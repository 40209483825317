// import "../../css/components/directories/ListDirectories.css";
import { Box, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FC, useState } from 'react';
import { IAlbum } from "../../interfaces";
// import { PublikAlbum } from "./PublikAlbum";
import { PublikFoto } from './PublikFoto';
import Lightbox from 'yet-another-react-lightbox';
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Video from "yet-another-react-lightbox/plugins/video";
import { EnumAlbumItemType } from '../../enums';
import { PublikVideo } from './PublikVideo';
import { PublikAudio } from './PublikAudio';

interface IProps {
    album: IAlbum,
}

export const ListPublikFotos: FC<IProps> = (props) => {

    const [openedLightbox, setOpenedLightbox] = useState<boolean>(false)
    const [lightboxIndex, setLightboxIndex] = useState<number>(0)

    const handleFotoClick = (index: number) => {
        setLightboxIndex(index)
        setOpenedLightbox(true)
    }

    const handleVideoClick = (index: number) => {
        setLightboxIndex(index)
        setOpenedLightbox(true)
    }

    const handleAudioClick = (index: number) => {
        setLightboxIndex(index)
        setOpenedLightbox(true)
    }

    return (
        <div className='ListPublikDirectories'>
            <Container className="ListPublikDirectories-container">
                <Grid container spacing={0} className="ListDirectories-grid-container">
                    {
                        props.album.albumItems?.map((albumItem, index) => {
                            if (albumItem.albumItemType == EnumAlbumItemType.foto && albumItem.foto) {
                                return (
                                    <Grid key={`${albumItem.id}`} size={{ xs: 12, md: 4 }} display="flex" justifyContent="center" alignItems="flex-start">
                                        <PublikFoto
                                            foto={albumItem.foto}
                                            onClick={handleFotoClick}
                                            index={index}
                                        />
                                    </Grid>
                                )
                            }
                            if (albumItem.albumItemType == EnumAlbumItemType.video && albumItem.video) {
                                return (
                                    <Grid key={`${albumItem.id}`} size={{ xs: 12, md: 4 }} display="flex" justifyContent="center" alignItems="flex-start">
                                        <PublikVideo
                                            video={albumItem.video}
                                            onClick={handleVideoClick}
                                            index={index}
                                        />
                                    </Grid>
                                )
                            }
                            if (albumItem.albumItemType === EnumAlbumItemType.audio && albumItem.audio) {
                                return (
                                    <Grid key={`${albumItem.id}`} size={{ xs: 12, md: 6, lg: 4 }} display="flex" justifyContent="center" alignItems="flex-start">
                                        <PublikAudio
                                            audio={albumItem.audio}
                                            onClick={handleAudioClick}
                                            index={index}
                                        />
                                    </Grid>
                                )
                            }
                        })
                    }
                    {
                        props.album.albumItems?.length === 0 &&
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography color="text.secondary" sx={{ fontSize: 24 }}>
                                Aucun élément.
                            </Typography>
                        </Box>
                    }
                    {
                        props.album && props.album.albumItems && < Lightbox
                            open={openedLightbox}
                            index={lightboxIndex}
                            close={() => setOpenedLightbox(false)}
                            slides={
                                props.album.albumItems.map((albumItem) => {
                                    if (albumItem.albumItemType === EnumAlbumItemType.video && albumItem.video) {
                                        return {
                                            type: "video",
                                            autoPlay: true,
                                            // width: 1280,
                                            // height: 720,
                                            width: 3840,
                                            height: 2560,
                                            poster: albumItem.video.videoPreviewUrl,
                                            sources: [
                                                {
                                                    src: albumItem.video.mp4VideoFileUrl,
                                                    type: "video/mp4",
                                                },
                                            ],
                                        }
                                    } else if (albumItem.albumItemType === EnumAlbumItemType.audio && albumItem.audio) {
                                        // on simule une video pour les audios
                                        return {
                                            type: "video",
                                            autoPlay: true,
                                            width: 3840,
                                            height: 2560,
                                            sources: [
                                                {
                                                    src: albumItem.audio.audioFileUrl,
                                                    type: "video/mp4",
                                                },
                                            ],
                                        }
                                    } else {
                                        return {
                                            src: albumItem.foto?.fotoFileUrl || "",
                                            alt: `foto ${albumItem.foto?.id}`,
                                            width: 3840,
                                            height: 2560,
                                            srcSet: [
                                                { src: albumItem.foto?.fotoPreviewUrl || "", width: 300, height: 300 },
                                                { src: albumItem.foto?.fotoFileUrl || "", width: 3840, height: 2560 },
                                            ],
                                        }
                                    }
                                })
                            }
                            plugins={[Fullscreen, Slideshow, Video]}
                            fullscreen={{
                                auto: true
                            }}
                        />
                    }
                </Grid>
            </Container>
        </div>
    );
}
