import { FC } from 'react';
import { Layout } from "./Layout";
import { ListReminders } from '../components/reminders/ListReminders';

interface IProps {
}

export const RemindersPage: FC<IProps> = (props) => {
  return (
    <div className="RemindersPage">
      <Layout>
        <div className="page-body">
          <ListReminders />
        </div>
      </Layout>
    </div >
  )
}
