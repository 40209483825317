
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';


export const SoftLimitIcon = (props: SvgIconProps) => {
  return (
    // <SvgIcon viewBox='0 0 24 24' {...props}>
    //   <path d="M14 8.9999C13.5 8.87589 12.6851 8.87132 12 8.87589M12 8.87589C11.7709 8.87741 11.9094 8.86767 11.6 8.87589C10.7926 8.90107 10.0016 9.23665 10 10.1874C9.99825 11.2002 11 11.4999 12 11.4999C13 11.4999 14 11.7311 14 12.8124C14 13.625 13.1925 13.981 12.1861 14.099C11.3861 14.099 11 14.1249 10 13.9999M12 8.87589L12 7.49999M12 14.0994V15.5M20 12C20 16.4611 14.54 19.6937 12.6414 20.683C12.4361 20.79 12.3334 20.8435 12.191 20.8712C12.08 20.8928 11.92 20.8928 11.809 20.8712C11.6666 20.8435 11.5639 20.79 11.3586 20.683C9.45996 19.6937 4 16.4611 4 12V8.21759C4 7.41808 4 7.01833 4.13076 6.6747C4.24627 6.37113 4.43398 6.10027 4.67766 5.88552C4.9535 5.64243 5.3278 5.50207 6.0764 5.22134L11.4382 3.21067C11.6461 3.13271 11.75 3.09373 11.857 3.07827C11.9518 3.06457 12.0482 3.06457 12.143 3.07827C12.25 3.09373 12.3539 3.13271 12.5618 3.21067L17.9236 5.22134C18.6722 5.50207 19.0465 5.64243 19.3223 5.88552C19.566 6.10027 19.7537 6.37113 19.8692 6.6747C20 7.01833 20 7.41808 20 8.21759V12Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    // </SvgIcon>


    <SvgIcon viewBox='0 0 128 128' {...props}>
      <path fill="currentColor" d="M61.77487,37.477v6.27741l-.40858.0984a13.483,13.483,0,0,0-5.306,2.46888A10.26314,10.26314,0,0,0,52.15969,54.25,9.43881,9.43881,0,0,0,55.58,61.73069,24.88,24.88,0,0,0,62.22564,65.462a20.01172,20.01172,0,0,1,5.14092,2.71649,5.8546,5.8546,0,0,1,2.309,5.40438,5.44832,5.44832,0,0,1-3.51333,4.28589,9.56232,9.56232,0,0,1-3.17045.65288,15.79973,15.79973,0,0,1-8.27746-2.06141l-1.50062-.81273-.58847,1.68471c-.28986.83812-.57134,1.65191-.87612,2.45409a1.09553,1.09553,0,0,0,.52273,1.49422,16.30718,16.30718,0,0,0,5.13456,1.95669c.95034.18722,1.888.3111,2.88059.44119.2794.037.56079.07412.84443.11328v6.18542h5.215V83.50064c.23278-.06979.46354-.13655.692-.2021a19.21247,19.21247,0,0,0,2.26885-.75349c4.03616-1.71327,6.36425-4.62873,6.92508-8.6648A10.39484,10.39484,0,0,0,71.665,63.34653a33.27057,33.27057,0,0,0-6.32611-3.29322,33.977,33.977,0,0,1-4.76836-2.44981,4.26016,4.26016,0,0,1-1.89423-4.56087,4.57814,4.57814,0,0,1,3.32708-3.74192,11.71028,11.71028,0,0,1,5.79066-.17045,13.012,13.012,0,0,1,4.62868,1.7059l.544.32478.61169-1.51642c.39361-.97452.77671-1.9238,1.13433-2.88151a1.09728,1.09728,0,0,0-.53752-1.22016l-.7323-.33339a16.82631,16.82631,0,0,0-2.70065-1.06459,27.775,27.775,0,0,0-3.0901-.57779c-.30686-.04552-.61579-.09094-.92689-.13963V37.477Z" />
      <path fill="currentColor" d="M63.99963,3.036l-49.752,10.91481V69.1716a37.8079,37.8079,0,0,0,10.30818,26.059,145.90474,145.90474,0,0,0,37.122,28.48626l2.32183,1.24715,2.32183-1.24715a145.90442,145.90442,0,0,0,37.12272-28.48626,37.8079,37.8079,0,0,0,10.30818-26.059V13.95078ZM103.94024,69.1716a28.03593,28.03593,0,0,1-7.63255,19.32572A136.23281,136.23281,0,0,1,63.99963,113.7964,136.25324,136.25324,0,0,1,31.69231,88.49732,28.03593,28.03593,0,0,1,24.05976,69.1716V21.84352l39.93987-8.76177,39.94061,8.76177Z" />
    </SvgIcon>





  )
}

