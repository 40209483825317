import { Tooltip, Typography, useTheme, Link } from '@mui/material';
import { FC } from 'react';
import { ILockerMinimal } from "../../interfaces";
import { truncateString } from '../../utils/Utils';
import { useNavigate } from 'react-router-dom';
import { constructPath, getBasePath } from '../../utils/Navigation';
import { Flexbox } from '../utils/Flexbox';
import { EnumBasePathFor, EnumListLockersMode, EnumLockerIconMode } from '../../enums';
import { LockerIcon } from '../svg_icons/LockerIcon';
import { VaultIcon } from '../svg_icons/VaultIcon';

interface IProps {
    locker: ILockerMinimal,
}


export const LockerShortcutted: FC<IProps> = (props) => {
    // const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[] | undefined>()
    const theme = useTheme()
    const navigate = useNavigate()

    const lockerPath = constructPath(getBasePath(EnumBasePathFor.locker, props.locker.mode), [
        { key: "lockerId", value: props.locker.id.toString() }
    ])

    // const getAncestorsBreadcrumbs = () => {
    //     if (props.locker === undefined) {
    //         return [{ title: "..." }]
    //     }
    //     let res = []
    //     // Le locker d'abord:
    //     res.push(
    //         {
    //             title: props.locker.title,
    //         }
    //     )
    //     // Puis les ancestors:
    //     res.push(
    //         ...props.directory.ancestors.map(ancestor => {
    //             return {
    //                 title: ancestor.name,
    //             }
    //         })
    //     )
    //     return res
    // }

    // useEffect(() => {
    //     setBreadcrumbs([
    //         ...getAncestorsBreadcrumbs(),
    //     ])
    // }, [props.directory])

    return (
        <Flexbox direction='column' sx={{
            justifyContent: "flex-start",
            alignItems: "flex-start",
        }}>
            <Link href={lockerPath} underline="none">
                <Flexbox
                    sx={{
                        ":hover": {
                            cursor: "pointer",
                            "& .hover-effect": {
                                backgroundColor: theme.palette.buttonBackground.main,
                            }
                        }
                    }}>
                    {
                        props.locker.mode === EnumListLockersMode.vault &&
                        <VaultIcon color="vault"
                            className='hover-effect'
                            sx={{
                                fontSize: 16,
                                p: 1,
                                mx: .5,
                                mr: -1,
                                border: "1px solid rgba(0, 0, 0, .2)",
                                // borderRadius: "50%",
                                borderRadius: "20%",
                                backgroundColor: "white",
                                zIndex: 2,
                            }}
                        />
                    }
                    {
                        props.locker.mode === EnumListLockersMode.desktop &&
                        <LockerIcon mode={EnumLockerIconMode.standard} color={props.locker.mode}
                            className='hover-effect'
                            sx={{
                                fontSize: 16,
                                p: 1,
                                mx: .5,
                                mr: -1,
                                border: "1px solid rgba(0, 0, 0, .2)",
                                // borderRadius: "50%",
                                borderRadius: "20%",
                                backgroundColor: "white",
                                zIndex: 2,
                            }}
                        />
                    }


                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={props.locker.title}>
                        <Typography color={theme.palette.text.primary}
                            className='hover-effect'
                            sx={{
                                fontSize: 16,
                                pl: 1.5,
                                pr: 1,
                                border: "1px solid rgba(0, 0, 0, .15)",
                                borderTopRightRadius: "7px",
                                borderBottomRightRadius: "7px",
                                backgroundColor: "white",
                                minWidth: 100,
                            }}
                        >
                            {truncateString(props.locker.title, 12)}
                        </Typography>
                    </Tooltip>
                </Flexbox>
            </Link>
            {/* <Breadcrumbs mode={EnumBreadcrumbMode.condensed} breadcrumbs={breadcrumbs} sx={{
                mt: -0.5,
                // mb: 2,
                pl: 4,
            }} /> */}
        </Flexbox>
    )
}
