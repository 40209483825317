import { Box, Collapse, Divider, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { IVideo } from "../../interfaces";
import { PocketIcon } from '../svg_icons/PocketIcon';
import { EnumListLockersMode, EnumMessageType, EnumPocketTargetType, EnumSvgVariant } from '../../enums';
import { ExpandMore } from "../utils/ExpandMore";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import { useMutation } from '@apollo/client';
import {
    GQL_MUTATION_COPY_TO_ALBUM, GQL_MUTATION_MOVE_TO_ALBUM, GQL_MUTATION_REMOVE_FROM_POCKET
} from '../../graphql/Mutations';
import { addMessage } from '../messages/Message';
import { useDispatch } from 'react-redux';
import {
    removeVideosAction as removeVideosFromPocketAction,
} from "../../redux/features/pocket/pocketSlice";
import { useNavigate } from 'react-router-dom';
import { constructPath, NAVIGATE_ALBUM } from '../../utils/Navigation';
import { setAllRefetchNeeded } from '../../redux/store';
import { FloatingSpinner } from '../spinner/FloatingSpinner';
import { Flexbox } from '../utils/Flexbox';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';

interface IProps {
    video: IVideo,
    targetType?: EnumPocketTargetType,
    targetId?: string,
    targetMode?: EnumListLockersMode,
}


export const VideoItem: FC<IProps> = (props) => {
    const [expanded, setExpanded] = useState(false)
    const [removeFromPocket, { data: removeFromPocketData, loading: removeFromPocketLoading, error: removeFromPocketError }] = useMutation(GQL_MUTATION_REMOVE_FROM_POCKET)
    const [moveToAlbum, { data: moveToAlbumData, loading: moveToAlbumLoading, error: moveToAlbumError }] = useMutation(GQL_MUTATION_MOVE_TO_ALBUM)
    const [copyToAlbum, { data: copyToAlbumData, loading: copyToAlbumLoading, error: copyToAlbumError }] = useMutation(GQL_MUTATION_COPY_TO_ALBUM)

    const [previewLoaded, setPreviewLoaded] = useState(false);


    const dispatch = useDispatch()
    const navigate = useNavigate()
    const theme = useTheme()


    const handlePreviewLoaded = () => {
        setPreviewLoaded(true);
    }


    useEffect(() => {
        if (removeFromPocketError) {
            addMessage({
                location: "VideoItem",
                type: EnumMessageType.Error,
                message: removeFromPocketError.message,
            })
        } else if (removeFromPocketData) {
            if (removeFromPocketData.removeFromPocket.statusCode === 200) {
                dispatch(removeVideosFromPocketAction([props.video.id]))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Retiré de la pocket.",
                })
            } else {
                removeFromPocketData.removeFromPocket.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [removeFromPocketData, removeFromPocketError])

    useEffect(() => {
        if (moveToAlbumError) {
            addMessage({
                location: "VideoItem",
                type: EnumMessageType.Error,
                message: moveToAlbumError.message,
            })
        } else if (moveToAlbumData) {
            if (moveToAlbumData.moveToAlbum.statusCode === 200) {
                setAllRefetchNeeded("VideoItem")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Video déplacée.",
                })
            } else {
                moveToAlbumData.moveToAlbum.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [moveToAlbumData, moveToAlbumError])

    useEffect(() => {
        if (copyToAlbumError) {
            addMessage({
                location: "VideoItem",
                type: EnumMessageType.Error,
                message: copyToAlbumError.message,
            })
        } else if (copyToAlbumData) {
            if (copyToAlbumData.copyToAlbum.statusCode === 200) {
                setAllRefetchNeeded("VideoItem")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Video copiée.",
                })
            } else {
                copyToAlbumData.copyToAlbum.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [copyToAlbumData, copyToAlbumError])

    const handleExpand = () => {
        setExpanded(!expanded)
    }

    const handleRemoveFromPocket = () => {
        removeFromPocket({
            variables: {
                videoIds: [props.video.id],
            }
        })
    }

    const handleSee = () => {
        if (props.video.album !== undefined) {
            const albumPath = constructPath(NAVIGATE_ALBUM, [
                { key: "albumId", value: props.video.album.id.toString() }
            ])
            navigate(albumPath)
        }
    }
    const handleMove = () => {
        if (!props.targetType || !props.targetId || props.targetType !== EnumPocketTargetType.album) {
            addMessage({
                type: EnumMessageType.Error,
                message: "Destination incorecte.",
            })
        } else {
            if (props.targetType === EnumPocketTargetType.album) {
                moveToAlbum({
                    variables: {
                        albumId: props.targetId,
                        videoIds: [props.video.id],
                    }
                })
            }
        }
    }
    const handleCopy = () => {
        if (!props.targetType || !props.targetId || props.targetType !== EnumPocketTargetType.album) {
            addMessage({
                type: EnumMessageType.Error,
                message: "Destination incorecte.",
            })
        } else {
            if (props.targetType === EnumPocketTargetType.album) {
                copyToAlbum({
                    variables: {
                        albumId: props.targetId,
                        videoIds: [props.video.id],
                    }
                })
            }
        }
    }

    return (
        <>
            <FloatingSpinner dependances={[moveToAlbumLoading, copyToAlbumLoading]} />
            <Box
                className="VideoItem"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    mb: 1,
                }}
            >
                <Box sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}>
                        <Box component="img"
                            src={props.video.videoPreviewUrl}
                            onLoad={handlePreviewLoaded}
                            sx={{
                                ml: .5,
                                maxWidth: 60,
                                maxHeight: 60,
                                display: previewLoaded ? 'block' : 'none',
                                border: "1px solid rgba(0, 0, 0, .5)",
                                borderRadius: 1,
                                backgroundColor: theme.palette.noteBackground.main,
                                zIndex: 2,
                            }}
                        />
                        <Flexbox
                            sx={{
                                fontSize: 16,
                                pl: 1,
                                pr: 2,
                                border: "1px solid rgba(0, 0, 0, .15)",
                                borderTopRightRadius: "7px",
                                borderBottomRightRadius: "7px",
                                backgroundColor: "white",
                            }}>
                            <PlayCircleOutlinedIcon sx={{ fontSize: 16 }} />
                            <Typography sx={{
                                ml: .5,
                                fontSize: 16,
                                color: theme.palette.text.primary,
                            }}>
                                video
                            </Typography>
                        </Flexbox>
                    </Box>
                    <Box >
                        <ExpandMore expand={expanded}
                            onClick={handleExpand} aria-expanded={expanded} aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Box>
                </Box>
                <Collapse in={expanded} timeout="auto" unmountOnExit
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                    }}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                        }}
                    >
                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Voir">
                            <IconButton onClick={handleSee} color="primary" size="small">
                                <VisibilityOutlinedIcon sx={{ fontSize: 16 }} />
                            </IconButton>
                        </Tooltip>
                        {
                            props.targetType &&
                            props.targetMode &&
                            props.targetMode !== EnumListLockersMode.shared &&
                            ([EnumPocketTargetType.album].includes(props.targetType)) &&
                            <>
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Déplacer ici">
                                    <IconButton onClick={handleMove} color="primary" size="small">
                                        <DriveFileMoveOutlinedIcon sx={{ fontSize: 16 }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Copier ici">
                                    <IconButton onClick={handleCopy} color="primary" size="small">
                                        <FileCopyOutlinedIcon sx={{ fontSize: 16 }} />
                                    </IconButton>
                                </Tooltip>
                            </>
                        }
                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Retirer de la pocket">
                            <IconButton onClick={handleRemoveFromPocket} color="primary" size="small">
                                <PocketIcon variant={EnumSvgVariant.primary} sx={{ fontSize: 16 }} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Divider flexItem variant='middle' />
                </Collapse>
            </Box >
        </>
    )
}
