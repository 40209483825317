import "../css/components/Footer.css"
import { FC, useEffect, useState } from 'react'
import { Container } from "@mui/system"
import { Box, Typography, useTheme } from "@mui/material";
import { useQuery } from "@apollo/client"
import { GQL_GET_API_INFOS } from "../graphql/Queries"
import { MetadocLogo } from "./MetadocLogo"
import { EnumMetadocLogoMode } from "../enums"


interface IProps { }

export const Footer: FC<IProps> = (props) => {

    const webVersion = "1.0.1"
    const apiExpectedVersion = "1.0.1"

    const [apiInfos, setApiInfos] = useState({
        apiVersion: "?",
        codeName: "?",
        coreVersion: "?",
        date: "?",
        environment: "?",
    })

    const theme = useTheme()

    // Pour un refresh régulier : 
    // const {error, loading, data, startPolling} = useQuery(GQL_GET_API_INFOS)
    // startPolling(500)

    // Pour un refresh à chaque mount du component : 
    // const {error, loading, data, refetch: refetchGql} = useQuery(GQL_GET_API_INFOS)
    // refetchGql()

    // sinon ya cette approche selon https://www.apollographql.com/docs/react/data/queries/ : 
    // const { loading, error, data } = useQuery(GET_DOGS, {
    //     fetchPolicy: 'network-only', // Doesn't check cache before making a network request
    //   });

    // Pour ne pas refresh :
    const { error, loading, data } = useQuery(GQL_GET_API_INFOS)

    useEffect(() => {
        if (data) {
            setApiInfos({
                apiVersion: data.getApiInfos.apiVersion,
                codeName: data.getApiInfos.codeName,
                coreVersion: data.getApiInfos.coreVersion,
                date: data.getApiInfos.date,
                environment: data.getApiInfos.environment,
            })
        }
    }, [data])

    return (
        <div className='Footer'>
            <Container className="Footer-container">
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <MetadocLogo mode={EnumMetadocLogoMode.tiny} link={true} />
                    <Typography variant="body2">
                        <span title={`Médéric DE LAUNAY - V ${webVersion} ${apiInfos.date} - core: ${apiInfos.coreVersion}(${apiInfos.codeName} - ${apiInfos.environment}) - api: ${apiInfos.apiVersion}(expected: ${apiExpectedVersion})`} >Metadoc</span> - Copyright &copy; 2014-{new Date().getFullYear()}
                    </Typography>
                </Box>

            </Container>
        </div >
    )
}


