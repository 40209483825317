import { createSlice } from "@reduxjs/toolkit";
import { IReminder } from "../../../interfaces";
import { useSelector } from "react-redux";
import { IRootState, store } from "../../store";
import { apolloClient } from "../../../ApolloClient";
import { GQL_REMINDERS } from "../../../graphql/Queries";


interface IRemindersSliceState {
    reminders: undefined | IReminder[];
    refetchNeeded: boolean
}

const initialState: IRemindersSliceState = {
    reminders: undefined,
    refetchNeeded: false,
}

export const remindersSlice = createSlice({
    name: "remindersSlice",
    initialState,
    reducers: {
        setRemindersAction: (state, action) => {
            const newReminders = [...action.payload]
            state.reminders = newReminders
        },
        unsetRemindersAction: (state) => {
            state.reminders = initialState.reminders
        },
        setRemindersRefetchNeeded: (state, action) => {
            state.refetchNeeded = action.payload
        },
    }
})

export const { setRemindersAction, unsetRemindersAction, setRemindersRefetchNeeded,
} = remindersSlice.actions


export const useGetReminders = () => {
    const reminders: (undefined | IReminder[]) = useSelector((state: IRootState) => state.remindersReducer.reminders)
    const refetchNeeded: boolean = useSelector((state: IRootState) => state.remindersReducer.refetchNeeded)
    if (reminders === undefined || refetchNeeded) {
        apolloClient.query({
            query: GQL_REMINDERS,
            fetchPolicy: 'network-only',
        }
        ).then(response => {
            store.dispatch(setRemindersAction(response.data.reminders))
            store.dispatch(setRemindersRefetchNeeded(false))
            return response.data.reminders
        })
    } else {
        return reminders
    }
}

export default remindersSlice.reducer