import { FC } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Home } from './pages/Home';
// import { Page404 } from './pages/Page404';
import { NAVIGATE_ROOT, NAVIGATE_LOGIN, NAVIGATE_DESKTOP, NAVIGATE_VAULT, NAVIGATE_SHARED, NAVIGATE_DESKTOP_LOCKER, NAVIGATE_VAULT_LOCKER, NAVIGATE_SHARED_LOCKER, NAVIGATE_DESKTOP_DIRECTORY, NAVIGATE_VAULT_DIRECTORY, NAVIGATE_SHARED_DIRECTORY, NAVIGATE_SEARCH, NAVIGATE_PROFILE, NAVIGATE_PASSWORD_RESET, NAVIGATE_ADMIN, NAVIGATE_CHOOSE_PASSWORD, NAVIGATE_PASSWORDS, NAVIGATE_CONTACTS, NAVIGATE_PUBLIK_DOCUMENT, NAVIGATE_PUBLIK_DIRECTORY, NAVIGATE_ALBUMS, NAVIGATE_ALBUM, NAVIGATE_PUBLIK_ALBUM, NAVIGATE_CASHFLOW, NAVIGATE_BANK_ACCOUNT, NAVIGATE_ADMIN_USER, NAVIGATE_REMINDERS } from './utils/Navigation';
import Login from './pages/Login';

import { ApolloProvider } from "@apollo/client";

// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Desktop } from './pages/Desktop';
import { Vault } from './pages/Vault';
import { Shared } from './pages/Shared';
import { EnumListLockersMode } from './enums';
import { LockerPage } from './pages/LockerPage';
import { DirectoryPage } from './pages/DirectoryPage';
import { SearchPage } from './pages/SearchPage';
import { ProfilePage } from './pages/ProfilePage';
import PasswordReset from './pages/PasswordReset';
import { AdminPage } from './pages/admin/AdminPage';
import ChoosePassword from './pages/ChoosePassword';
import { apolloClient } from './ApolloClient';
import { PasswordsPage } from './pages/PasswordsPage';
import { ContactsPage } from './pages/ContactsPage';
import Page404 from './pages/Page404';
import { PublikDocumentPage } from './pages/PublikDocumentPage';
import { PublikDirectoryPage } from './pages/PublikDirectoryPage';
import { AlbumsPage } from './pages/AlbumsPage';
import { AlbumPage } from './pages/AlbumPage';
import { PublikAlbumPage } from './pages/PublikAlbumPage';
import { Cashflow } from './pages/Cashflow';
import { BankAccountPage } from './pages/BankAccountPage';
import theme from './theme';
import { AdminUserPage } from './pages/admin/AdminUserPage';
import { RemindersPage } from './pages/RemindersPage';


interface IProps { }

export const App: FC<IProps> = (props) => {
    return (
        <ApolloProvider client={apolloClient}>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className="App">
                        <BrowserRouter>
                            <Routes>
                                <Route path={`${NAVIGATE_ROOT}`} element={<Home />} />
                                <Route path={`${NAVIGATE_LOGIN}`} element={<Login />} />

                                <Route path={`${NAVIGATE_DESKTOP}`} element={<Desktop />} />
                                <Route path={`${NAVIGATE_DESKTOP_LOCKER}`} element={<LockerPage mode={EnumListLockersMode.desktop} />} />
                                <Route path={`${NAVIGATE_DESKTOP_DIRECTORY}`} element={<DirectoryPage mode={EnumListLockersMode.desktop} />} />
                                <Route path={`${NAVIGATE_VAULT}`} element={<Vault />} />
                                <Route path={`${NAVIGATE_VAULT_LOCKER}`} element={<LockerPage mode={EnumListLockersMode.vault} />} />
                                <Route path={`${NAVIGATE_VAULT_DIRECTORY}`} element={<DirectoryPage mode={EnumListLockersMode.vault} />} />
                                <Route path={`${NAVIGATE_SHARED}`} element={<Shared />} />
                                <Route path={`${NAVIGATE_SHARED_LOCKER}`} element={<LockerPage mode={EnumListLockersMode.shared} />} />
                                <Route path={`${NAVIGATE_SHARED_DIRECTORY}`} element={<DirectoryPage mode={EnumListLockersMode.shared} />} />
                                <Route path={`${NAVIGATE_PUBLIK_DIRECTORY}`} element={<PublikDirectoryPage />} />
                                <Route path={`${NAVIGATE_PUBLIK_DOCUMENT}`} element={<PublikDocumentPage />} />

                                <Route path={`${NAVIGATE_SEARCH}`} element={<SearchPage />} />
                                <Route path={`${NAVIGATE_PROFILE}`} element={<ProfilePage />} />
                                <Route path={`${NAVIGATE_PASSWORD_RESET}`} element={<PasswordReset />} />

                                <Route path={`${NAVIGATE_ADMIN}`} element={<AdminPage />} />
                                <Route path={`${NAVIGATE_ADMIN_USER}`} element={<AdminUserPage />} />
                                <Route path={`${NAVIGATE_CHOOSE_PASSWORD}`} element={<ChoosePassword />} />

                                <Route path={`${NAVIGATE_PASSWORDS}`} element={<PasswordsPage />} />
                                <Route path={`${NAVIGATE_ALBUMS}`} element={<AlbumsPage />} />
                                <Route path={`${NAVIGATE_ALBUM}`} element={<AlbumPage />} />
                                <Route path={`${NAVIGATE_PUBLIK_ALBUM}`} element={<PublikAlbumPage />} />
                                <Route path={`${NAVIGATE_CONTACTS}`} element={<ContactsPage />} />
                                <Route path={`${NAVIGATE_REMINDERS}`} element={<RemindersPage />} />

                                <Route path={`${NAVIGATE_CASHFLOW}`} element={<Cashflow />} />
                                <Route path={`${NAVIGATE_BANK_ACCOUNT}`} element={<BankAccountPage />} />



                                <Route path="/*" element={<Page404 />} />
                            </Routes>
                        </BrowserRouter>
                    </div>
                </LocalizationProvider>
            </ThemeProvider>
        </ApolloProvider>
    )
}



