import { Box, Container, Divider, IconButton, Tooltip, Typography, FormControl, InputLabel, Input, InputAdornment } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FC, useEffect, useState } from 'react';
import { Spinner } from "../spinner/Spinner";
import { Add } from '@mui/icons-material';
import { filterMatch } from "../../utils/Utils";
import { useGetReminders } from '../../redux/features/reminders/remindersSlice';
import { IReminder } from '../../interfaces';
import { Reminder } from './Reminder';
import { ModalNewReminder } from './modals/ModalNewReminder';
import ClearIcon from '@mui/icons-material/Clear';

interface IProps {
}

export const ListReminders: FC<IProps> = (props) => {
    const reminders = useGetReminders()
    const [showModalNewReminder, setShowModalNewReminder] = useState(false)
    const [filter, setFilter] = useState("")
    const [filteredReminders, setFilteredReminders] = useState<IReminder[] | undefined>([])

    const closeModalNewReminder = () => {
        setShowModalNewReminder(false)
    }

    useEffect(() => {
        if (reminders) {
            if (filter.length === 0) {
                setFilteredReminders(reminders)
            } else {
                setFilteredReminders(reminders.filter(reminder => (
                    filterMatch(reminder.content, filter) ||
                    filterMatch(reminder.deliverAt, filter) ||
                    filterMatch(reminder.contact.displayTitle, filter) ||
                    filterMatch(reminder.contact.email, filter)
                )))
            }
        }
    }, [reminders, filter])

    const handleFilter = (newFilter: string) => {
        setFilter(newFilter)
    }

    return (
        <div className='ListReminders'>
            <Container className="ListReminders-actions"
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    mt: "12px",
                }}
            >
                <FormControl sx={{
                    pb: 2,
                    my: 0,
                    mr: 1,
                    width: "200px",
                }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-reminder">Filtrer...</InputLabel>
                    <Input
                        type='text'
                        onChange={(event) => { handleFilter(event.target.value) }}
                        value={filter}
                        endAdornment={
                            filter.length > 0 && <InputAdornment position="end">
                                <IconButton
                                    onClick={() => { setFilter("") }}
                                >
                                    <ClearIcon fontSize='small' />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <Divider orientation="vertical" flexItem variant="middle" sx={{
                    mx: 1,
                }} />
                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Ajouter un reminder">
                    <IconButton color="primary" onClick={() => setShowModalNewReminder(true)}><Add fontSize="medium" /></IconButton>
                </Tooltip>
                <ModalNewReminder
                    open={showModalNewReminder}
                    handleClose={closeModalNewReminder}
                />
            </Container>

            <Container className="ListReminders-container">
                <Grid container spacing={0} className="ListReminders-grid-container">
                    {
                        reminders === undefined ? (
                            <Spinner classes="big-spinner" />
                        ) : (
                            filteredReminders?.map((reminder) => {
                                return (
                                    <Grid key={`${reminder.id}`} size={{ xs: 12, md: 4 }} display="flex" justifyContent="center" alignItems="flex-start">
                                        <Reminder reminder={reminder} />
                                    </Grid>
                                )
                            })
                        )
                    }
                    {
                        reminders !== undefined && reminders.length === 0 &&
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography color="text.secondary" sx={{ fontSize: 24 }}>
                                Aucun reminder.
                            </Typography>
                        </Box>
                    }

                </Grid>
            </Container>
        </div>
    )
}
