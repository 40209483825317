import { Box, Button, LinearProgress, Paper, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { IUser } from "../../interfaces";
import { LinearProgressWithLabel } from '../spinner/LinearProgressWithLabel';
import { EnumLockerIconMode, EnumMessageType, EnumPackLabel } from '../../enums';
import { LockerIcon } from '../svg_icons/LockerIcon';
import { VaultIcon } from '../svg_icons/VaultIcon';
import { addMessage } from '../messages/Message';
import { setAllRefetchNeeded } from '../../redux/store';
import { useMutation } from '@apollo/client';
import { GQL_MUTATION_RECALCULATE_DISK_USAGE } from '../../graphql/Mutations';
import { FloatingSpinner } from '../spinner/FloatingSpinner';
import { getDayjsDuration, pluralize } from '../../utils/Utils';
import dayjs from 'dayjs';
import { CameraIcon } from '../svg_icons/CameraIcon';
import { Flexbox } from '../utils/Flexbox';


interface IProps {
    user: IUser,
}


export const UserPackInfos: FC<IProps> = (props) => {
    const [packPictoUrl, setPackPictoUrl] = useState("")
    const [packLabel, setPackLabel] = useState("")
    const [packLimit, setPackLimit] = useState("")
    const [packUsagePercent, setPackUsagePercent] = useState(0)
    const [packUsagePercentDesktop, setPackUsagePercentDesktop] = useState(0)
    const [packUsagePercentVault, setPackUsagePercentVault] = useState(0)
    const [packUsagePercentAlbums, setPackUsagePercentAlbums] = useState(0)
    const [lastCheck, setLastCheck] = useState("inconnue")

    const theme = useTheme()

    const [recalculateDiskUsage, { data: recalculateDiskUsageData, loading: recalculateDiskUsageLoading, error: recalculateDiskUsageError }] = useMutation(GQL_MUTATION_RECALCULATE_DISK_USAGE)

    useEffect(() => {
        if (props.user.packInfos) {
            setPackUsagePercent(props.user.packInfos.usagePercent)
            setPackUsagePercentDesktop(props.user.packInfos.usagePercentDesktop)
            setPackUsagePercentVault(props.user.packInfos.usagePercentVault)
            setPackUsagePercentAlbums(props.user.packInfos.usagePercentAlbums)
            if (props.user.packInfos.lastCheck !== null) {
                setLastCheck(`Il y a ${getDayjsDuration(dayjs.utc(props.user.packInfos.lastCheck), dayjs.utc()).toString()} minutes`)
            }
        }
        if (props.user.packInfos?.label === EnumPackLabel.starter) {
            setPackPictoUrl(`${process.env.PUBLIC_URL}/images/packs/starter.png`)
            setPackLabel("Starter")
            setPackLimit("200 MB")
        } else if (props.user.packInfos?.label === EnumPackLabel.basic) {
            setPackPictoUrl(`${process.env.PUBLIC_URL}/images/packs/basic.png`)
            setPackLabel("Basic")
            setPackLimit("500 MB")
        } else if (props.user.packInfos?.label === EnumPackLabel.bronze) {
            setPackPictoUrl(`${process.env.PUBLIC_URL}/images/packs/bronze.png`)
            setPackLabel("Bronze")
            setPackLimit("1 GB")
        } else if (props.user.packInfos?.label === EnumPackLabel.silver) {
            setPackPictoUrl(`${process.env.PUBLIC_URL}/images/packs/silver.png`)
            setPackLabel("Silver")
            setPackLimit("2 GB")
        } else if (props.user.packInfos?.label === EnumPackLabel.gold) {
            setPackPictoUrl(`${process.env.PUBLIC_URL}/images/packs/gold.png`)
            setPackLabel("Gold")
            setPackLimit("5 GB")
        } else if (props.user.packInfos?.label === EnumPackLabel.amethyst) {
            setPackPictoUrl(`${process.env.PUBLIC_URL}/images/packs/amethyst.png`)
            setPackLabel("Amethyst")
            setPackLimit("10 GB")
        } else if (props.user.packInfos?.label === EnumPackLabel.ruby) {
            setPackPictoUrl(`${process.env.PUBLIC_URL}/images/packs/ruby.png`)
            setPackLabel("Ruby")
            setPackLimit("20 GB")
        } else if (props.user.packInfos?.label === EnumPackLabel.diamond) {
            setPackPictoUrl(`${process.env.PUBLIC_URL}/images/packs/diamond.png`)
            setPackLabel("Diamond")
            setPackLimit("50 GB")
        } else if (props.user.packInfos?.label === EnumPackLabel.blackDiamond) {
            setPackPictoUrl(`${process.env.PUBLIC_URL}/images/packs/black_diamond.png`)
            setPackLabel("Black diamond")
            setPackLimit("100 GB")
        }
    }, [])

    useEffect(() => {
        if (recalculateDiskUsageError) {
            addMessage({
                location: "UserPackInfos",
                type: EnumMessageType.Error,
                message: recalculateDiskUsageError.message,
            })
        } else if (recalculateDiskUsageData) {
            if (recalculateDiskUsageData.recalculateDiskUsage.statusCode === 200) {
                setAllRefetchNeeded("UserPackInfos")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Recalcul de l'espace disque effectué.",
                })
            } else {
                recalculateDiskUsageData.recalculateDiskUsage.errors.map((error: string) => {
                    addMessage({
                        location: "UserPackInfos",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [recalculateDiskUsageData, recalculateDiskUsageError])

    const handleRecalculate = () => {
        recalculateDiskUsage({
            variables: {
                userIds: [props.user.id]
            }
        })
    }

    return (
        <>
            <FloatingSpinner dependances={[recalculateDiskUsageLoading]} />
            <Paper className="UserPackInfos" sx={{ width: "100%" }}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", py: 2, px: 2 }}>
                    <Typography variant="h5" color="primary">
                        Espace disque
                    </Typography>
                    <Typography sx={{ fontSize: 14, color: theme.palette.text.secondary }}>
                        Dernière mise à jour: {lastCheck}
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 2, px: 2, py: 2 }}>
                            <Box
                                component="img"
                                src={packPictoUrl}
                                sx={{ width: "30px" }}
                            />
                            <Typography color="primary" sx={{ fontSize: "18px" }}>
                                {packLabel}
                            </Typography>
                        </Box>
                        <Typography color="primary" sx={{ fontSize: "18px" }}>
                            {packLimit}
                        </Typography>
                    </Box>
                    <Box sx={{ width: '100%', px: 1 }}>
                        <LinearProgressWithLabel variant="determinate"
                            color={packUsagePercent > 80 ? "error" : packUsagePercent > 60 ? "warning" : 'success'}
                            value={packUsagePercent} />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2, px: 2, py: 2 }}>
                            <LockerIcon mode={EnumLockerIconMode.standard} color="desktop" sx={{ fontSize: 24 }} />
                            <Typography color="primary" sx={{ fontSize: "18px" }}>
                                Bureau
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ width: '100%', px: 1 }}>
                        <LinearProgressWithLabel variant="determinate"
                            color={packUsagePercentDesktop > 80 ? "error" : packUsagePercentDesktop > 60 ? "warning" : 'success'}
                            value={packUsagePercentDesktop} />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2, px: 2, py: 2 }}>
                            <VaultIcon color="vault" sx={{ fontSize: 24 }} />
                            <Typography color="primary" sx={{ fontSize: "18px" }}>
                                Coffre
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ width: '100%', px: 1 }}>
                        <LinearProgressWithLabel variant="determinate"
                            color={packUsagePercentVault > 80 ? "error" : packUsagePercentVault > 60 ? "warning" : 'success'}
                            value={packUsagePercentVault} />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <Flexbox sx={{ gap: 2, px: 2, py: 2, alignItems: "flex-start" }}>
                            <CameraIcon color="desktop" sx={{ fontSize: 24 }} />
                            <Flexbox direction='column' sx={{ justifyContent: "flex-start", alignItems: "flex-start" }}>
                                <Typography color="primary" sx={{ fontSize: "18px" }}>
                                    Albums
                                </Typography>
                                <Typography sx={{ fontSize: 14, color: theme.palette.text.secondary, mt: -1 }}>
                                    La vraie taille est divisée par 1000.
                                </Typography>

                            </Flexbox>
                        </Flexbox>
                    </Box>
                    <Box sx={{ width: '100%', px: 1 }}>
                        <LinearProgressWithLabel variant="determinate"
                            color={packUsagePercentAlbums > 80 ? "error" : packUsagePercentAlbums > 60 ? "warning" : 'success'}
                            value={packUsagePercentAlbums} />
                    </Box>
                    <Box sx={{ width: '100%', pt: 3, display: "flex", justifyContent: "flex-end" }}>
                        <Button onClick={handleRecalculate} variant="contained" color="primary">Recalculer</Button>
                    </Box>
                </Box>
            </Paper>
        </>
    )
}
