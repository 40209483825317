import { gql } from "@apollo/client"


export const GQL_FRAGMENT_USER = gql`
  fragment User on User {
    id
    firstname
    lastname
    userAvatarUrl
    userAvatarPreviewUrl
    admin
    lastConnection
  }
`

// Lockers:
export const GQL_FRAGMENT_LOCKER_MINIMAL = gql`
  fragment LockerMinimal on Locker {
    id
    title
    mode
  }
`

export const GQL_FRAGMENT_LOCKER = gql`
${GQL_FRAGMENT_LOCKER_MINIMAL}
  fragment Locker on Locker {
    ...LockerMinimal
    description
    vault
    publik
    delivery
  }
`

export const GQL_FRAGMENT_LOCKER_EXTENDED = gql`
${GQL_FRAGMENT_LOCKER}
${GQL_FRAGMENT_USER}
  fragment LockerExtended on Locker {
    ...Locker
    owners {
      ...User
    }
    viewers {
      ...User
    }
    directoriesCount
    documentsCount
    notesCount
  }
`

export const GQL_FRAGMENT_DIRECTORY_MINIMAL = gql`
  fragment DirectoryMinimal on Directory {
    id
    name
    mode
  }
`

export const GQL_FRAGMENT_DIRECTORY = gql`
${GQL_FRAGMENT_DIRECTORY_MINIMAL}
  fragment Directory on Directory {
    ...DirectoryMinimal
    description
    directoriesCount
    documentsCount
    notesCount
    publik
    pubToken
  }
`

export const GQL_FRAGMENT_SHEET = gql`
  fragment Sheet on Sheet {
    id
    filename
    updatedAt
    sheetFileUrl
    sheetFileUrlForDownload
    applicable
  }
`


export const GQL_FRAGMENT_DOCUMENT = gql`
${GQL_FRAGMENT_SHEET}
  fragment Document on Document {
    id
    title
    description
    sheetsCount
    mode
    updatedAt
    sheetFileUrl
    sheetFileUrlForDownload
    sheetPreviewUrl
    publik
    pubToken
    sheets {
      ...Sheet
    }
    directory {
      id
      mode
    }
  }
`

export const GQL_FRAGMENT_PUBLIK_DOCUMENT = gql`
  fragment PublikDocument on Document {
    id
    title
    description
    sheetsCount
    mode
    updatedAt
    sheetFileUrl
    sheetFileUrlForDownload
    sheetPreviewUrl
    publik
    pubToken
  }
`

export const GQL_FRAGMENT_PARTNERSHIP = gql`
${GQL_FRAGMENT_USER}
  fragment Partnership on Partnership {
    id
    isRequester
    isResponder
    partner {
      ...User
    }
    confirmed
  }
`

export const GQL_FRAGMENT_NOTE = gql`
  fragment Note on Note {
    id
    title
    content
    mode
    directory {
      id
      mode
    }
  }
`

export const GQL_FRAGMENT_PASSWORD = gql`
  fragment Password on Password {
    id
    title
    url
    description
    login
    encryptedPassword
  }
`



export const GQL_FRAGMENT_FOTO = gql`
  fragment Foto on Foto {
    id
    fotoFileUrl
    fotoFileUrlForDownload
    fotoPreviewUrl
    album {
      id
    }
  }
`

export const GQL_FRAGMENT_VIDEO = gql`
  fragment Video on Video {
    id
    videoPreviewUrl
    mp4VideoFileUrl
    mp4VideoFileUrlForDownload
    originalVideoFileUrl
    originalVideoFileUrlForDownload
    album {
      id
    }
  }
`

export const GQL_FRAGMENT_AUDIO = gql`
  fragment Audio on Audio {
    id
    audioFileUrl
    audioFileUrlForDownload
    title
    album {
      id
    }
  }
`


export const GQL_FRAGMENT_ALBUM_ITEM = gql`
${GQL_FRAGMENT_FOTO}
${GQL_FRAGMENT_VIDEO}
${GQL_FRAGMENT_AUDIO}
  fragment AlbumItem on AlbumItem {
    id
    albumItemType
    foto {
      ...Foto
    }
    video {
      ...Video
    }
    audio {
      ...Audio
    }
  }
`

export const GQL_FRAGMENT_ALBUM_MINIMAL = gql`
  fragment AlbumMinimal on Album {
    id
    title
    mode
  }
`

export const GQL_FRAGMENT_ALBUM = gql`
${GQL_FRAGMENT_ALBUM_MINIMAL}
  fragment Album on Album {
    ...AlbumMinimal
    description
    dateAlbum
    publik
    pubToken
  }
`


export const GQL_FRAGMENT_ALBUM_EXTENDED = gql`
${GQL_FRAGMENT_ALBUM}
${GQL_FRAGMENT_USER}
${GQL_FRAGMENT_FOTO}
${GQL_FRAGMENT_VIDEO}
  fragment AlbumExtended on Album {
    ...Album
    owners {
      ...User
    }
    viewers {
      ...User
    }
    albumItemsCount
    randomFotos {
     ...Foto
    }
    randomVideos {
     ...Video
    }
  }
`

export const GQL_FRAGMENT_CUSTOM_CONTACT = gql`
  fragment CustomContact on CustomContact {
    id
    title
    contactAvatarPreviewUrl
  }
`


export const GQL_FRAGMENT_CONTACT_MINIMAL = gql`
  fragment ContactMinimal on Contact {
    id
    nickname
    firstname
    lastname
    enterpriseName
    contactType
    contactAvatarPreviewUrl
    displayTitle
  }
`

export const GQL_FRAGMENT_CONTACT = gql`
${GQL_FRAGMENT_CONTACT_MINIMAL}
${GQL_FRAGMENT_CUSTOM_CONTACT}
 fragment Contact on Contact {
  ...ContactMinimal
    phone
    email
    birthAt
    adresse
    description
    contactRelations {
    ...ContactMinimal
    }
    customContacts {
    ...CustomContact
    }
  }
`

export const GQL_FRAGMENT_TODOSTEP = gql`
  fragment Todostep on Todostep {
    id
    title
    done
  }
`

export const GQL_FRAGMENT_TODOLIST = gql`
${GQL_FRAGMENT_TODOSTEP}
  fragment Todolist on Todolist {
    id
    title
    done
    todosteps {
     ...Todostep
    }
  }
`

// Cashflow:
export const GQL_FRAGMENT_BANK_ACCOUNT_MINIMAL = gql`
  fragment BankAccountMinimal on BankAccount {
    id
    title
    cbColor
  }
`

export const GQL_FRAGMENT_BANK_ACCOUNT = gql`
${GQL_FRAGMENT_BANK_ACCOUNT_MINIMAL}
  fragment BankAccount on BankAccount {
    ...BankAccountMinimal
    description
    bankName
    bankAccountType
    taxRate
    bankBalance
    realBalance
    softLimit
  }
`

export const GQL_FRAGMENT_ACCOUNT_OPERATION = gql`
${GQL_FRAGMENT_CUSTOM_CONTACT}
  fragment AccountOperation on AccountOperation {
    id
    title
    accountOperationType
    dateOperation
    amount
    description
    customContact {
      ...CustomContact
    }
    state
    paidAt
    reference
    bankAccountId
    imputable
    toDuplicate
  }
`

export const GQL_FRAGMENT_REMINDER = gql`
${GQL_FRAGMENT_CONTACT}
  fragment Reminder on Reminder {
    id
    content
    deliverAt
    deliveredAt
    contact {
      ...Contact
    }
  }
`
