import { gql } from "@apollo/client"
import { GQL_FRAGMENT_LOCKER_EXTENDED, GQL_FRAGMENT_USER, GQL_FRAGMENT_DIRECTORY, GQL_FRAGMENT_LOCKER_MINIMAL, GQL_FRAGMENT_DOCUMENT, GQL_FRAGMENT_PARTNERSHIP, GQL_FRAGMENT_NOTE, GQL_FRAGMENT_PASSWORD, GQL_FRAGMENT_FOTO, GQL_FRAGMENT_CONTACT, GQL_FRAGMENT_PUBLIK_DOCUMENT, GQL_FRAGMENT_ALBUM_EXTENDED, GQL_FRAGMENT_ALBUM, GQL_FRAGMENT_DIRECTORY_MINIMAL, GQL_FRAGMENT_TODOLIST, GQL_FRAGMENT_BANK_ACCOUNT, GQL_FRAGMENT_ACCOUNT_OPERATION, GQL_FRAGMENT_CUSTOM_CONTACT, GQL_FRAGMENT_BANK_ACCOUNT_MINIMAL, GQL_FRAGMENT_ALBUM_ITEM, GQL_FRAGMENT_VIDEO, GQL_FRAGMENT_AUDIO, GQL_FRAGMENT_REMINDER } from "./Fragments"

// Settings
export const GQL_GET_API_INFOS = gql`
query {
    getApiInfos {
      apiVersion
      codeName
      coreVersion
      date
      environment
    }
  }
`

// Users
export const GQL_WHO_AM_I = gql`
${GQL_FRAGMENT_USER}
${GQL_FRAGMENT_PARTNERSHIP}
${GQL_FRAGMENT_DIRECTORY_MINIMAL}
${GQL_FRAGMENT_LOCKER_MINIMAL}
${GQL_FRAGMENT_TODOLIST}
${GQL_FRAGMENT_CONTACT}
${GQL_FRAGMENT_BANK_ACCOUNT_MINIMAL}
  query {
    whoami {
      ...User
      email
      userType
      partnerships {
        ...Partnership
      }
      notPartners {
        ...User
      }
      packInfos {
        label
        maxDisk
        usagePercent
        usagePercentDesktop
        usagePercentVault
        lastCheck
        usagePercentAlbums
      }
      deliveryInfos {
        lockerId
        documentsCount
        notesCount
      }
      lockersShortcutted {
        ...LockerMinimal
      }
      directoriesShortcutted {
        ...DirectoryMinimal
        ancestors {
          ...DirectoryMinimal
        }
        locker {
          ...LockerMinimal
        }
      }
      todolists {
        ...Todolist
      }
      contactsInfos {
        incomingBirthdays {
          ...Contact
        }
      }
      bankAccountsShortcutted {
        ...BankAccountMinimal
      }
    }
  }
`


export const GQL_USERS = gql`
  ${GQL_FRAGMENT_USER}
  query users {
    users {
      ...User
      email
      packInfos {
        label
        maxDisk
        usagePercent
      }
    }
  }
`


export const GQL_USER = gql`
  ${GQL_FRAGMENT_USER}
  ${GQL_FRAGMENT_PARTNERSHIP}
  query user($userId: ID!) {
    user(userId: $userId) {
      ...User
      email
      partnerships {
        ...Partnership
      }
      notPartners {
        ...User
      }
      packInfos {
        label
        maxDisk
        usagePercent
        usagePercentDesktop
        usagePercentVault
        lastCheck
        usagePercentAlbums
      }
    }
  }
`


// Lockers
export const GQL_LOCKERS = gql`
  ${GQL_FRAGMENT_LOCKER_EXTENDED}
  query lockers($mode: EnumLockerMode, $filter: String) {
    lockers(mode: $mode, filter: $filter) {
      ...LockerExtended
    }
  }
`

export const GQL_LOCKER = gql`
  ${GQL_FRAGMENT_LOCKER_EXTENDED}
  ${GQL_FRAGMENT_DIRECTORY}
  query locker($lockerId: ID!, $filter: String) {
    locker(lockerId: $lockerId) {
      ...LockerExtended
      directories: directDirectories(filter: $filter) {
        ...Directory
      }
    }
  }
`

export const GQL_QUERY_LOCKER_DIRECTORIES_INFOS = gql`
query locker($lockerId: ID!) {
  locker(lockerId: $lockerId) {
    directoriesCount
    documentsCount
  }
}
`


// Pocket
export const GQL_POCKET = gql`
  ${GQL_FRAGMENT_DIRECTORY}
  ${GQL_FRAGMENT_DOCUMENT}
  ${GQL_FRAGMENT_NOTE}
  ${GQL_FRAGMENT_FOTO}
  ${GQL_FRAGMENT_VIDEO}
  ${GQL_FRAGMENT_AUDIO}
  query pocket {
    pocket {
      directories {
        ...Directory
        locker {
          mode
        }
      }
      documents {
        ...Document
      }
      notes {
        ...Note
      }
      fotos {
        ...Foto
      }
      videos {
        ...Video
      }
      audios {
        ...Audio
      }
    }
  }
`

// Directories:
export const GQL_DIRECTORY = gql`
  ${GQL_FRAGMENT_DIRECTORY}
  ${GQL_FRAGMENT_LOCKER_MINIMAL}
  ${GQL_FRAGMENT_DOCUMENT}
  ${GQL_FRAGMENT_NOTE}
  query directory($directoryId: ID!, $filter: String) {
    directory(directoryId: $directoryId) {
      ...Directory
      children(filter: $filter) {
        ...Directory
      }
      ancestors {
        ...Directory
      }
      locker {
        ...LockerMinimal
        mode
      }
      documents(filter: $filter) {
        ...Document
      }
      notes(filter: $filter) {
        ...Note
      }
    }
  }
`

export const GQL_PUBLIK_DIRECTORY = gql`
  ${GQL_FRAGMENT_DIRECTORY}
  ${GQL_FRAGMENT_DOCUMENT}
  ${GQL_FRAGMENT_NOTE}
  query publikDirectory($directoryId: ID!, $pubToken: String!) {
    publikDirectory(
      directoryId: $directoryId,
      pubToken: $pubToken,
      ) {
      ...Directory
      children {
        ...Directory
      }
      ancestors {
        ...Directory
      }
      documents {
        ...Document
      }
      notes {
        ...Note
      }
    }
  }
`

export const GQL_QUERY_DIRECTORY_DIRECTORIES_INFOS = gql`
query directory($directoryId: ID!) {
  directory(directoryId: $directoryId) {
    directoriesCount
    documentsCount
  }
}
`


// Search
export const GQL_SEARCH = gql`
${GQL_FRAGMENT_LOCKER_EXTENDED}
${GQL_FRAGMENT_LOCKER_MINIMAL}
${GQL_FRAGMENT_DIRECTORY}
${GQL_FRAGMENT_DIRECTORY_MINIMAL}
${GQL_FRAGMENT_DOCUMENT}
${GQL_FRAGMENT_NOTE}
  query search(
      $searchQuery: String!,
      $desktopLockers: Boolean!,
      $vaultLockers: Boolean!,
      $sharedLockers: Boolean!,
      $desktopDirectories: Boolean!,
      $vaultDirectories: Boolean!,
      $sharedDirectories: Boolean!,
      $desktopDocuments: Boolean!,
      $vaultDocuments: Boolean!,
      $sharedDocuments: Boolean!,
      $desktopNotes: Boolean!,
      $vaultNotes: Boolean!,
      $sharedNotes: Boolean!,
      $dateFrom: String!,
      $dateTo: String!,
    ) {
    search(
      searchQuery: $searchQuery,
      searchTargets: {
        desktopLockers: $desktopLockers,
        vaultLockers: $vaultLockers,
        sharedLockers: $sharedLockers,
        desktopDirectories: $desktopDirectories,
        vaultDirectories: $vaultDirectories,
        sharedDirectories: $sharedDirectories,
        desktopDocuments: $desktopDocuments,
        vaultDocuments: $vaultDocuments,
        sharedDocuments: $sharedDocuments,
        desktopNotes: $desktopNotes,
        vaultNotes: $vaultNotes,
        sharedNotes: $sharedNotes,
      }
      dateFrom: $dateFrom,
      dateTo: $dateTo,
    ) {
      lockers {
        ...LockerExtended
      }
      directories {
        ...Directory
        ancestors {
          ...DirectoryMinimal
        }
        locker {
          ...LockerMinimal
        }
      }
      documents {
        ...Document
        directory {
          ...DirectoryMinimal
          ancestors {
            ...DirectoryMinimal
          }
          locker {
            ...LockerMinimal
          }
        }
      }
      notes {
        ...Note
        directory {
          ...DirectoryMinimal
          ancestors {
            ...DirectoryMinimal
          }
          locker {
            ...LockerMinimal
          }
        }
      }
    }
  }
`

export const GQL_QUERY_GET_SIZE_INFOS = gql`
query getSizeInfos(
  $lockerIds: [ID!],
  $directoryIds: [ID!],
  $documentIds: [ID!],
  $albumIds: [ID!],
  ) {
  getSizeInfos(
    lockerIds: $lockerIds
    directoryIds: $directoryIds
    documentIds: $documentIds
    albumIds: $albumIds
    ) {
    lockers {
      id
      sizeInfos {
        size
        sizeByOwner
        humanSize
        humanSizeByOwner
      }
    }
    directories {
      id
      sizeInfos {
        size
        sizeByOwner
        humanSize
        humanSizeByOwner
      }
    }
    documents {
      id
      sizeInfos {
        size
        sizeByOwner
        humanSize
        humanSizeByOwner
      }
    }
    albums {
      id
      sizeInfos {
        size
        sizeByOwner
        humanSize
        humanSizeByOwner
      }
    }
  }
}
`


// Passwords
export const GQL_PASSWORDS = gql`
  ${GQL_FRAGMENT_PASSWORD}
  query passwords {
    passwords {
      ...Password
    }
  }
`

// Albums
export const GQL_ALBUMS = gql`
  ${GQL_FRAGMENT_ALBUM_EXTENDED}
  query albums(
    $dateFrom: String!,
    $dateTo: String!,
    ) {
    albums(
      dateFrom: $dateFrom,
      dateTo: $dateTo,
      ) {
      ...AlbumExtended
    }
  }
`

export const GQL_ALBUM = gql`
  ${GQL_FRAGMENT_ALBUM_EXTENDED}
  ${GQL_FRAGMENT_ALBUM_ITEM}
  query album($albumId: ID!) {
    album(albumId: $albumId) {
      ...AlbumExtended
      albumItems: albumItems {
        ...AlbumItem
      }
    }
  }
`

export const GQL_PUBLIK_ALBUM = gql`
${GQL_FRAGMENT_ALBUM}
${GQL_FRAGMENT_ALBUM_ITEM}
  query publikAlbum($albumId: ID!, $pubToken: String!) {
    publikAlbum(
      albumId: $albumId,
      pubToken: $pubToken,
    ) {
        ...Album
        albumItems {
          ...AlbumItem
        }
      }
  }
`


// Fotos
export const GQL_FOTOS = gql`
  ${GQL_FRAGMENT_FOTO}
  query fotos {
    fotos {
      ...Foto
    }
  }
`

// Contacts
export const GQL_CONTACTS = gql`
  ${GQL_FRAGMENT_CONTACT}
  query contacts {
    contacts {
      ...Contact
    }
  }
`

// CustomContacts
export const GQL_CUSTOM_CONTACTS = gql`
  ${GQL_FRAGMENT_CUSTOM_CONTACT}
  query customContacts {
    customContacts {
      ...CustomContact
    }
  }
`

// Documents
export const GQL_PUBLIK_DOCUMENT = gql`
  ${GQL_FRAGMENT_PUBLIK_DOCUMENT}
  query publikDocument($documentId: ID!, $pubToken: String!) {
    publikDocument(
      documentId: $documentId,
      pubToken: $pubToken,
      ) {
      ...PublikDocument
    }
  }
`

// Cashflow
export const GQL_BANK_ACCOUNTS = gql`
  ${GQL_FRAGMENT_BANK_ACCOUNT}
  query bankAccounts {
    bankAccounts {
      ...BankAccount
    }
  }
`

export const GQL_BANK_ACCOUNT = gql`
  ${GQL_FRAGMENT_BANK_ACCOUNT}
  ${GQL_FRAGMENT_ACCOUNT_OPERATION}
  query bankAccount(
    $bankAccountId: ID!,
    $dateFrom: String!,
    $dateTo: String!,
    ) {
    bankAccount(
      bankAccountId: $bankAccountId,
      ) {
      ...BankAccount
      accountOperations(
        dateFrom: $dateFrom,
        dateTo: $dateTo,
      ) {
        ...AccountOperation
      }
      realBalanceAtDateFrom: realBalanceAt(date: $dateFrom),
    }
  }
`

// Reminders
export const GQL_REMINDERS = gql`
  ${GQL_FRAGMENT_REMINDER}
  query reminders {
    reminders {
      ...Reminder
    }
  }
`