import { useMutation } from '@apollo/client';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import { IAccountOperation } from '../../../../interfaces';
import { EnumAccountOperationState, EnumAccountOperationType, EnumDayjsFormat, EnumMessageType } from '../../../../enums';
import { addMessage } from '../../../messages/Message';
import { setAllRefetchNeeded } from '../../../../redux/store';
import { AccountOperationIcon } from '../../../svg_icons/AccountOperationIcon';
import { GQL_MUTATION_SET_ACCOUNT_OPERATION_STATE } from '../../../../graphql/Mutations';
import { Flexbox } from '../../../utils/Flexbox';
import { DateSelector } from '../../../utils/DateSelector';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';
import { AccountOperationSpan } from '../AccountOperationSpan';
import { HourglassIcon } from '../../../svg_icons/HourglassIcon';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { CashBagIcon } from '../../../svg_icons/CashBagIcon';
import DoneIcon from '@mui/icons-material/Done';

interface IProps {
    accountOperation: IAccountOperation;
    open: boolean;
    handleClose: () => void;
}

export const ModalHandleStateAccountOperation: FC<IProps> = (props) => {
    // const [paidAt, setPaidAt] = useState<Dayjs>(props.accountOperation.paidAt ? dayjs(props.accountOperation.paidAt) : dayjs())
    const [paidAt, setPaidAt] = useState<Dayjs>(props.accountOperation.paidAt ? dayjs(props.accountOperation.paidAt) : dayjs(props.accountOperation.dateOperation).locale('fr'))
    const [setAccountOperationState, { data: setAccountOperationStateData, loading: setAccountOperationStateLoading, error: setAccountOperationStateError }] = useMutation(GQL_MUTATION_SET_ACCOUNT_OPERATION_STATE)
    const [closeModalOnMutationSuccess, setCloseModalOnMutationSuccess] = useState(false)

    const theme = useTheme()

    useEffect(() => {
        if (setAccountOperationStateError) {
            addMessage({
                location: "ModalHandleStateAccountOperation",
                type: EnumMessageType.Error,
                message: setAccountOperationStateError.message,
            })
        } else if (setAccountOperationStateData) {
            if (setAccountOperationStateData.setAccountOperationState.statusCode === 200) {
                setAllRefetchNeeded("ModalHandleStateAccountOperation")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Statut de l'opération modifié.",
                })
                if (closeModalOnMutationSuccess) {
                    handleClose()
                }
            } else {
                setAccountOperationStateData.setAccountOperationState.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [setAccountOperationStateData, setAccountOperationStateError])


    const checkErrors = (newState: EnumAccountOperationState) => {
        let res = true
        if (newState === EnumAccountOperationState.paid && paidAt === undefined) {
            addMessage({
                type: EnumMessageType.Error,
                message: "Veuillez sélectionner la date de paiement.",
            })
            res = false
        }
        return res
    }

    const handleConfirm = (newState: EnumAccountOperationState) => {
        if (checkErrors(newState)) {
            if (newState === EnumAccountOperationState.consolidated) {
                setCloseModalOnMutationSuccess(true)
            }
            setAccountOperationState({
                variables: {
                    accountOperationId: props.accountOperation.id,
                    state: newState,
                    paidAt: newState === EnumAccountOperationState.paid ? paidAt?.format(EnumDayjsFormat.dateForBackend) : undefined,
                }
            })
        }
    }

    const handleClose = () => {
        // cleanDatas()
        props.handleClose()
    }

    const handlePaidAt = (pDate: Dayjs) => {
        setPaidAt(pDate)
    }

    const getCashBagIcon = () => {
        if (props.accountOperation.accountOperationType === EnumAccountOperationType.credit) {
            return <CashBagIcon mainColor={theme.palette.cbGreen.main} secondaryColor={theme.palette.cbGreen.light} thirdColor={theme.palette.grey[50]} />
        }
        else {
            return <CashBagIcon mainColor={theme.palette.cbRed.main} secondaryColor={theme.palette.cbRed.light} thirdColor={theme.palette.grey[50]} />
        }
    }

    const quickPaidAtSelection = (pDate: Dayjs) => {
        setPaidAt(pDate)
    }


    return (
        <div className="ModalHandleStateAccountOperation">
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <Box>
                    <DialogTitle color="primary">
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <AccountOperationIcon color={props.accountOperation.accountOperationType} sx={{ fontSize: 24, mr: "12px" }} />
                            <Typography sx={{ fontSize: 24 }}>
                                Changement de statut
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                    }}>
                        <Flexbox direction='column' sx={{
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            // bgcolor: "yellow",
                            width: "100%",
                        }}>
                            <Typography sx={{
                                width: "100%",
                                textAlign: "center",
                            }}>
                                L'opération <AccountOperationSpan accountOperation={props.accountOperation} /> est actuellement au statut:
                            </Typography>
                            {
                                props.accountOperation.state === EnumAccountOperationState.prepared && <>
                                    <Flexbox sx={{
                                        color: theme.palette.cancel.main,
                                        my: 1,
                                        width: "100%",

                                    }}>
                                        <AutorenewIcon sx={{
                                            fontSize: 18,
                                            mr: 1,
                                        }} />
                                        <Typography sx={{
                                            fontSize: 18,
                                            fontWeight: "bold",
                                        }}>
                                            Préparé
                                        </Typography>
                                    </Flexbox>
                                    <Typography sx={{
                                        color: "text.secondary",
                                    }}>
                                        Il s'agit d'une opération préparée par le système (duplication, opération rapide, synchronisation bancaire, etc.).
                                        <br />
                                        Afin de valider cette opération, merci de cliquer sur le bouton Valider.
                                    </Typography>
                                </>
                            }
                            {
                                props.accountOperation.state === EnumAccountOperationState.created && <>
                                    <Flexbox sx={{
                                        color: theme.palette.primary.main,
                                        my: 1,
                                        width: "100%",

                                    }}>
                                        <HourglassIcon sx={{
                                            fontSize: 18,
                                            mr: 1,
                                        }} />
                                        <Typography sx={{
                                            fontSize: 18,
                                            fontWeight: "bold",
                                        }}>
                                            Enregistré
                                        </Typography>
                                    </Flexbox>
                                    <Typography sx={{
                                        color: "text.secondary",
                                    }}>
                                        Afin de confirmer le paiement, merci de sélectionner la date de paiement effective et de cliquer sur le bouton Confirmer.
                                    </Typography>
                                    <Flexbox sx={{
                                        justifyContent: "flex-start",
                                        width: "100%",
                                        mt: 2,
                                    }}>
                                        <Typography sx={{
                                            fontSize: 16,
                                            color: theme.palette.text.secondary,
                                            width: "100%",
                                        }}>
                                            Date de paiement
                                        </Typography>
                                        <DateSelector
                                            date={paidAt}
                                            title="Date de paiement"
                                            disableFuture={true}
                                            handleDate={handlePaidAt}
                                            // resetDate={resetDateOperation}
                                            showArrows={true}
                                            displayDateOnLeft={true}
                                        />
                                    </Flexbox>
                                    <Divider flexItem sx={{
                                        mb: 1,
                                        mt: 2,
                                    }}>
                                        Sélection rapide
                                    </Divider>
                                    <Flexbox sx={{
                                        flexWrap: "wrap",
                                    }}>
                                        <Button
                                            onClick={() => {
                                                quickPaidAtSelection(dayjs(props.accountOperation.dateOperation))
                                            }}
                                        >
                                            Date de l'opération ({dayjs(props.accountOperation.dateOperation).locale('fr').format(EnumDayjsFormat.date)})
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                quickPaidAtSelection(dayjs())
                                            }}
                                        >
                                            Aujourd'hui
                                        </Button>
                                    </Flexbox>
                                </>
                            }
                            {
                                props.accountOperation.state === EnumAccountOperationState.paid && <>
                                    <Flexbox sx={{
                                        color: props.accountOperation.accountOperationType === EnumAccountOperationType.credit ? theme.palette.credit.main : theme.palette.debit.main,
                                        my: 1,
                                        width: "100%",

                                    }}>
                                        {getCashBagIcon()}
                                        <Typography sx={{
                                            fontSize: 18,
                                            fontWeight: "bold",
                                        }}>
                                            Payé
                                        </Typography>
                                    </Flexbox>
                                    <Typography sx={{
                                        color: "text.secondary",
                                    }}>
                                        L'opération a été payée le {dayjs(props.accountOperation.paidAt).locale('fr').format(EnumDayjsFormat.date)}.
                                        {
                                            props.accountOperation.reference && <>
                                                <br />
                                                Référence: {props.accountOperation.reference}
                                            </>
                                        }

                                        <br />
                                        Vous pouvez consolider l'opération ou annuler le paiement lié à cette opération.
                                    </Typography>
                                </>
                            }
                            {
                                props.accountOperation.state === EnumAccountOperationState.consolidated && <>
                                    <Flexbox sx={{
                                        color: theme.palette.desktop.main,
                                        my: 1,
                                        width: "100%",
                                    }}>
                                        <DoneIcon />
                                        <Typography sx={{
                                            fontSize: 18,
                                            fontWeight: "bold",
                                        }}>
                                            Consolidé
                                        </Typography>
                                    </Flexbox>
                                    <Typography sx={{
                                        color: "text.secondary",
                                    }}>
                                        L'opération est consolidée, il n'y a plus rien à faire.
                                        <br />
                                        Toutefois, vous pouvez annuler la consolidation de l'opération pour la faire revenir au statut précédent.
                                    </Typography>
                                </>
                            }
                        </Flexbox>

                    </DialogContent>
                    <DialogActions>
                        {
                            props.accountOperation.state !== EnumAccountOperationState.consolidated && <>
                                <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                            </>
                        }
                        {
                            props.accountOperation.state === EnumAccountOperationState.prepared && <>
                                <Button onClick={() => handleConfirm(EnumAccountOperationState.created)} variant="contained" color="primary">
                                    Valider
                                </Button>
                            </>
                        }
                        {
                            props.accountOperation.state === EnumAccountOperationState.created &&
                            // props.accountOperation.accountOperationType === EnumAccountOperationType.credit &&
                            <>
                                <Button onClick={() => handleConfirm(EnumAccountOperationState.paid)} variant="contained" color="success">
                                    Confirmer
                                </Button>
                            </>
                        }
                        {/* {
                            props.accountOperation.state === EnumAccountOperationState.created &&
                            props.accountOperation.accountOperationType === EnumAccountOperationType.debit &&
                            <>
                                <Button onClick={() => handleConfirm(EnumAccountOperationState.consolidated)} variant="contained" color="desktop">
                                    Consolider
                                </Button>
                            </>
                        } */}
                        {
                            props.accountOperation.state === EnumAccountOperationState.paid && <>
                                <Button onClick={() => handleConfirm(EnumAccountOperationState.created)} variant="contained" color="primary">
                                    Annuler le paiement
                                </Button>
                                <Button onClick={() => handleConfirm(EnumAccountOperationState.consolidated)} variant="contained" color="desktop">
                                    Consolider
                                </Button>
                            </>
                        }
                        {
                            props.accountOperation.state === EnumAccountOperationState.consolidated && <>
                                <Button onClick={() => handleConfirm(EnumAccountOperationState.paid)} variant="contained" color="error">
                                    Annuler la consolidation
                                </Button>
                                <Button onClick={handleClose} variant="outlined" color="cancel">Fermer</Button>
                            </>
                        }
                    </DialogActions>
                </Box>
            </Dialog>
        </div >
    )
}
