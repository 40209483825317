import { Box, Container, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FC, useEffect, useState } from 'react';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { ModalNewFotos } from './modals/ModalNewFotos';
import { Foto } from './Foto';
import { Video as VideoComponent } from './Video';
import Lightbox from 'yet-another-react-lightbox';
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Video from "yet-another-react-lightbox/plugins/video";
import { IAlbum, IZipPrototype } from '../../interfaces';
import { EnumAlbumItemType, EnumListAlbumsMode, EnumMessageType, EnumSvgVariant } from '../../enums';
import { Audio } from './Audio';
import { useDispatch } from 'react-redux';
import { selectAllAlbumItemsAction, unSelectAllAlbumItemsAction } from '../../redux/features/albums/albumSlice';
import { CropFreeOutlined, DeleteOutline } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import { GQL_MUTATION_ADD_IN_POCKET, GQL_MUTATION_CREATE_ALBUM_ZIP, GQL_MUTATION_CREATE_ZIP, GQL_MUTATION_DELETE_AUDIOS, GQL_MUTATION_DELETE_FOTO, GQL_MUTATION_DELETE_FOTOS, GQL_MUTATION_DELETE_VIDEOS, GQL_MUTATION_REMOVE_FROM_POCKET } from '../../graphql/Mutations';
import { addMessage } from '../messages/Message';
import {
    addFotosAction as addFotosInPocketAction,
    removeFotosAction as removeFotosFromPocketAction,
    addVideosAction as addVideosInPocketAction,
    removeVideosAction as removeVideosFromPocketAction,
    addAudiosAction as addAudiosInPocketAction,
    removeAudiosAction as removeAudiosFromPocketAction,
} from "../../redux/features/pocket/pocketSlice";
import { PocketIcon } from '../svg_icons/PocketIcon';
import { ModalDeleteConfirm } from '../modals/ModalDeleteConfirm';
import { setAllRefetchNeeded } from '../../redux/store';
import { pluralize } from '../../utils/Utils';
import { ZipIcon } from '../svg_icons/ZipIcon';
import { ModalNewAlbumZip } from './modals/ModalNewAlbumZip';
import { FloatingSpinner } from '../spinner/FloatingSpinner';


interface IProps {
    album: IAlbum
}

export const ListFotos: FC<IProps> = (props) => {
    const [showModalNewFotos, setShowModalNewFotos] = useState(false)
    const [openedLightbox, setOpenedLightbox] = useState<boolean>(false)
    const [lightboxIndex, setLightboxIndex] = useState<number>(0)
    const [selectAllElements, setSelectAllElements] = useState(true)
    const [hasSelectedElement, setHasSelectedElement] = useState(false)
    const [showDeleteElementsDialog, setShowDeleteElementsDialog] = useState(false)
    const [putInPocketSelectedElements, setPutInPocketSelectedElements] = useState(true)
    const [showZipElementsDialog, setShowZipElementsDialog] = useState(false)

    const [deleteFotos, { data: deleteFotosData, loading: deleteFotosLoading, error: deleteFotosError }] = useMutation(GQL_MUTATION_DELETE_FOTOS)
    const [deleteVideos, { data: deleteVideosData, loading: deleteVideosLoading, error: deleteVideosError }] = useMutation(GQL_MUTATION_DELETE_VIDEOS)
    const [deleteAudios, { data: deleteAudiosData, loading: deleteAudiosLoading, error: deleteAudiosError }] = useMutation(GQL_MUTATION_DELETE_AUDIOS)
    const [addInPocket, { data: addInPocketData, loading: addInPocketLoading, error: addInPocketError }] = useMutation(GQL_MUTATION_ADD_IN_POCKET)
    const [removeFromPocket, { data: removeFromPocketData, loading: removeFromPocketLoading, error: removeFromPocketError }] = useMutation(GQL_MUTATION_REMOVE_FROM_POCKET)
    const [createAlbumZip, { data: createAlbumZipData, loading: createAlbumZipLoading, error: createAlbumZipError }] = useMutation(GQL_MUTATION_CREATE_ALBUM_ZIP)

    const dispatch = useDispatch()

    useEffect(() => {
        if (getSelectedAlbumItems().length > 0) {
            setHasSelectedElement(true)
        } else {
            setHasSelectedElement(false)
        }
    }, [props.album.albumItems])

    const getSelectedAlbumItems = () => {
        return props.album.albumItems.filter(albumItem => albumItem.selected)
    }

    const getSelectedFotos = () => {
        return getSelectedAlbumItems().filter(albumItem => albumItem.albumItemType === EnumAlbumItemType.foto).map(albumItem => albumItem.foto)
    }
    const getSelectedVideos = () => {
        return getSelectedAlbumItems().filter(albumItem => albumItem.albumItemType === EnumAlbumItemType.video).map(albumItem => albumItem.video)
    }
    const getSelectedAudios = () => {
        return getSelectedAlbumItems().filter(albumItem => albumItem.albumItemType === EnumAlbumItemType.audio).map(albumItem => albumItem.audio)
    }

    const closeModalNewFotos = () => {
        setShowModalNewFotos(false)
    }

    const handleFotoClick = (index: number) => {
        setLightboxIndex(index)
        setOpenedLightbox(true)
    }

    const handleVideoClick = (index: number) => {
        setLightboxIndex(index)
        setOpenedLightbox(true)
    }

    const handleAudioClick = (index: number) => {
        setLightboxIndex(index)
        setOpenedLightbox(true)
    }

    const handleSelectAll = () => {
        selectAllElements ? dispatch(selectAllAlbumItemsAction()) : dispatch(unSelectAllAlbumItemsAction())
        setSelectAllElements(!selectAllElements)
    }

    const handleDeleteSelection = () => {
        if (getSelectedAlbumItems().length > 0) {
            setShowDeleteElementsDialog(true)
        }
    }

    const handlePutInPocket = () => {
        if (getSelectedAlbumItems().length > 0) {
            addInPocket({
                variables: {
                    fotoIds: getSelectedFotos().map(foto => foto?.id),
                    videoIds: getSelectedVideos().map(video => video?.id),
                    audioIds: getSelectedAudios().map(audio => audio?.id),
                }
            })

        }
    }

    const handleRemoveFromPocket = () => {
        if (getSelectedAlbumItems().length > 0) {
            removeFromPocket({
                variables: {
                    fotoIds: getSelectedFotos().map(foto => foto?.id),
                    videoIds: getSelectedVideos().map(video => video?.id),
                    audioIds: getSelectedAudios().map(audio => audio?.id),
                }
            })

        }
    }

    useEffect(() => {
        if (addInPocketError) {
            addMessage({
                location: "ListFotos",
                type: EnumMessageType.Error,
                message: addInPocketError.message,
            })
        } else if (addInPocketData) {
            if (addInPocketData.addInPocket.statusCode === 200) {
                dispatch(addFotosInPocketAction(getSelectedFotos()))
                dispatch(addVideosInPocketAction(getSelectedVideos()))
                dispatch(addAudiosInPocketAction(getSelectedAudios()))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "C'est dans la pocket.",
                })
                // Il peut y avoir certains qui n'ont pas fonctionner:
                const errors: string[] = addInPocketData.addInPocket.errors
                errors.map(error => addMessage({
                    type: EnumMessageType.Warning,
                    message: error,
                }))
                setPutInPocketSelectedElements(false)
            } else {
                addInPocketData.addInPocket.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [addInPocketData, addInPocketError])

    useEffect(() => {
        if (removeFromPocketError) {
            addMessage({
                location: "ListFotos",
                type: EnumMessageType.Error,
                message: removeFromPocketError.message,
            })
        } else if (removeFromPocketData) {
            if (removeFromPocketData.removeFromPocket.statusCode === 200) {
                dispatch(removeFotosFromPocketAction(getSelectedFotos().map(foto => foto?.id)))
                dispatch(removeVideosFromPocketAction(getSelectedVideos().map(video => video?.id)))
                dispatch(removeAudiosFromPocketAction(getSelectedAudios().map(audio => audio?.id)))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Retiré de la pocket.",
                })
                setPutInPocketSelectedElements(true)
            } else {
                removeFromPocketData.removeFromPocket.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [removeFromPocketData, removeFromPocketError])

    const closeDeleteElementsDialog = () => {
        setShowDeleteElementsDialog(false)
    }

    const confirmedDeleteElements = () => {
        if (getSelectedFotos().length > 0) {
            deleteFotos({
                variables: {
                    fotoIds: getSelectedFotos().map(foto => foto?.id),
                }
            })
        }
        if (getSelectedVideos().length > 0) {
            deleteVideos({
                variables: {
                    videoIds: getSelectedVideos().map(video => video?.id),
                }
            })
        }
        if (getSelectedAudios().length > 0) {
            deleteAudios({
                variables: {
                    audioIds: getSelectedAudios().map(audio => audio?.id),
                }
            })
        }
        setShowDeleteElementsDialog(false)
    }

    useEffect(() => {
        if (deleteFotosError) {
            addMessage({
                location: "ListFotos",
                type: EnumMessageType.Error,
                message: deleteFotosError.message,
            })
        } else if (deleteFotosData) {
            if (deleteFotosData.deleteFotos.statusCode === 200) {
                const deletedFotosCount = deleteFotosData.deleteFotos.deletedCount
                setAllRefetchNeeded("ListFotos")
                addMessage({
                    type: EnumMessageType.Success,
                    message: `${deletedFotosCount} ${pluralize("photo supprimée", deletedFotosCount, "photos supprimées")}.`,
                })
            } else {
                deleteFotosData.deleteFotos.errors.map((error: string) => {
                    addMessage({
                        location: "ListFotos",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteFotosData, deleteFotosError])

    useEffect(() => {
        if (deleteVideosError) {
            addMessage({
                location: "ListFotos",
                type: EnumMessageType.Error,
                message: deleteVideosError.message,
            })
        } else if (deleteVideosData) {
            if (deleteVideosData.deleteVideos.statusCode === 200) {
                const deletedVideosCount = deleteVideosData.deleteVideos.deletedCount
                setAllRefetchNeeded("ListFotos")
                addMessage({
                    type: EnumMessageType.Success,
                    message: `${deletedVideosCount} ${pluralize("video supprimée", deletedVideosCount, "videos supprimées")}.`,
                })
            } else {
                deleteVideosData.deleteVideos.errors.map((error: string) => {
                    addMessage({
                        location: "ListFotos",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteVideosData, deleteVideosError])

    useEffect(() => {
        if (deleteAudiosError) {
            addMessage({
                location: "ListFotos",
                type: EnumMessageType.Error,
                message: deleteAudiosError.message,
            })
        } else if (deleteAudiosData) {
            if (deleteAudiosData.deleteAudios.statusCode === 200) {
                const deletedAudiosCount = deleteAudiosData.deleteAudios.deletedCount
                setAllRefetchNeeded("ListFotos")
                addMessage({
                    type: EnumMessageType.Success,
                    message: `${deletedAudiosCount} ${pluralize("musique supprimée", deletedAudiosCount, "musiques supprimées")}.`,
                })
            } else {
                deleteAudiosData.deleteAudios.errors.map((error: string) => {
                    addMessage({
                        location: "ListFotos",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteAudiosData, deleteAudiosError])

    const handleZipSelection = () => {
        if (getSelectedAlbumItems().length > 0) {
            setShowZipElementsDialog(true)
        }
    }
    const closeZipElementsDialog = () => {
        setShowZipElementsDialog(false)
    }

    const confirmedZipElements = (zipPrototype: IZipPrototype) => {
        if (hasSelectedElement) {
            createAlbumZip({
                variables: {
                    ...zipPrototype,
                    albumId: props.album.id,
                    fotoIds: getSelectedFotos().map(foto => foto?.id),
                    videoIds: getSelectedVideos().map(video => video?.id),
                    audioIds: getSelectedAudios().map(audio => audio?.id),
                }
            })

        }
    }
    useEffect(() => {
        if (createAlbumZipError) {
            addMessage({
                location: "ListSubDirectories",
                type: EnumMessageType.Error,
                message: createAlbumZipError.message,
            })
        } else if (createAlbumZipData) {
            if (createAlbumZipData.createAlbumZip.statusCode === 200) {
                setAllRefetchNeeded("ListSubDirectories")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Zip créé.",
                })
            } else {
                createAlbumZipData.createAlbumZip.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [createAlbumZipData, createAlbumZipError])

    return (
        <>
            <FloatingSpinner dependances={[deleteFotosLoading, deleteVideosLoading, deleteAudiosLoading, createAlbumZipLoading]} />
            <div className='ListFotos'>
                <Container className="ListFotos-actions"
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        mt: "12px",
                    }}
                >
                    {
                        hasSelectedElement && props.album.mode === EnumListAlbumsMode.owned && <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Supprimer les éléments sélectionnés">
                            <IconButton onClick={handleDeleteSelection} color="error">
                                <DeleteOutline />
                            </IconButton>
                        </Tooltip>
                    }
                    {
                        hasSelectedElement && <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Zipper les éléments sélectionnés">
                            <IconButton onClick={handleZipSelection} color="primary">
                                <ZipIcon color="desktop" />
                            </IconButton>
                        </Tooltip>
                    }
                    {
                        hasSelectedElement && putInPocketSelectedElements &&
                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Placer les éléments sélectionnés dans la pocket">
                            <IconButton onClick={handlePutInPocket} color="primary">
                                <PocketIcon variant={EnumSvgVariant.primary} sx={{ fontSize: 18 }} />
                            </IconButton>
                        </Tooltip>
                    }
                    {
                        hasSelectedElement && !putInPocketSelectedElements &&
                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Retirer les éléments sélectionnés de la pocket">
                            <IconButton onClick={handleRemoveFromPocket} color="secondary">
                                <PocketIcon variant={EnumSvgVariant.secondary} sx={{ fontSize: 18 }} />
                            </IconButton>
                        </Tooltip>
                    }
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={selectAllElements ? "Sélectionner tout" : "Désélectionner tout"}>
                        <IconButton onClick={handleSelectAll} color={selectAllElements ? "secondary" : "cancel"}>
                            <CropFreeOutlined />
                        </IconButton>
                    </Tooltip>
                    {
                        props.album.mode === EnumListAlbumsMode.owned && <>
                            <Divider orientation="vertical" flexItem variant="middle" />
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Ajouter des items">
                                <IconButton color="primary" onClick={() => setShowModalNewFotos(true)}><CloudUploadOutlinedIcon fontSize="medium" /></IconButton>
                            </Tooltip>
                            <ModalNewFotos
                                album={props.album}
                                open={showModalNewFotos}
                                handleClose={closeModalNewFotos}
                            />
                        </>
                    }
                </Container>

                <Container className="ListFotos-container">
                    <Grid container spacing={0} className="ListFotos-grid-container">
                        {
                            props.album.albumItems?.map((albumItem, index) => {
                                if (albumItem.albumItemType === EnumAlbumItemType.foto && albumItem.foto) {
                                    return (
                                        <Grid key={`${albumItem.id}`} size={{ xs: 12, md: 6, lg: 4 }} display="flex" justifyContent="center" alignItems="flex-start">
                                            <Foto
                                                foto={albumItem.foto}
                                                selected={albumItem.selected}
                                                onClick={handleFotoClick}
                                                index={index}
                                            />
                                        </Grid>
                                    )
                                }
                                if (albumItem.albumItemType === EnumAlbumItemType.video && albumItem.video) {
                                    return (
                                        <Grid key={`${albumItem.id}`} size={{ xs: 12, md: 6, lg: 4 }} display="flex" justifyContent="center" alignItems="flex-start">
                                            <VideoComponent
                                                video={albumItem.video}
                                                selected={albumItem.selected}
                                                onClick={handleVideoClick}
                                                index={index}
                                            />
                                        </Grid>
                                    )
                                }
                                if (albumItem.albumItemType === EnumAlbumItemType.audio && albumItem.audio) {
                                    return (
                                        <Grid key={`${albumItem.id}`} size={{ xs: 12, md: 6, lg: 4 }} display="flex" justifyContent="center" alignItems="flex-start">
                                            <Audio
                                                audio={albumItem.audio}
                                                selected={albumItem.selected}
                                                onClick={handleAudioClick}
                                                index={index}
                                            />
                                        </Grid>
                                    )
                                }
                            })
                        }
                        {
                            props.album !== undefined && props.album.albumItems !== undefined && props.album.albumItems.length === 0 &&
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <Typography color="text.secondary" sx={{ fontSize: 24 }}>
                                    Aucune photo.
                                </Typography>
                            </Box>
                        }
                        {
                            props.album && props.album.albumItems && <Lightbox
                                open={openedLightbox}
                                index={lightboxIndex}
                                close={() => setOpenedLightbox(false)}
                                slides={
                                    // props.album.albumItems.filter((albumItem) => [EnumAlbumItemType.foto, EnumAlbumItemType.video].includes(albumItem.albumItemType)).map((albumItem) => {
                                    props.album.albumItems.map((albumItem) => {
                                        if (albumItem.albumItemType === EnumAlbumItemType.video && albumItem.video) {
                                            return {
                                                type: "video",
                                                autoPlay: true,
                                                // width: 1280,
                                                // height: 720,
                                                width: 3840,
                                                height: 2560,
                                                poster: albumItem.video.videoPreviewUrl,
                                                sources: [
                                                    {
                                                        src: albumItem.video.mp4VideoFileUrl,
                                                        type: "video/mp4",
                                                    },
                                                ],
                                            }
                                        } else if (albumItem.albumItemType === EnumAlbumItemType.audio && albumItem.audio) {
                                            // on simule une video pour les audios
                                            return {
                                                type: "video",
                                                autoPlay: true,
                                                width: 3840,
                                                height: 2560,
                                                sources: [
                                                    {
                                                        src: albumItem.audio.audioFileUrl,
                                                        type: "video/mp4",
                                                    },
                                                ],
                                            }
                                        } else {
                                            return {
                                                src: albumItem.foto?.fotoFileUrl || "",
                                                alt: `photo ${albumItem.foto?.id}`,
                                                width: 3840,
                                                height: 2560,
                                                // srcSet: [
                                                //     { src: albumItem.foto?.fotoPreviewUrl || "", width: 300, height: 300 },
                                                //     { src: albumItem.foto?.fotoFileUrl || "", width: 3840, height: 2560 },
                                                // ],
                                            }
                                        }

                                    })
                                }
                                plugins={[Fullscreen, Slideshow, Video]}
                                fullscreen={{
                                    auto: true
                                }}
                            />
                        }
                    </Grid>
                </Container>
            </div>
            <ModalDeleteConfirm
                open={showDeleteElementsDialog}
                title="Suppression des items"
                content={
                    getSelectedAlbumItems().length > 0
                        ?
                        `Confirmez-vous la suppression définitive de ces items ?`
                        :
                        `Confirmez-vous la suppression définitive de cet item ?`
                }
                handleClose={closeDeleteElementsDialog}
                handleConfirm={confirmedDeleteElements}
                danger={true}
            />
            <ModalNewAlbumZip
                open={showZipElementsDialog}
                handleClose={closeZipElementsDialog}
                handleConfirm={confirmedZipElements}
            />
        </>
    )
}
