import { Button, Typography, Avatar, Box, useTheme, Link } from '@mui/material'
import Grid from '@mui/material/Grid2';
import { FC, useState } from 'react'
import { useNavigate } from "react-router"
import { useDispatch } from "react-redux"
import { Layout } from "./Layout"
import { MetadocLogo } from "../components/MetadocLogo"
import { EnumBasePathFor, EnumListLockersMode, EnumLockerIconMode, EnumMetadocLogoMode, EnumNewsInfoIcon, EnumSize, EnumUserInfosMode } from "../enums"
import { useGetCurrentUser } from "../redux/features/users/currentUserSlice"
import { constructPath, getBasePath, NAVIGATE_ALBUMS, NAVIGATE_CASHFLOW, NAVIGATE_CONTACTS, NAVIGATE_DESKTOP, NAVIGATE_PASSWORDS, NAVIGATE_PROFILE, NAVIGATE_REMINDERS, NAVIGATE_SEARCH, NAVIGATE_SHARED, NAVIGATE_VAULT } from "../utils/Navigation"
import { NewsInfo } from "../components/NewsInfo"
import { setSearchParamsAction } from "../redux/features/search/searchSlice"
import dayjs from 'dayjs'
import { pluralize } from "../utils/Utils"
import { LockerIcon } from "../components/svg_icons/LockerIcon"
import { VaultIcon } from "../components/svg_icons/VaultIcon"
import { SharedIcon } from "../components/svg_icons/SharedIcon"
import { KeysIcon } from "../components/svg_icons/KeysIcon"
import { CameraIcon } from "../components/svg_icons/CameraIcon"
import { ContactIcon } from "../components/svg_icons/ContactIcon"
import { Flexbox } from "../components/utils/Flexbox"
import { DirectoryShortcutted } from '../components/directories/DirectoryShortcutted'
import { Todolist } from '../components/todo_list/Todolist'
import { BankAccountIcon } from '../components/svg_icons/BankAccountIcon'
import { ContactBadge } from '../components/contacts/ContactBadge'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import BoltIcon from '@mui/icons-material/Bolt';
import { ModalQuickDrop } from '../components/quickDrop/ModalQuickDrop';
import { UserInfos } from '../components/profile/UserInfos';
import { LockerShortcutted } from '../components/directories/LockerShortcutted';

interface IProps {
}

export const Home: FC<IProps> = (props) => {
  const currentUser = useGetCurrentUser()
  const [showModalQuickDrop, setShowModalQuickDrop] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useTheme()

  const nbPendingPartnerships = () => {
    let res = 0
    if (currentUser?.partnerships && currentUser?.partnerships?.length > 0) {
      currentUser.partnerships.forEach(partnership => {
        if (!partnership.confirmed && partnership.isResponder) {
          res += 1
        }
      })
    }
    return res
  }

  const getDeliveryPath = () => {
    let res = ""
    if (currentUser?.deliveryInfos?.lockerId) {
      res = constructPath(getBasePath(EnumBasePathFor.locker, EnumListLockersMode.desktop), [
        { key: "lockerId", value: currentUser.deliveryInfos.lockerId.toString() }
      ])
    }
    return res
  }

  const navigateTo = (path: string) => {
    navigate(path)
  }

  const searchLastDocuments = () => {
    // const fiveDaysAgo = dayjs().subtract(5, 'day').toDate();
    // const dateFrom = fiveDaysAgo.format("DD/MM/YYYY")
    const fiveDaysAgo = dayjs().subtract(5, 'day');
    const dateFrom = fiveDaysAgo.format("DD/MM/YYYY")
    const payload = {
      searchQuery: "*",
      targets: {
        desktopLockers: false,
        desktopDirectories: false,
        desktopDocuments: true,
        vaultLockers: false,
        vaultDirectories: false,
        vaultDocuments: true,
        sharedLockers: false,
        sharedDirectories: false,
        sharedDocuments: true,
        desktopNotes: false,
        vaultNotes: false,
        sharedNotes: false,
      },
      dates: {
        dateFrom: dateFrom,
        dateTo: "",
      },
    }
    dispatch(setSearchParamsAction(payload))
    navigate(NAVIGATE_SEARCH)
  }

  const closeModalQuickDrop = () => {
    setShowModalQuickDrop(false)
  }

  const noNews = () => {
    if (nbPendingPartnerships() > 0) {
      return false
    }
    if (currentUser?.deliveryInfos?.documentsCount &&
      currentUser?.deliveryInfos?.documentsCount > 0) {
      return false
    }
    if (currentUser?.deliveryInfos?.notesCount &&
      currentUser?.deliveryInfos?.notesCount > 0) {
      return false
    }
    if (currentUser && currentUser.contactsInfos.incomingBirthdays.length > 0) {
      return false
    }
    return true
  }

  return (
    <>
      <div className="Home">
        <Layout>
          <Grid container
            // spacing={2}
            sx={{
              pt: 6,
              pb: 5,
              px: {
                xs: 1,
                md: 4,
              },
              // bgcolor: "green",
            }}>
            <Grid size={{ xs: 12, md: 6 }} display="flex" justifyContent="center" alignItems="flex-start" sx={{
              // bgcolor: "blue",
            }}>
              <Flexbox direction="column">
                {/* <MetadocLogo mode={EnumMetadocLogoMode.accueil} link={true} />
                <Typography variant="h4" color="primary" sx={{
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                }}>
                  Metadoc
                </Typography>
                <Flexbox>
                  <Avatar className="avatar-icon" alt={`${currentUser?.firstname} ${currentUser?.lastname}`} src={`${currentUser?.userAvatarPreviewUrl}`} sx={{
                    mr: 1.5,
                  }} />
                  <Typography variant="h5" color="primary">
                    {currentUser?.firstname} {currentUser?.lastname}
                  </Typography>
                </Flexbox> */}
                {
                  currentUser && <>
                    <Flexbox direction='column' sx={{
                      // bgcolor: "red",
                    }}>
                      <UserInfos user={currentUser} mode={EnumUserInfosMode.dashboard} />
                    </Flexbox>
                  </>
                }
                <Flexbox direction='column' sx={{
                  mt: 4,
                }}>
                  <Flexbox sx={{
                    mb: 2,
                  }}>
                    <Button onClick={() => setShowModalQuickDrop(true)} startIcon={<BoltIcon color="secondary" sx={{ fontSize: 24 }} />} color="primary" variant="text">
                      Action rapide
                    </Button>
                  </Flexbox>
                  {
                    noNews() && <>
                      <Typography sx={{
                        color: theme.palette.text.secondary,
                        fontStyle: "italic",
                        fontSize: 16,
                        mt: { xs: 1, md: 4 },
                      }}>
                        Aucune notification..
                      </Typography>
                    </>
                  }

                  {
                    nbPendingPartnerships() > 0 &&
                    <NewsInfo
                      icon={EnumNewsInfoIcon.partnership}
                      title={`${nbPendingPartnerships()} ${pluralize("demande", nbPendingPartnerships())} de partenariat en attente.`}
                      handleAction={() => navigateTo(NAVIGATE_PROFILE)}
                    />
                  }
                  {
                    currentUser?.deliveryInfos?.documentsCount &&
                      currentUser?.deliveryInfos?.documentsCount > 0 ? (
                      <NewsInfo
                        icon={EnumNewsInfoIcon.delivery}
                        title={`${currentUser.deliveryInfos.documentsCount} ${pluralize("document a été placé", currentUser.deliveryInfos.documentsCount, "documents ont été placés")} dans votre dépôt.`}
                        handleAction={() => navigateTo(getDeliveryPath())}
                      />
                    ) : null
                  }
                  {
                    currentUser?.deliveryInfos?.notesCount &&
                      currentUser?.deliveryInfos?.notesCount > 0 ? (
                      <NewsInfo
                        icon={EnumNewsInfoIcon.delivery}
                        title={`${currentUser.deliveryInfos.notesCount} ${pluralize("note a été placée", currentUser.deliveryInfos.notesCount, "notes ont été placées")} dans votre dépôt.`}
                        handleAction={() => navigateTo(getDeliveryPath())}
                      />
                    ) : null
                  }
                  {
                    currentUser && currentUser.contactsInfos.incomingBirthdays.length > 0 && (
                      <>
                        {
                          currentUser.contactsInfos.incomingBirthdays.map((incomingBirthday, incomingBirthdayIndex) => (
                            <NewsInfo
                              key={`incoming-birthday-index-${incomingBirthdayIndex}`}
                              icon={EnumNewsInfoIcon.birthday}
                              title={`Le ${dayjs(incomingBirthday.birthAt).format("DD/MM")} c'est l'anniversaire de :`}
                              handleAction={() => navigateTo(NAVIGATE_CONTACTS)}
                            >
                              <ContactBadge contact={incomingBirthday} size={EnumSize.small} displayContactTitle={true} />
                            </NewsInfo>
                          ))
                        }
                      </>
                    )
                  }
                  {
                    // TODO: à remettre or what ?
                    false && <>
                      <NewsInfo
                        icon={EnumNewsInfoIcon.document}
                        title="Voir les documents des 5 derniers jours."
                        handleAction={() => searchLastDocuments()}
                      />
                    </>
                  }
                </Flexbox>
              </Flexbox>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }} display="flex" justifyContent="center" alignItems="flex-start" sx={{
              // bgcolor: "yellow",
              borderLeftStyle: { xs: "none", md: "solid" },
              borderLeftColor: theme.palette.divider,
              px: { xs: 1, md: 4 },
              pt: 2,
            }}>
              <Flexbox direction='column' sx={{
                // bgcolor: "red",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}>
                <Flexbox sx={{
                  gap: 2,
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}>
                  {/* <Button onClick={() => setShowModalQuickDrop(true)} startIcon={<BoltIcon color="secondary" sx={{ fontSize: 24 }} />} color="primary" variant="outlined">
                    Action rapide
                  </Button> */}
                  <Link href={NAVIGATE_DESKTOP} style={{ textDecoration: 'none' }}>
                    <Button startIcon={<LockerIcon mode={EnumLockerIconMode.standard} color="desktop" sx={{ fontSize: 24 }} />} color="primary" variant="outlined">
                      Bureau
                    </Button>
                  </Link>
                  <Link href={NAVIGATE_VAULT} style={{ textDecoration: 'none' }}>
                    <Button startIcon={<VaultIcon color="vault" sx={{ fontSize: 24 }} />} color="primary" variant="outlined">
                      Coffre
                    </Button>
                  </Link>
                  <Link href={NAVIGATE_SHARED} style={{ textDecoration: 'none' }}>
                    <Button startIcon={<SharedIcon color="shared" sx={{ fontSize: 24 }} />} color="primary" variant="outlined">
                      Partage
                    </Button>
                  </Link>
                  <Link href={NAVIGATE_ALBUMS} style={{ textDecoration: 'none' }}>
                    <Button startIcon={<CameraIcon sx={{ fontSize: 24, color: theme.palette.info.main }} />} color="primary" variant="outlined">
                      Albums
                    </Button>
                  </Link>
                  <Link href={NAVIGATE_PASSWORDS} style={{ textDecoration: 'none' }}>
                    <Button startIcon={<KeysIcon color="secondary" sx={{ fontSize: 24 }} />} color="primary" variant="outlined">
                      Passwords
                    </Button>
                  </Link>
                  <Link href={NAVIGATE_CONTACTS} style={{ textDecoration: 'none' }}>
                    <Button startIcon={<ContactIcon sx={{ fontSize: 24, color: theme.palette.desktop.main }} />} color="primary" variant="outlined">
                      Contacts
                    </Button>
                  </Link>
                  <Link href={NAVIGATE_CASHFLOW} style={{ textDecoration: 'none' }}>
                    <Button startIcon={<BankAccountIcon sx={{ fontSize: 24, color: theme.palette.desktop.main }} />} color="primary" variant="outlined">
                      Cashflow
                    </Button>
                  </Link>
                  <Link href={NAVIGATE_REMINDERS} style={{ textDecoration: 'none' }}>
                    <Button startIcon={<AccessAlarmIcon sx={{ fontSize: 24, color: theme.palette.vault.main }} />} color="primary" variant="outlined">
                      Reminders
                    </Button>
                  </Link>
                  {
                    currentUser && currentUser.lockersShortcutted.length > 0 &&
                    currentUser.lockersShortcutted.map((lockerShortcutted, lockerShortcuttedIndex) => {
                      return <LockerShortcutted key={`locker-shortcutted-index-${lockerShortcuttedIndex}`} locker={lockerShortcutted} />
                    })
                  }
                  {
                    currentUser && currentUser.directoriesShortcutted.length > 0 &&
                    currentUser.directoriesShortcutted.map((directoryShortcutted, directoryShortcuttedIndex) => {
                      return <DirectoryShortcutted key={`directory-shortcutted-index-${directoryShortcuttedIndex}`} directory={directoryShortcutted} />
                    })
                  }
                </Flexbox>
                <Flexbox sx={{
                  // bgcolor: "red",
                  width: "100%",
                }}>
                  <Todolist />
                </Flexbox>

              </Flexbox>
            </Grid>
          </Grid>
        </Layout>
      </div >
      <ModalQuickDrop
        open={showModalQuickDrop}
        handleClose={closeModalQuickDrop}
      />
    </>
  )
}
