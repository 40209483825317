import * as React from 'react';
import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from "react-router"
import { NAVIGATE_ROOT } from "../utils/Navigation"
import { useDispatch, useSelector } from "react-redux";
import { setJwt } from "../redux/features/global/apolloSlice";
import { IRootState } from "../redux/store"
import { useMutation } from "@apollo/client"
import { GQL_MUTATION_LOGIN, GQL_MUTATION_SEND_FORGOTTEN_PASSWORD_MAIL } from "../graphql/Mutations";
import { Messages } from "../components/messages/Messages";
import { addMessage } from "../components/messages/Message";
import { EnumMessageType, EnumMetadocLogoMode } from "../enums";
import { MetadocLogo } from '../components/MetadocLogo';
import { Copyright } from '../components/utils/Copyright';


// function Copyright(props: any) {
//     return (
//         <Typography variant="body2" color="text.secondary" align="center" {...props}>
//             {'Copyright © '}
//             <Link color="inherit" href={process.env.REACT_APP_METADOC_WEB_URI}>
//                 Metadoc
//             </Link>{' '}
//             {new Date().getFullYear()}
//             {'.'}
//         </Typography>
//     );
// }


export default function Login() {
    let navigate = useNavigate()
    const dispatch = useDispatch()
    const hasJwt = useSelector((state: IRootState) => state.apolloReducer.hasJwt)

    React.useEffect(() => {
        if (hasJwt) {
            // Il y a un JWT, on redirect sur home..
            navigate(`${NAVIGATE_ROOT}`)
        }
    }, [])

    const [email, setEmail] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [login, { data: loginData, loading, error }] = useMutation(GQL_MUTATION_LOGIN)
    const [sendForgottenPasswordMail, { data: sendForgottenPasswordMailData, loading: sendForgottenPasswordMailLoading, error: sendForgottenPasswordMailError }] = useMutation(GQL_MUTATION_SEND_FORGOTTEN_PASSWORD_MAIL)


    React.useEffect(() => {
        if (error) {
            addMessage({
                location: "Login",
                type: EnumMessageType.Error,
                message: error.message,
            })
        } else if (loginData) {
            if (loginData.login.statusCode === 200) {
                dispatch(setJwt(loginData.login.authToken))
                // Refresh page: (redirect sur home au reload..)
                navigate(0)
                // navigate(NAVIGATE_ROOT)
            } else {
                loginData.login.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [loginData, error])

    React.useEffect(() => {
        if (sendForgottenPasswordMailError) {
            addMessage({
                location: "Login",
                type: EnumMessageType.Error,
                message: sendForgottenPasswordMailError.message,
            })
        } else if (sendForgottenPasswordMailData) {
            if (sendForgottenPasswordMailData.sendForgottenPasswordMail.statusCode === 200) {
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Un mail de changement de mot de passe vous a été envoyé."
                })
            } else {
                sendForgottenPasswordMailData.sendForgottenPasswordMail.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [sendForgottenPasswordMailData, sendForgottenPasswordMailError])

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        login({
            variables: {
                email: email,
                password: password,
            }
        })
    };

    const handleForgottenPassword = () => {
        if (email === undefined || email === "") {
            addMessage({
                type: EnumMessageType.Error,
                message: "Merci de saisir votre email.",
            }
            )
        } else {
            sendForgottenPasswordMail({
                variables: {
                    email: email,
                }
            })
        }
    }

    return (
        <div className="Login">
            <Messages />
            <Container className="Login-container" component="main" maxWidth="xs">
                {/* <CssBaseline /> */}
                <Box
                    sx={{
                        paddingTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <MetadocLogo mode={EnumMetadocLogoMode.accueil} link={true} />
                    <Typography component="h1" variant="h5" color="primary">
                        Connexion
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            color="primary"
                            onChange={(event) => { setEmail(event.target.value) }}
                            value={email}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Mot de passe"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={(event) => { setPassword(event.target.value) }}
                            value={password}
                        />
                        {/* <FormControlLabel
                            control={<Checkbox value="remember" defaultChecked={true} color="primary" />}
                            label={<Typography color="primary">Rester connecté</Typography>}
                        /> */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Se connecter
                        </Button>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link href='#' onClick={handleForgottenPassword} variant="body2">
                                    Mot de passe oublié ?
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </div>
    );
}