import { createSlice } from "@reduxjs/toolkit";
import { IAlbum } from "../../../interfaces";
import { useSelector } from "react-redux";
import { IRootState, store } from "../../store";
import { apolloClient } from "../../../ApolloClient";
import { GQL_ALBUM } from "../../../graphql/Queries";
import { EnumAlbumItemType } from "../../../enums";


interface IAlbumSliceState {
    album: undefined | IAlbum;
    refetchNeeded: boolean
}

const initialState: IAlbumSliceState = {
    album: undefined,
    refetchNeeded: false,
}

export const albumSlice = createSlice({
    name: "albumSlice",
    initialState,
    reducers: {
        setAlbumAction: (state, action) => {
            state.album = action.payload
        },
        updateAlbumAction: (state, action) => {
            state.album = { ...state.album, ...action.payload }
        },
        setAlbumRefetchNeeded: (state, action) => {
            state.refetchNeeded = action.payload
        },
        setSizeInfosAlbumAction: (state, action) => {
            const payloadAlbums = action.payload.albums
            payloadAlbums.forEach((payloadAlbum: IAlbum) => {
                if (state.album && state.album.id === payloadAlbum.id) {
                    state.album = { ...state.album, sizeInfos: payloadAlbum.sizeInfos }
                }
            })
        },
        selectAllAlbumItemsAction: (state) => {
            if (state.album) {
                state.album.albumItems = state.album.albumItems.map(
                    albumItem => {
                        return { ...albumItem, selected: true }
                    }
                )
            }
        },
        unSelectAllAlbumItemsAction: (state) => {
            if (state.album) {
                state.album.albumItems = state.album.albumItems.map(
                    albumItem => {
                        return { ...albumItem, selected: false }
                    }
                )
            }
        },
        toggleSelectFotoAction: (state, action) => {
            const fotoId = action.payload
            if (state.album) {
                state.album.albumItems = state.album.albumItems?.map(
                    albumItem => {
                        return albumItem.albumItemType === EnumAlbumItemType.foto && albumItem.foto?.id === fotoId ? { ...albumItem, selected: !albumItem.selected } : albumItem
                    }
                )
            }
        },
        toggleSelectVideoAction: (state, action) => {
            const videoId = action.payload
            if (state.album) {
                state.album.albumItems = state.album.albumItems?.map(
                    albumItem => {
                        return albumItem.albumItemType === EnumAlbumItemType.video && albumItem.video?.id === videoId ? { ...albumItem, selected: !albumItem.selected } : albumItem
                    }
                )
            }
        },
        toggleSelectAudioAction: (state, action) => {
            const audioId = action.payload
            if (state.album) {
                state.album.albumItems = state.album.albumItems?.map(
                    albumItem => {
                        return albumItem.albumItemType === EnumAlbumItemType.audio && albumItem.audio?.id === audioId ? { ...albumItem, selected: !albumItem.selected } : albumItem
                    }
                )
            }
        },


    }
})

export const {
    setAlbumRefetchNeeded,
    setAlbumAction,
    updateAlbumAction,
    setSizeInfosAlbumAction,
    selectAllAlbumItemsAction,
    unSelectAllAlbumItemsAction,
    toggleSelectFotoAction,
    toggleSelectVideoAction,
    toggleSelectAudioAction,
} = albumSlice.actions


export const useGetAlbum = (albumId: string) => {
    const album: (undefined | IAlbum) = useSelector((state: IRootState) => state.albumReducer.album)
    const refetchNeeded: boolean = useSelector((state: IRootState) => state.albumReducer.refetchNeeded)
    if (refetchNeeded || album === undefined || album.id !== albumId) {
        apolloClient.query({
            query: GQL_ALBUM,
            fetchPolicy: 'network-only',
            variables: {
                albumId,
            }
        }
        ).then(response => {
            store.dispatch(setAlbumAction(response.data.album))
            store.dispatch(setAlbumRefetchNeeded(false))
            return response.data.album
        })
    } else {
        return album
    }
}

export default albumSlice.reducer