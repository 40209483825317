import { Box, Divider, IconButton, Paper, Skeleton, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { IVideo } from "../../interfaces";
import {
    CropFreeOutlined,
    DeleteOutline
} from '@mui/icons-material';

import { Flexbox } from "../utils/Flexbox";
import { ModalDeleteConfirm } from '../modals/ModalDeleteConfirm';
import { useMutation } from '@apollo/client';
import { addMessage } from '../messages/Message';
import { EnumMessageType, EnumSvgVariant } from '../../enums';
import { setAllRefetchNeeded } from '../../redux/store';
import { GQL_MUTATION_ADD_IN_POCKET, GQL_MUTATION_DELETE_VIDEO, GQL_MUTATION_REMOVE_FROM_POCKET } from '../../graphql/Mutations';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import { PocketIcon } from '../svg_icons/PocketIcon';

import {
    addVideosAction as addVideosInPocketAction,
    removeVideosAction as removeVideosFromPocketAction,
    useGetVideos as useGetPocketVideos,
} from "../../redux/features/pocket/pocketSlice";
import { useDispatch } from 'react-redux';
import { toggleSelectVideoAction } from '../../redux/features/albums/albumSlice';

interface IProps {
    video: IVideo,
    selected?: boolean,
    onClick: (index: number) => void,
    index: number,
}

export const Video: FC<IProps> = (props) => {
    const [previewLoaded, setPreviewLoaded] = useState(false);
    const [showDeleteVideoDialog, setShowDeleteVideoDialog] = useState(false)
    const [deleteVideo, { data: deleteVideoData, loading: deleteVideoLoading, error: deleteVideoError }] = useMutation(GQL_MUTATION_DELETE_VIDEO)

    const pocketVideos = useGetPocketVideos()
    const [addInPocket, { data: addInPocketData, loading: addInPocketLoading, error: addInPocketError }] = useMutation(GQL_MUTATION_ADD_IN_POCKET)
    const [removeFromPocket, { data: removeFromPocketData, loading: removeFromPocketLoading, error: removeFromPocketError }] = useMutation(GQL_MUTATION_REMOVE_FROM_POCKET)

    const theme = useTheme()
    const dispatch = useDispatch()

    const handlePreviewLoaded = () => {
        setPreviewLoaded(true);
    }

    useEffect(() => {
        if (deleteVideoError) {
            addMessage({
                location: "Video",
                type: EnumMessageType.Error,
                message: deleteVideoError.message,
            })
        } else if (deleteVideoData) {
            if (deleteVideoData.deleteVideo.statusCode === 200) {
                setAllRefetchNeeded("Video")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Video supprimée.",
                })
            } else {
                deleteVideoData.deleteVideo.errors.map((error: string) => {
                    addMessage({
                        location: "Video",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteVideoData, deleteVideoError])

    useEffect(() => {
        if (addInPocketError) {
            addMessage({
                location: "Video",
                type: EnumMessageType.Error,
                message: addInPocketError.message,
            })
        } else if (addInPocketData) {
            if (addInPocketData.addInPocket.statusCode === 200) {
                dispatch(addVideosInPocketAction([props.video]))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "C'est dans la pocket.",
                })
            } else {
                addInPocketData.addInPocket.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [addInPocketData, addInPocketError])

    useEffect(() => {
        if (removeFromPocketError) {
            addMessage({
                location: "Video",
                type: EnumMessageType.Error,
                message: removeFromPocketError.message,
            })
        } else if (removeFromPocketData) {
            if (removeFromPocketData.removeFromPocket.statusCode === 200) {
                dispatch(removeVideosFromPocketAction([props.video.id]))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Retiré de la pocket.",
                })
            } else {
                removeFromPocketData.removeFromPocket.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [removeFromPocketData, removeFromPocketError])


    const handleDelete = () => {
        setShowDeleteVideoDialog(true)
    }

    const closeDeleteVideoDialog = () => {
        setShowDeleteVideoDialog(false)
    }

    const confirmedDeleteVideo = () => {
        deleteVideo({
            variables: {
                videoId: props.video.id
            }
        })
        setShowDeleteVideoDialog(false)
    }

    const isInPocket = () => {
        if (pocketVideos) {
            return pocketVideos.filter(video => video.id === props.video.id).length > 0
        }
        return false
    }

    const handlePutInPocket = () => {
        addInPocket({
            variables: {
                videoIds: [props.video.id],
            }
        })
    }

    const handleRemoveFromPocket = () => {
        removeFromPocket({
            variables: {
                videoIds: [props.video.id],
            }
        })
    }

    const handleSelect = () => {
        dispatch(toggleSelectVideoAction(props.video.id))
    }

    return (
        <>
            <Paper className={`model-video-${props.video.id}`}
                sx={{
                    marginTop: "24px",
                    marginBottom: "12px",
                    marginLeft: "12px",
                    marginRight: "12px",
                    width: "320px",
                    boxShadow: props.selected ? `0px 0px 4px 2px ${theme.palette.secondary.main}` : "",
                }}>
                <Box>
                    {
                        props.video.videoPreviewUrl === null && <Flexbox sx={{
                            height: "200px",
                            px: 1,
                            py: 1,
                            backgroundColor: theme.palette.fotoBackground.main,
                            borderTopLeftRadius: 4,
                            borderTopRightRadius: 4,
                        }}>
                            <Typography sx={{
                                color: "white",
                                textAlign: "center",
                                // cursor: "pointer",
                                // ":hover": {
                                //     color: theme.palette.secondary.main,
                                // }
                            }}
                            // onClick={() => props.onClick(props.index)}
                            >
                                Video en construction
                                <br />
                                <Typography sx={{ color: theme.palette.cancel.main }}>
                                    (Patientez puis recharchez la page..)
                                </Typography>
                            </Typography>
                        </Flexbox>
                    }
                    {
                        props.video.videoPreviewUrl && <>
                            <Flexbox
                                onClick={() => props.onClick(props.index)}
                                sx={{
                                    // bgcolor: "red",
                                    width: "304px", // 320 - 2*8 (padding)
                                    height: "200px",
                                    // backgroundColor: theme.palette.fotoBackground.main,
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4,
                                    position: "absolute",
                                    cursor: "pointer",
                                }}>
                                <PlayCircleOutlinedIcon sx={{ fontSize: 50, color: "white" }} />
                            </Flexbox>
                            <Flexbox sx={{
                                px: 1,
                                py: 1,
                                backgroundColor: theme.palette.fotoBackground.main,
                                borderTopLeftRadius: 4,
                                borderTopRightRadius: 4,
                            }}>
                                {!previewLoaded && <Skeleton variant="rectangular" width={200} height={200} sx={{ borderRadius: 1 }} />}
                                <Box component="img"
                                    src={props.video.videoPreviewUrl}
                                    onLoad={handlePreviewLoaded}
                                    sx={{
                                        maxHeight: "200px",
                                        maxWidth: "300px",
                                        borderRadius: 1,
                                        display: previewLoaded ? 'block' : 'none',
                                        cursor: "pointer",
                                    }}
                                />

                            </Flexbox>
                        </>
                    }


                    <Box>
                        <Divider />
                        <Flexbox sx={{
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                        }}>
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Télécharger">
                                <IconButton href={props.video.originalVideoFileUrlForDownload} color="primary">
                                    <CloudDownloadOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={props.selected ? "Désélectionner" : "Sélectionner"}>
                                <IconButton onClick={handleSelect} color={props.selected ? "cancel" : "secondary"}>
                                    <CropFreeOutlined />
                                </IconButton>
                            </Tooltip>
                            {
                                isInPocket() ?
                                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Retirer de la pocket">
                                        <IconButton onClick={handleRemoveFromPocket} color="secondary">
                                            <PocketIcon variant={EnumSvgVariant.secondary} />
                                        </IconButton>
                                    </Tooltip> :
                                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Placer dans la pocket">
                                        <IconButton onClick={handlePutInPocket} color="primary">
                                            <PocketIcon variant={EnumSvgVariant.primary} />
                                        </IconButton>
                                    </Tooltip>
                            }
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Supprimer">
                                <IconButton onClick={handleDelete} color="error">
                                    <DeleteOutline />
                                </IconButton>
                            </Tooltip>
                        </Flexbox>
                    </Box>
                </Box>
            </Paper>
            <ModalDeleteConfirm
                open={showDeleteVideoDialog}
                title="Suppression de la video"
                content={`Confirmez-vous la suppression définitive de la video ?`}
                handleClose={closeDeleteVideoDialog}
                handleConfirm={confirmedDeleteVideo}
            />
        </>
    )
}
