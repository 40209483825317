import { Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import "yet-another-react-lightbox/styles.css";
import { IAccountOperation } from '../../../interfaces';
import 'dayjs/locale/fr';
import { EnumAccountOperationColorTarget, EnumAccountOperationType } from '../../../enums';
import { formatFancyCurrency } from '../../../utils/Utils';


interface IProps {
    accountOperation: IAccountOperation,
}

export const AccountOperationSpan: FC<IProps> = (props) => {

    const theme = useTheme()

    const getAccountOperationColor = (target: EnumAccountOperationColorTarget) => {
        if (!props.accountOperation.imputable) {
            return theme.palette.cancel.main
        }
        if (props.accountOperation.accountOperationType === EnumAccountOperationType.credit) {
            return target === EnumAccountOperationColorTarget.title ? theme.palette.text.primary : theme.palette.credit.main
        }
        return target === EnumAccountOperationColorTarget.title ? theme.palette.text.primary : theme.palette.debit.main
    }

    return (
        <>
            <Typography component="span" sx={{
                color: theme.palette.primary.main,
                fontStyle: "italic",
            }}>
                {props.accountOperation.title} ( <Typography component="span" sx={{ color: getAccountOperationColor(EnumAccountOperationColorTarget.default) }}> {formatFancyCurrency(parseFloat(props.accountOperation.amount), props.accountOperation.accountOperationType)}</Typography> )
            </Typography>
        </>
    )
}
