
export enum EnumMessageType {
    Success = 1,
    Error,
    Info,
    Warning,
}

export enum EnumMetadocLogoMode {
    standard = "standard",
    accueil = "accueil",
    tiny = "tiny",
    publik = "publik",
}


export enum EnumListLockersMode {
    desktop = "desktop",
    vault = "vault",
    shared = "shared",
}

export enum EnumLockerIconMode {
    standard = "standard",
    delivery = "delivery",
}

export enum EnumSize {
    tiny = "tiny",
    small = "small",
    medium = "medium",
    large = "large",
    xlarge = "xlarge",
}

export enum EnumSvgVariant {
    // default = "default",
    primary = "primary",
    secondary = "secondary",
}

export enum EnumPocketTargetType {
    locker = "locker",
    directory = "directory",
    album = "album",
}

export enum EnumBasePathFor {
    mode = "mode",
    locker = "locker",
    directory = "directory",
    publikDocument = "publik_document",
    publikDirectory = "publik_directory",
    album = "album",
}

export enum EnumParentType {
    locker = "locker",
    directory = "directory",
}

export enum EnumPocketTab {
    none = "none",
    pocket = "pocket",
    search = "search",
}

export enum EnumDateFormat {
    sql = "sql",
    human = "human",
}

export enum EnumPackLabel {
    starter = "starter",
    basic = "basic",
    bronze = "bronze",
    silver = "silver",
    gold = "gold",
    amethyst = "amethyst",
    ruby = "ruby",
    diamond = "diamond",
    blackDiamond = "black_diamond",
}

export enum EnumPackHumanLabel {
    starter = "Starter",
    basic = "Basic",
    bronze = "Bronze",
    silver = "Silver",
    gold = "Gold",
    amethyst = "Amethyst",
    ruby = "Ruby",
    diamond = "Diamond",
    blackDiamond = "Black diamond",
}

export enum EnumUserInfosMode {
    standard = "standard",
    admin = "admin",
    dashboard = "dashboard",
}

export enum EnumShareLevel {
    none = "none",
    viewer = "viewer",
    owner = "owner",
}

export enum EnumSliceReference {
    lockers = "lockers",
    locker = "locker",
    directory = "directory",
    pocket = "pocket",
    currentUser = "currentUser",
    admin = "admin",
    passwords = "passwords",
    albums = "albums",
    album = "album",
    fotos = "fotos",
    contacts = "contacts",
    bankAccounts = "bankAccounts",
    bankAccount = "bankAccount",
    customContacts = "customContacts",
    user = "user",
    reminders = "reminders",
}

export enum EnumGraphQLErrorCode {
    session_expired = "SESSION_EXPIRED"
}

export enum EnumContactType {
    standard = "standard",
    business = "business",
}

export enum EnumDayjsFormat {
    date = "DD/MM/YYYY",
    day_name_date = "dddd DD/MM/YYYY",
    date_hours_minutes = "DD/MM/YYYY HH:mm",
    date_hours_minutes_secondes = "DD/MM/YYYY HH:mm:ss",
    hours_minutes = "HH:mm",
    day_name_day_number = "dddd DD",
    day_number = "DD",
    day_name = "dddd",
    day_small = "ddd",
    month_name = "MMMM",
    month_name_year = "MMMM YYYY",
    day_month_name_year = "DD MMMM YYYY",
    dateForBackend = "YYYY-MM-DD",
    datetimeForBackend = "YYYY-MM-DD HH:mm:ss",
}

export enum EnumListAlbumsMode {
    standard = "standard",
    owned = "owned",
    viewed = "viewed",
}

export enum EnumModalContactDownloadVcardsMode {
    single = "single",
    multi = "multi",
}

export enum EnumNewsInfoIcon {
    none = "none",
    partnership = "partnership",
    delivery = "delivery",
    document = "document",
    birthday = "birthday",
}

export enum EnumBreadcrumbMode {
    standard = "standard",
    condensed = "condensed",
}

export enum EnumCbColor {
    cbBlue = "cbBlue",
    cbGold = "cbGold",
    cbBlack = "cbBlack",
    cbGreen = "cbGreen",
    cbRed = "cbRed",
}

export enum EnumBankAccountType {
    standard = "standard",
    business = "business",
}

export enum EnumMandatoryContactFields {
    email = "email",
}

export enum EnumAccountOperationType {
    credit = "credit",
    debit = "debit",
}

export enum EnumAccountOperationState {
    prepared = "prepared",
    created = "created",
    paid = "paid",
    consolidated = "consolidated",
}

export enum EnumAccountOperationColorTarget {
    default = "default",
    title = "title",
}

// export enum EnumCounterpartyType {
//     contact = "contact",
//     customContact = "customContact",
// }

export enum EnumMandatoryContactConditions {
    isBusiness = "isBusiness",
}

export enum EnumCustomContactSelectionMode {
    standard = "standard",
    settings = "settings",
}

export enum EnumCustomContactMode {
    standard = "standard",
    selection = "selection",
    settings = "settings",
}

export enum EnumDevice {
    desktop = "desktop",
    mobile = "mobile",
}

export enum EnumAccountOperationImputableVisibility {
    imputable = "imputable",
    notImputable = "notImputable",
    both = "both",
}

export enum EnumAccountOperationTypeVisibility {
    credit = "credit",
    debit = "debit",
    both = "both",
}

export enum EnumAlbumItemType {
    foto = "foto",
    video = "video",
    audio = "audio",
}

export enum EnumDatePeriodMode {
    byMonth = "byMonth",
    byYear = "byYear",
}
