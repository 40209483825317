import { useMutation } from '@apollo/client';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EnumMessageType } from '../../../enums';
import { GQL_MUTATION_UPDATE_DIRECTORY } from '../../../graphql/Mutations';
import { IDirectory } from '../../../interfaces';
import { addMessage } from '../../messages/Message';
import { DirectoryIcon } from '../../svg_icons/DirectoryIcon';
import { setAllRefetchNeeded } from '../../../redux/store';

interface IProps {
    open: boolean;
    handleClose: () => void;
    directory: IDirectory

}

export const ModalEditDirectory: FC<IProps> = (props) => {
    const [name, setName] = useState(props.directory.name)
    const [errorOnName, setErrorOnName] = useState(false)
    const [description, setDescription] = useState(props.directory.description)
    const nameRef = useRef<HTMLInputElement>(null);
    const [updateDirectory, { data: updateDirectoryData, loading: updateDirectoryLoading, error: updateDirectoryError }] = useMutation(GQL_MUTATION_UPDATE_DIRECTORY)
    const dispatch = useDispatch()

    useEffect(() => {
        // Le input n'est pas monté avant le test, donc on bricole avec un setTimeout..
        if (props.open) {
            setTimeout(() => {
                if (nameRef.current) {
                    nameRef.current.focus();
                }
            }, 0);
        }
    }, [props.open]);

    useEffect(() => {
        if (updateDirectoryError) {
            addMessage({
                location: "ModalEditDirectory",
                type: EnumMessageType.Error,
                message: updateDirectoryError.message,
            })
        } else if (updateDirectoryData) {
            if (updateDirectoryData.updateDirectory.statusCode === 200) {
                const updatedDirectory: IDirectory = updateDirectoryData.updateDirectory.directory
                setAllRefetchNeeded("ModalEditDirectory")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Dossier modifié.",
                })
                handleClose()
            } else {
                updateDirectoryData.updateDirectory.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [updateDirectoryData, updateDirectoryError])

    const checkErrors = () => {
        if (name === undefined || name === "") {
            setErrorOnName(true)
            return false
        }
        return true
    }

    const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (checkErrors()) {
            updateDirectory({
                variables: {
                    directoryId: props.directory.id,
                    name,
                    description,
                }
            })
        }
    }

    const handleClose = () => {
        props.handleClose()
    }

    return (
        <div className="ModalEditDirectory">
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <Box
                    noValidate
                    component="form"
                    onSubmit={handleConfirm}
                >
                    <DialogTitle color="primary">
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <DirectoryIcon color={props.directory.mode} sx={{ fontSize: 24, mr: "12px" }} />
                            <Typography sx={{ fontSize: 24 }}>
                                Modifier le dossier
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            inputRef={nameRef}
                            autoFocus
                            margin="dense"
                            label="Nom du dossier"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setName(event.target.value) }}
                            value={name}
                            required
                            error={errorOnName}
                            helperText={errorOnName ? "Ce champ est obligatoire." : ""}
                        />
                        <TextField
                            margin="normal"
                            label="Description"
                            fullWidth
                            multiline
                            rows={4}
                            onChange={(event) => { setDescription(event.target.value) }}
                            value={description}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                        <Button type="submit" variant="contained" color="primary">
                            Modifier
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div >
    )
}
