import { FC, useState } from 'react';
import { IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { Flexbox } from './Flexbox';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';
import { EnumDatePeriodMode, EnumDayjsFormat } from '../../enums';
import SettingsIcon from '@mui/icons-material/Settings';
import { ModalSelectDatePeriod } from './ModalSelectDatePeriod';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';


interface IProps {
    mode: EnumDatePeriodMode,
    dateFrom: Dayjs,
    dateTo: Dayjs,
    handleDates: (pDateFrom: Dayjs, pDateTo: Dayjs) => void;
}

export const DatePeriod: FC<IProps> = (props) => {
    const [showModalSelectDatePeriod, setShowModalSelectDatePeriod] = useState(false)
    const theme = useTheme()

    const handleShowModalSelectDatePeriod = () => {
        setShowModalSelectDatePeriod(true)
    }

    const closeModalSelectDatePeriod = () => {
        setShowModalSelectDatePeriod(false)
    }

    const handleConfirmModalSelectDatePeriod = (pDateFrom: Dayjs, pDateTo: Dayjs) => {
        props.handleDates(pDateFrom, pDateTo)
        closeModalSelectDatePeriod()
    }

    const handlePreviousPeriod = () => {
        if (props.mode === EnumDatePeriodMode.byMonth) {
            props.handleDates(dayjs(props.dateFrom).subtract(1, 'month').startOf('month'), dayjs(props.dateFrom).subtract(1, 'month').endOf('month'))
        }
        if (props.mode === EnumDatePeriodMode.byYear) {
            props.handleDates(dayjs(props.dateFrom).subtract(1, 'year').startOf('year'), dayjs(props.dateFrom).subtract(1, 'year').endOf('year'))
        }
    }

    const handleNextPeriod = () => {
        if (props.mode === EnumDatePeriodMode.byMonth) {
            props.handleDates(dayjs(props.dateTo).add(1, 'month').startOf('month'), dayjs(props.dateTo).add(1, 'month').endOf('month'))
        }
        if (props.mode === EnumDatePeriodMode.byYear) {
            props.handleDates(dayjs(props.dateTo).add(1, 'year').startOf('year'), dayjs(props.dateTo).add(1, 'year').endOf('year'))
        }
    }

    const displayDate = () => {
        if (props.dateFrom.isSame(dayjs().startOf('month'), 'day') && props.dateTo.isSame(dayjs().endOf('month'), 'day')) {
            return <>
                Ce mois-ci
            </>
        }

        if (props.dateFrom.isSame(dayjs().startOf('year'), 'day') && props.dateTo.isSame(dayjs().endOf('year'), 'day')) {
            return <>
                Cette année
            </>
        }

        if (props.dateFrom.isSame(dayjs().subtract(1, 'month').startOf('month'), 'day') && props.dateTo.isSame(dayjs().subtract(1, 'month').endOf('month'), 'day')) {
            return <>
                Le mois dernier
            </>
        }

        if (props.dateFrom.isSame(dayjs().subtract(1, 'year').startOf('year'), 'day') && props.dateTo.isSame(dayjs().subtract(1, 'year').endOf('year'), 'day')) {
            return <>
                L'année dernière
            </>
        }

        if (props.dateFrom.isSame(dayjs().add(1, 'month').startOf('month'), 'day') && props.dateTo.isSame(dayjs().add(1, 'month').endOf('month'), 'day')) {
            return <>
                Le mois prochain
            </>
        }

        if (props.dateFrom.isSame(dayjs().add(1, 'year').startOf('year'), 'day') && props.dateTo.isSame(dayjs().add(1, 'year').endOf('year'), 'day')) {
            return <>
                L'année prochaine
            </>
        }

        if (props.dateFrom.isSame(dayjs().subtract(100, 'year').startOf('year'), 'day') && props.dateTo.isSame(dayjs('2077-12-31').endOf('day'), 'day')) {
            return <>
                Tout l'historique
            </>
        }
        return <>
            {dayjs(props.dateFrom).locale('fr').format(EnumDayjsFormat.date)} - {dayjs(props.dateTo).locale('fr').format(EnumDayjsFormat.date)}
        </>
    }

    return (
        <>
            <Flexbox sx={{
                // bgcolor: "yellow",
            }}>
                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Sélectionner">
                    <IconButton onClick={handleShowModalSelectDatePeriod} >
                        <SettingsIcon sx={{
                            fontSize: 18,
                            color: theme.palette.primary.main,
                        }} />
                    </IconButton>
                </Tooltip>
                <Flexbox sx={{
                    // display: { xs: "none", md: "flex" },
                }}>
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={props.mode === EnumDatePeriodMode.byMonth ? "Mois précédent" : "Année précédente"}>
                        <IconButton
                            onClick={handlePreviousPeriod}
                            color="primary"
                        >
                            <KeyboardArrowLeftIcon sx={{ fontSize: 18 }} />
                        </IconButton>
                    </Tooltip>
                </Flexbox>
                <Typography sx={{
                    color: theme.palette.primary.main,
                    fontSize: { xs: 14, md: 16 },
                }}>
                    {displayDate()}
                </Typography>
                <Flexbox sx={{
                    // display: { xs: "none", md: "flex" },
                }}>
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={props.mode === EnumDatePeriodMode.byMonth ? "Mois suivant" : "Année suivante"}>
                        <IconButton
                            onClick={handleNextPeriod}
                            color="primary"
                        >
                            <KeyboardArrowRightIcon sx={{ fontSize: 18 }} />
                        </IconButton>
                    </Tooltip>
                </Flexbox>
            </Flexbox>
            <ModalSelectDatePeriod
                mode={props.mode}
                dateFrom={props.dateFrom}
                dateTo={props.dateTo}
                open={showModalSelectDatePeriod}
                handleClose={closeModalSelectDatePeriod}
                handleConfirm={handleConfirmModalSelectDatePeriod}
            />
        </>
    )
}


