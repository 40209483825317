import { gql } from "@apollo/client"
import { GQL_FRAGMENT_ACCOUNT_OPERATION, GQL_FRAGMENT_ALBUM_EXTENDED, GQL_FRAGMENT_BANK_ACCOUNT, GQL_FRAGMENT_CONTACT, GQL_FRAGMENT_CUSTOM_CONTACT, GQL_FRAGMENT_DIRECTORY, GQL_FRAGMENT_DOCUMENT, GQL_FRAGMENT_LOCKER_EXTENDED, GQL_FRAGMENT_LOCKER_MINIMAL, GQL_FRAGMENT_NOTE, GQL_FRAGMENT_SHEET, GQL_FRAGMENT_USER } from "./Fragments"

// Login
export const GQL_MUTATION_LOGIN = gql`
${GQL_FRAGMENT_USER}
  mutation login($email: String!, $password: String!) {
    login(input: {credentials: {email: $email, password: $password}}) {
      authToken
      user {
        ...User
        email
        userType
      }
      statusCode
      errors
    }
  }
`

// Lockers
export const GQL_MUTATION_CREATE_LOCKER = gql`
  ${GQL_FRAGMENT_LOCKER_EXTENDED}
  mutation createLocker($title: String!, $description: String!, $vault: Boolean) {
    createLocker(input: {
      title: $title
      description: $description
      vault: $vault
    }) {
      statusCode
      errors
      locker {
        ...LockerExtended
      }
    }
  }
`

export const GQL_MUTATION_UPDATE_LOCKER = gql`
${GQL_FRAGMENT_LOCKER_EXTENDED}
mutation updateLocker($lockerId: ID! $title: String, $description: String) {
  updateLocker(input: {
    lockerId: $lockerId
    title: $title
    description: $description
  }) {
    statusCode
    errors
    locker {
      ...LockerExtended
    }
  }
}
`


export const GQL_MUTATION_PUT_LOCKER_IN_VAULT = gql`
mutation putLockerInVault($lockerId: ID!) {
  putLockerInVault(input: {lockerId: $lockerId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_EXTRACT_LOCKER_FROM_VAULT = gql`
mutation extractLockerFromVault($lockerId: ID!) {
  extractLockerFromVault(input: {lockerId: $lockerId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_DELETE_LOCKER = gql`
mutation deleteLocker($lockerId: ID!) {
  deleteLocker(input: {lockerId: $lockerId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_DELETE_LOCKERS = gql`
mutation deleteLockers($lockerIds: [ID!]!) {
  deleteLockers(input: {lockerIds: $lockerIds}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_EXIT_SHARE = gql`
mutation exitShare($lockerId: ID!) {
  exitShare(input: {lockerId: $lockerId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_SET_LOCKER_PUBLIK = gql`
mutation setLockerPublik($lockerId: ID!) {
  setLockerPublik(input: {lockerId: $lockerId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_UNSET_LOCKER_PUBLIK = gql`
mutation unsetLockerPublik($lockerId: ID!) {
  unsetLockerPublik(input: {lockerId: $lockerId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_SHARE_LOCKER = gql`
${GQL_FRAGMENT_LOCKER_EXTENDED}
mutation shareLocker($lockerId: ID!, $userId: ID!, $shareLevel: EnumShareLevel!) {
  shareLocker(input: {lockerId: $lockerId, userId: $userId, shareLevel: $shareLevel}) {
    statusCode
    errors
    locker {
      ...LockerExtended
    }
  }
}
`

export const GQL_MUTATION_ADD_IN_LOCKERS_SHORTCUTTED = gql`
mutation addInLockersShortcutted($lockerId: ID!) {
  addInLockersShortcutted(input: {lockerId: $lockerId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_REMOVE_FROM_LOCKERS_SHORTCUTTED = gql`
mutation removeFromLockersShortcutted($lockerId: ID!) {
  removeFromLockersShortcutted(input: {lockerId: $lockerId}) {
    statusCode
    errors
  }
}
`



// Directories :
export const GQL_MUTATION_CREATE_DIRECTORY = gql`
  ${GQL_FRAGMENT_DIRECTORY}
  mutation createDirectory($name: String!, $description: String!, $lockerId: ID, $directoryId: ID) {
    createDirectory(input: {
      name: $name
      description: $description
      lockerId: $lockerId
      directoryId: $directoryId
    }) {
      statusCode
      errors
      directory {
        ...Directory
      }
    }
  }
`

export const GQL_MUTATION_UPDATE_DIRECTORY = gql`
${GQL_FRAGMENT_DIRECTORY}
mutation updateDirectory($directoryId: ID! $name: String, $description: String) {
  updateDirectory(input: {
    directoryId: $directoryId
    name: $name
    description: $description
  }) {
    statusCode
    errors
    directory {
      ...Directory
    }
  }
}
`

export const GQL_MUTATION_DELETE_DIRECTORY = gql`
mutation deleteDirectory($directoryId: ID!) {
  deleteDirectory(input: {directoryId: $directoryId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_DELETE_DIRECTORIES = gql`
mutation deleteDirectories($directoryIds: [ID!]!) {
  deleteDirectories(input: {directoryIds: $directoryIds}) {
    statusCode
    errors
    deletedCount
  }
}
`

export const GQL_MUTATION_ADD_IN_DIRECTORIES_SHORTCUTTED = gql`
mutation addInDirectoriesShortcutted($directoryId: ID!) {
  addInDirectoriesShortcutted(input: {directoryId: $directoryId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_REMOVE_FROM_DIRECTORIES_SHORTCUTTED = gql`
mutation removeFromDirectoriesShortcutted($directoryId: ID!) {
  removeFromDirectoriesShortcutted(input: {directoryId: $directoryId}) {
    statusCode
    errors
  }
}
`

// Documents:
export const GQL_MUTATION_CREATE_DOCUMENTS = gql`
mutation createDocuments($directoryId: ID!, $sheetFiles: [String!]!) {
  createDocuments(input: {directoryId: $directoryId, sheetFiles: $sheetFiles}) {
    statusCode
    errors
    createdCount
  }
}
`

export const GQL_MUTATION_QUICK_DROP = gql`
mutation quickDrop($sheetFiles: [String!]!) {
  quickDrop(input: { sheetFiles: $sheetFiles}) {
    statusCode
    errors
    createdCount
  }
}
`

export const GQL_MUTATION_UPDATE_DOCUMENT = gql`
${GQL_FRAGMENT_DOCUMENT}
mutation updateDocument($documentId: ID! $title: String, $description: String) {
  updateDocument(input: {
    documentId: $documentId
    title: $title
    description: $description
  }) {
    statusCode
    errors
    document {
      ...Document
    }
  }
}
`

export const GQL_MUTATION_DELETE_DOCUMENT = gql`
mutation deleteDocument($documentId: ID!) {
  deleteDocument(input: {documentId: $documentId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_DELETE_DOCUMENTS = gql`
mutation deleteDocuments($documentIds: [ID!]!) {
  deleteDocuments(input: {documentIds: $documentIds}) {
    statusCode
    errors
    deletedCount
  }
}
`

export const GQL_MUTATION_SEND_DOCUMENT_BY_MAIL = gql`
mutation sendDocumentByMail(
  $documentId: ID!,
  $receiverEmail: String!,
  $mailSubject: String!,
  $mailContent: String!,
  ) {
  sendDocumentByMail(input: {
    documentId: $documentId
    receiverEmail: $receiverEmail
    mailSubject: $mailSubject
    mailContent: $mailContent
  }) {
    statusCode
    errors
  }
}
`


// Pocket:
export const GQL_MUTATION_ADD_IN_POCKET = gql`
mutation addInPocket(
  $directoryIds: [ID!],
  $documentIds: [ID!],
  $noteIds: [ID!],
  $fotoIds: [ID!],
  $videoIds: [ID!],
  $audioIds: [ID!],
  ) {
  addInPocket(input: {
    directoryIds: $directoryIds,
    documentIds: $documentIds,
    noteIds: $noteIds,
    fotoIds: $fotoIds,
    videoIds: $videoIds,
    audioIds: $audioIds,
    }) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_REMOVE_FROM_POCKET = gql`
mutation removeFromPocket(
  $directoryIds: [ID!],
  $documentIds: [ID!],
  $noteIds: [ID!],
  $fotoIds: [ID!],
  $videoIds: [ID!],
  $audioIds: [ID!],
  ) {
  removeFromPocket(input: {
    directoryIds: $directoryIds,
    documentIds: $documentIds,
    noteIds: $noteIds,
    fotoIds: $fotoIds,
    videoIds: $videoIds,
    audioIds: $audioIds,
    }) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_MOVE_TO_LOCKER = gql`
${GQL_FRAGMENT_LOCKER_EXTENDED}
${GQL_FRAGMENT_DIRECTORY}
mutation moveToLocker($lockerId: ID!, $directoryIds: [ID!]!) {
  moveToLocker(input: {lockerId: $lockerId, directoryIds: $directoryIds}) {
    statusCode
    errors
    locker {
      ...LockerExtended
      directories: directDirectories {
        ...Directory
      }
    }
  }
}
`

export const GQL_MUTATION_MOVE_TO_DIRECTORY = gql`
${GQL_FRAGMENT_DIRECTORY}
${GQL_FRAGMENT_DOCUMENT}
${GQL_FRAGMENT_LOCKER_MINIMAL}
mutation moveToDirectory($directoryId: ID!, $directoryIds: [ID!], $documentIds: [ID!], $noteIds: [ID!]) {
  moveToDirectory(input: {directoryId: $directoryId, directoryIds: $directoryIds, documentIds: $documentIds, noteIds: $noteIds}) {
    statusCode
    errors
    directory {
      ...Directory
      children {
        ...Directory
      }
      ancestors {
        ...Directory
      }
      locker {
        ...LockerMinimal
        mode
      }
      documents {
        ...Document
      }
    }
  }
}
`

export const GQL_MUTATION_COPY_TO_LOCKER = gql`
${GQL_FRAGMENT_LOCKER_EXTENDED}
${GQL_FRAGMENT_DIRECTORY}
mutation copyToLocker($lockerId: ID!, $directoryIds: [ID!]!) {
  copyToLocker(input: {lockerId: $lockerId, directoryIds: $directoryIds}) {
    statusCode
    errors
    locker {
      ...LockerExtended
      directories: directDirectories {
        ...Directory
      }
    }
  }
}
`

export const GQL_MUTATION_COPY_TO_DIRECTORY = gql`
${GQL_FRAGMENT_DIRECTORY}
${GQL_FRAGMENT_DOCUMENT}
${GQL_FRAGMENT_LOCKER_MINIMAL}
mutation copyToDirectory($directoryId: ID!, $directoryIds: [ID!], $documentIds: [ID!], $noteIds: [ID!]) {
  copyToDirectory(input: {directoryId: $directoryId, directoryIds: $directoryIds, documentIds: $documentIds, noteIds: $noteIds}) {
    statusCode
    errors
    directory {
      ...Directory
      children {
        ...Directory
      }
      ancestors {
        ...Directory
      }
      locker {
        ...LockerMinimal
        mode
      }
      documents {
        ...Document
      }
    }
  }
}
`

export const GQL_MUTATION_MOVE_TO_ALBUM = gql`
mutation moveToAlbum(
  $albumId: ID!,
  $fotoIds: [ID!],
  $videoIds: [ID!],
  $audioIds: [ID!],
  ) {
  moveToAlbum(input: {
    albumId: $albumId,
    fotoIds: $fotoIds,
    videoIds: $videoIds,
    audioIds: $audioIds,
    }) {
    statusCode
    errors
  }
}
`


export const GQL_MUTATION_COPY_TO_ALBUM = gql`
mutation copyToAlbum(
  $albumId: ID!,
  $fotoIds: [ID!],
  $videoIds: [ID!],
  $audioIds: [ID!],
  ) {
  copyToAlbum(input: {
    albumId: $albumId,
    fotoIds: $fotoIds,
    videoIds: $videoIds,
    audioIds: $audioIds,
    }) {
    statusCode
    errors
  }
}
`

// Sheets:
export const GQL_MUTATION_ADD_SHEET = gql`
${GQL_FRAGMENT_SHEET}
mutation addSheet($documentId: ID!, $sheetFile: String!) {
  addSheet(input: {documentId: $documentId, sheetFile: $sheetFile}) {
    statusCode
    errors
    sheet {
      ...Sheet
    }
  }
}
`

export const GQL_MUTATION_SET_SHEET_APPLICABLE = gql`
mutation setSheetApplicable($sheetId: ID!) {
  setSheetApplicable(input: {sheetId: $sheetId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_DELETE_SHEET = gql`
mutation deleteSheet($sheetId: ID!) {
  deleteSheet(input: {sheetId: $sheetId}) {
    statusCode
    errors
  }
}
`

// Partnerships:
export const GQL_MUTATION_CREATE_PARTNERSHIP = gql`
mutation createPartnership($requesterId: ID!, $responderId: ID!) {
  createPartnership(input: {requesterId: $requesterId, responderId: $responderId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_DELETE_PARTNERSHIP = gql`
mutation deletePartnership($userOneId: ID!, $userTwoId: ID!) {
  deletePartnership(input: {userOneId: $userOneId, userTwoId: $userTwoId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_ACCEPT_PARTNERSHIP = gql`
mutation acceptPartnership($userOneId: ID!, $userTwoId: ID!) {
  acceptPartnership(input: {userOneId: $userOneId, userTwoId: $userTwoId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_UPDATE_AVATAR = gql`
${GQL_FRAGMENT_USER}
mutation updateAvatar($userId: ID!, $sheetFile: String!) {
  updateAvatar(input: {userId: $userId, sheetFile: $sheetFile}) {
    statusCode
    errors
    user {
      ...User
    }
  }
}
`

export const GQL_MUTATION_UPDATE_PASSWORD = gql`
mutation updatePassword($userId: ID!, $currentPassword: String!, $newPassword: String!) {
  updatePassword(input: {userId: $userId, currentPassword: $currentPassword, newPassword: $newPassword}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_SEND_FORGOTTEN_PASSWORD_MAIL = gql`
  mutation sendForgottenPasswordMail($email: String!) {
    sendForgottenPasswordMail(input: {email: $email}) {
      statusCode
      errors
    }
  }
`

export const GQL_MUTATION_RESET_PASSWORD = gql`
mutation resetPassword($email: String!, $passwordResetToken: String!, $newPassword: String!) {
  resetPassword(input: {email: $email, passwordResetToken: $passwordResetToken, newPassword: $newPassword}) {
    statusCode
    errors
  }
}
`


export const GQL_MUTATION_RECALCULATE_DISK_USAGE = gql`
mutation recalculateDiskUsage($userIds: [ID!]!) {
  recalculateDiskUsage(input: {userIds: $userIds}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_CHANGE_PACK = gql`
mutation changePack($userId: ID!, $packLabel: EnumPackLabel!) {
  changePack(input: {userId: $userId, packLabel: $packLabel}) {
    statusCode
    errors
  }
}
`


export const GQL_MUTATION_CREATE_USER = gql`
mutation createUser($email: String!, $firstname: String!, $lastname: String!) {
  createUser(input: {email: $email, firstname: $firstname, lastname: $lastname}) {
    statusCode
    errors
  }
}
`


export const GQL_MUTATION_CHOOSE_PASSWORD = gql`
mutation choosePassword($email: String!, $choosePasswordToken: String!, $newPassword: String!) {
  choosePassword(input: {email: $email, choosePasswordToken: $choosePasswordToken, newPassword: $newPassword}) {
    statusCode
    errors
  }
}
`




// Notes
export const GQL_MUTATION_DELETE_NOTE = gql`
mutation deleteNote($noteId: ID!) {
  deleteNote(input: {noteId: $noteId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_DELETE_NOTES = gql`
mutation deleteNotes($noteIds: [ID!]!) {
  deleteNotes(input: {noteIds: $noteIds}) {
    statusCode
    errors
    deletedCount
  }
}
`

export const GQL_MUTATION_UPDATE_NOTE = gql`
${GQL_FRAGMENT_NOTE}
mutation updateNote($noteId: ID!, $title: String, $content: String) {
  updateNote(input: {
    noteId: $noteId
    title: $title
    content: $content
  }) {
    statusCode
    errors
    note {
      ...Note
    }
  }
}
`

export const GQL_MUTATION_CREATE_NOTE = gql`
  mutation createNote($title: String!, $content: String!, $directoryId: ID!) {
    createNote(input: {
      title: $title
      content: $content
      directoryId: $directoryId
    }) {
      statusCode
      errors
    }
  }
`

export const GQL_MUTATION_CREATE_QUICK_NOTE = gql`
mutation createQuickNote($content: String!) {
    createQuickNote(input: {
      content: $content
    }) {
      statusCode
      errors
    }
  }
`

// Zips
export const GQL_MUTATION_CREATE_ZIP = gql`
mutation createZip(
  $title: String!,
  $description: String,
  $directoryId: ID!,
  $directoryIds: [ID!],
  $documentIds: [ID!],
  $noteIds: [ID!],
  ) {
  createZip(input: {
    title: $title,
    description: $description,
    directoryId: $directoryId,
    directoryIds: $directoryIds,
    documentIds: $documentIds,
    noteIds: $noteIds,
  }) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_CREATE_ALBUM_ZIP = gql`
mutation createAlbumZip(
  $title: String!,
  $description: String,
  $albumId: ID!,
  $fotoIds: [ID!],
  $videoIds: [ID!],
  $audioIds: [ID!],
  ) {
  createAlbumZip(input: {
    title: $title,
    description: $description,
    albumId: $albumId,
    fotoIds: $fotoIds,
    videoIds: $videoIds,
    audioIds: $audioIds,
  }) {
    statusCode
    errors
  }
}
`

// Passwords :
export const GQL_MUTATION_CREATE_PASSWORD = gql`
  mutation createPassword($title: String!, $url: String, $description: String, $login: String, $encryptedPassword: String) {
    createPassword(input: {
      title: $title
      url: $url
      description: $description
      login: $login
      encryptedPassword: $encryptedPassword
    }) {
      statusCode
      errors
    }
  }
`

export const GQL_MUTATION_DELETE_PASSWORD = gql`
mutation deletePassword($passwordId: ID!) {
  deletePassword(input: {passwordId: $passwordId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_UPDATE_PASSWORD_OBJECT = gql`
mutation updatePasswordObject($passwordId: ID!, $title: String, $url: String, $description: String, $login: String, $encryptedPassword: String) {
  updatePasswordObject(input: {
    passwordId: $passwordId
    title: $title
    url: $url
    description: $description
    login: $login
    encryptedPassword: $encryptedPassword
  }) {
    statusCode
    errors
  }
}
`

// Albums
export const GQL_MUTATION_CREATE_ALBUM = gql`
  ${GQL_FRAGMENT_ALBUM_EXTENDED}
  mutation createAlbum(
    $title: String!,
    $description: String!,
    $dateAlbum: String!,
    ) {
    createAlbum(input: {
      title: $title,
      description: $description,
      dateAlbum: $dateAlbum,
    }) {
      statusCode
      errors
      album {
        ...AlbumExtended
      }
    }
  }
`

export const GQL_MUTATION_UPDATE_ALBUM = gql`
${GQL_FRAGMENT_ALBUM_EXTENDED}
mutation updateAlbum(
  $albumId: ID!,
  $title: String,
  $description: String,
  $dateAlbum: String,
  ) {
  updateAlbum(input: {
    albumId: $albumId,
    title: $title,
    description: $description,
    dateAlbum: $dateAlbum,
  }) {
    statusCode
    errors
    album {
      ...AlbumExtended
    }
  }
}
`

export const GQL_MUTATION_DELETE_ALBUM = gql`
mutation deleteAlbum($albumId: ID!) {
  deleteAlbum(input: {albumId: $albumId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_DELETE_ALBUMS = gql`
mutation deleteAlbums($albumIds: [ID!]!) {
  deleteAlbums(input: {albumIds: $albumIds}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_EXIT_ALBUM_SHARE = gql`
mutation exitAlbumShare($albumId: ID!) {
  exitAlbumShare(input: {albumId: $albumId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_SET_ALBUM_PUBLIK = gql`
mutation setAlbumPublik($albumId: ID!) {
  setAlbumPublik(input: {albumId: $albumId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_UNSET_ALBUM_PUBLIK = gql`
mutation unsetAlbumPublik($albumId: ID!) {
  unsetAlbumPublik(input: {albumId: $albumId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_SHARE_ALBUM = gql`
${GQL_FRAGMENT_ALBUM_EXTENDED}
mutation shareAlbum($albumId: ID!, $userId: ID!, $shareLevel: EnumShareLevel!) {
  shareAlbum(input: {albumId: $albumId, userId: $userId, shareLevel: $shareLevel}) {
    statusCode
    errors
    album {
      ...AlbumExtended
    }
  }
}
`

// Fotos : 
export const GQL_MUTATION_CREATE_FOTOS = gql`
mutation createFotos($albumId: ID!, $fotoFiles: [String!]!) {
  createFotos(input: {albumId: $albumId, fotoFiles: $fotoFiles}) {
    statusCode
    errors
    createdCount
  }
}
`

export const GQL_MUTATION_DELETE_FOTO = gql`
mutation deleteFoto($fotoId: ID!) {
  deleteFoto(input: {fotoId: $fotoId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_DELETE_FOTOS = gql`
mutation deleteFotos($fotoIds: [ID!]!) {
  deleteFotos(input: {fotoIds: $fotoIds}) {
    statusCode
    errors
    deletedCount
  }
}
`

export const GQL_MUTATION_ROTATE_FOTO = gql`
mutation rotateFoto($fotoId: ID!) {
  rotateFoto(input: {fotoId: $fotoId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_DELETE_VIDEO = gql`
mutation deleteVideo($videoId: ID!) {
  deleteVideo(input: {videoId: $videoId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_DELETE_VIDEOS = gql`
mutation deleteVideos($videoIds: [ID!]!) {
  deleteVideos(input: {videoIds: $videoIds}) {
    statusCode
    errors
    deletedCount
  }
}
`

export const GQL_MUTATION_DELETE_AUDIO = gql`
mutation deleteAudio($audioId: ID!) {
  deleteAudio(input: {audioId: $audioId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_DELETE_AUDIOS = gql`
mutation deleteAudios($audioIds: [ID!]!) {
  deleteAudios(input: {audioIds: $audioIds}) {
    statusCode
    errors
    deletedCount
  }
}
`

// Contacts :
export const GQL_MUTATION_CREATE_CONTACT = gql`
  mutation createContact(
    $nickname: String,
    $firstname: String,
    $lastname: String,
    $enterpriseName: String,
    $contactType: EnumContact,
    $phone: String,
    $email: String,
    $birthAt: String,
    $adresse: String,
    $description: String,
    ) {
    createContact(input: {
      nickname: $nickname,
      firstname: $firstname,
      lastname: $lastname,
      enterpriseName: $enterpriseName,
      contactType: $contactType,
      phone: $phone,
      email: $email,
      birthAt: $birthAt,
      adresse: $adresse,
      description: $description,
    }) {
      statusCode
      errors
    }
  }
`

export const GQL_MUTATION_DELETE_CONTACT = gql`
mutation deleteContact($contactId: ID!) {
  deleteContact(input: {contactId: $contactId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_DELETE_CONTACTS = gql`
mutation deleteContacts($contactIds: [ID!]!) {
  deleteContacts(input: {contactIds: $contactIds}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_UPDATE_CONTACT = gql`
mutation updateContact(
  $contactId: ID!,
  $nickname: String,
  $firstname: String,
  $lastname: String,
  $enterpriseName: String,
  $contactType: EnumContact,
  $phone: String,
  $email: String,
  $birthAt: String,
  $adresse: String,
  $description: String,
  ) {
  updateContact(input: {
    contactId: $contactId
    nickname: $nickname,
    firstname: $firstname,
    lastname: $lastname,
    enterpriseName: $enterpriseName,
    contactType: $contactType,
    phone: $phone,
    email: $email,
    birthAt: $birthAt,
    adresse: $adresse,
    description: $description,
  }) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_UPDATE_CONTACT_AVATAR = gql`
${GQL_FRAGMENT_CONTACT}
mutation updateContactAvatar($contactId: ID!, $sheetFile: String!) {
  updateContactAvatar(input: {contactId: $contactId, sheetFile: $sheetFile}) {
    statusCode
    errors
    contact {
      ...Contact
    }
  }
}
`

export const GQL_MUTATION_IMPORT_CONTACT_VCARDS = gql`
mutation importContactVcards($sheetFile: String!) {
  importContactVcards(input: {sheetFile: $sheetFile}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_EXPORT_CONTACT_VCARDS = gql`
mutation exportContactVcards($contactIds: [ID!]!, $fileName: String!) {
  exportContactVcards(input: {contactIds: $contactIds, fileName: $fileName}) {
    sheetFileUrl
    statusCode
    errors
  }
}
`

// Contacts relationships:
export const GQL_MUTATION_UPDATE_CONTACT_RELATIONSHIPS = gql`
mutation updateContactRelationships($mainContactId: ID!, $otherContactIds: [ID!]!) {
  updateContactRelationships(input: {mainContactId: $mainContactId, otherContactIds: $otherContactIds}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_UPDATE_CUSTOM_CONTACT_RELATIONSHIPS = gql`
mutation updateCustomContactRelationships($contactId: ID!, $customContactIds: [ID!]!) {
  updateCustomContactRelationships(input: {contactId: $contactId, customContactIds: $customContactIds}) {
    statusCode
    errors
  }
}
`

// CustomContacts :
export const GQL_MUTATION_CREATE_CUSTOM_CONTACT = gql`
${GQL_FRAGMENT_CUSTOM_CONTACT}
  mutation createCustomContact(
    $title: String!,
    ) {
    createCustomContact(input: {
      title: $title,
    }) {
      statusCode
      errors
      customContact {
        ...CustomContact
      }
    }
  }
`

export const GQL_MUTATION_UPDATE_CUSTOM_CONTACT = gql`
${GQL_FRAGMENT_CUSTOM_CONTACT}
mutation updateCustomContact(
  $customContactId: ID!,
  $title: String!,
  ) {
  updateCustomContact(input: {
    customContactId: $customContactId
    title: $title,
  }) {
    statusCode
    errors
    customContact {
        ...CustomContact
      }
  }
}
`

export const GQL_MUTATION_DELETE_CUSTOM_CONTACT = gql`
mutation deleteCustomContact($customContactId: ID!) {
  deleteCustomContact(input: {customContactId: $customContactId}) {
    statusCode
    errors
  }
}
`

// TodoList
export const GQL_MUTATION_CREATE_TODOLIST = gql`
  mutation createTodolist($title: String!) {
    createTodolist(input: {
      title: $title
    }) {
      statusCode
      errors
    }
  }
`

export const GQL_MUTATION_UPDATE_TODOLIST_DONE = gql`
mutation updateTodolistDone($todolistId: ID!, $done: Boolean!) {
  updateTodolistDone(input: {todolistId: $todolistId, done: $done}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_UPDATE_TODOLIST = gql`
mutation updateTodolist($todolistId: ID!, $title: String!) {
  updateTodolist(input: {todolistId: $todolistId, title: $title}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_DELETE_TODOLIST = gql`
mutation deleteTodolist($todolistId: ID!) {
  deleteTodolist(input: {todolistId: $todolistId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_CREATE_TODOSTEP = gql`
  mutation createTodostep($todolistId: ID!, $title: String!) {
    createTodostep(input: {
      todolistId: $todolistId
      title: $title
    }) {
      statusCode
      errors
    }
  }
`

export const GQL_MUTATION_UPDATE_TODOSTEP_DONE = gql`
mutation updateTodostepDone($todostepId: ID!, $done: Boolean!) {
  updateTodostepDone(input: {todostepId: $todostepId, done: $done}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_UPDATE_TODOSTEP = gql`
mutation updateTodostep($todostepId: ID!, $title: String!) {
  updateTodostep(input: {todostepId: $todostepId, title: $title}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_DELETE_TODOSTEP = gql`
mutation deleteTodostep($todostepId: ID!) {
  deleteTodostep(input: {todostepId: $todostepId}) {
    statusCode
    errors
  }
}
`

// Cashflow
export const GQL_MUTATION_CREATE_BANK_ACCOUNT = gql`
  ${GQL_FRAGMENT_BANK_ACCOUNT}
  mutation createBankAccount(
    $title: String!,
    $cbColor: EnumCbColor!,
    $description: String!,
    $bankName: String,
    $bankAccountType: EnumBankAccount!,
    $taxRate: Float,
    ){
    createBankAccount(input: {
      title: $title,
      cbColor: $cbColor,
      description: $description,
      bankName: $bankName,
      bankAccountType: $bankAccountType,
      taxRate: $taxRate,
    }){
      statusCode
      errors
      bankAccount {
        ...BankAccount
      }
    }
  } 
`


export const GQL_MUTATION_DELETE_BANK_ACCOUNT = gql`
mutation deleteBankAccount($bankAccountId: ID!) {
  deleteBankAccount(input: {bankAccountId: $bankAccountId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_DELETE_BANK_ACCOUNTS = gql`
mutation deleteBankAccounts($bankAccountIds: [ID!]!) {
  deleteBankAccounts(input: {bankAccountIds: $bankAccountIds}) {
    statusCode
    errors
  }
}
`


export const GQL_MUTATION_UPDATE_BANK_ACCOUNT = gql`
${GQL_FRAGMENT_BANK_ACCOUNT}
mutation updateBankAccount(
  $bankAccountId: ID!,
  $title: String,
  $cbColor: EnumCbColor,
  $description: String,
  $bankName: String,
  $bankAccountType: EnumBankAccount,
  $taxRate: Float,
  ){
  updateBankAccount(input: {
    bankAccountId: $bankAccountId
    title: $title,
    cbColor: $cbColor,
    description: $description,
    bankName: $bankName,
    bankAccountType: $bankAccountType,
    taxRate: $taxRate,
  }) {
    statusCode
    errors
    bankAccount {
      ...BankAccount
    }
  }
}
`

export const GQL_MUTATION_ADD_IN_BANK_ACCOUNTS_SHORTCUTTED = gql`
mutation addInBankAccountsShortcutted($bankAccountId: ID!) {
  addInBankAccountsShortcutted(input: {bankAccountId: $bankAccountId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_REMOVE_FROM_BANK_ACCOUNTS_SHORTCUTTED = gql`
mutation removeFromBankAccountsShortcutted($bankAccountId: ID!) {
  removeFromBankAccountsShortcutted(input: {bankAccountId: $bankAccountId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_CREATE_QUICK_ACCOUNT_OPERATION = gql`
mutation createQuickAccountOperation(
    $bankAccountId: ID!,
    $amount: Float!,
    $title: String,
    ) {
    createQuickAccountOperation(input: {
      bankAccountId: $bankAccountId,
      title: $title,
      amount: $amount,
    }) {
      statusCode
      errors
    }
  }
`

export const GQL_MUTATION_SYNC_BANK_ACCOUNT_WITH_BANK = gql`
mutation syncBankAccountWithBank(
    $bankAccountId: ID!,
    $bankBalance: Float!,
    ) {
    syncBankAccountWithBank(input: {
      bankAccountId: $bankAccountId,
      bankBalance: $bankBalance,
    }) {
      statusCode
      errors
    }
  }
`

export const GQL_MUTATION_SET_BANK_ACCOUNT_SOFT_LIMIT = gql`
mutation setBankAccountSoftLimit(
    $bankAccountId: ID!,
    $softLimit: Float!,
    ) {
    setBankAccountSoftLimit(input: {
      bankAccountId: $bankAccountId,
      softLimit: $softLimit,
    }) {
      statusCode
      errors
    }
  }
`

export const GQL_MUTATION_CREATE_ACCOUNT_OPERATION = gql`
  mutation createAccountOperation(
    $bankAccountId: ID!,
    $title: String!,
    $accountOperationType: EnumAccountOperation!,
    $dateOperation: String!,
    $amount: Float!,
    $description: String,
    $customContactId: ID,
    $reference: String,
    $imputable: Boolean!,
    $consolidateImmediately: Boolean!,
    ) {
    createAccountOperation(input: {
      bankAccountId: $bankAccountId,
      title: $title,
      accountOperationType: $accountOperationType,
      dateOperation: $dateOperation,
      amount: $amount,
      description: $description,
      customContactId: $customContactId,
      reference: $reference,
      imputable: $imputable,
      consolidateImmediately: $consolidateImmediately,
    }) {
      statusCode
      errors
    }
  }
`

export const GQL_MUTATION_UPDATE_ACCOUNT_OPERATION = gql`
${GQL_FRAGMENT_ACCOUNT_OPERATION}
mutation updateAccountOperation(
  $accountOperationId: ID!,
  $title: String!,
  $accountOperationType: EnumAccountOperation!,
  $dateOperation: String!,
  $amount: Float!,
  $description: String,
  $customContactId: ID,
  $reference: String,
  $imputable: Boolean,
  ){
  updateAccountOperation(input: {
    accountOperationId: $accountOperationId
    title: $title,
    accountOperationType: $accountOperationType,
    dateOperation: $dateOperation,
    amount: $amount,
    description: $description,
    customContactId: $customContactId,
    reference: $reference,
    imputable: $imputable,
  }) {
    statusCode
    errors
    accountOperation {
      ...AccountOperation
    }
  }
}
`


export const GQL_MUTATION_SET_ACCOUNT_OPERATION_STATE = gql`
  mutation setAccountOperationState(
    $accountOperationId: ID!,
    $state: EnumAccountOperationState!,
    $paidAt: String,
    ) {
    setAccountOperationState(input: {
      accountOperationId: $accountOperationId,
      state: $state,
      paidAt: $paidAt,
    }) {
      statusCode
      errors
    }
  }
`

export const GQL_MUTATION_DELETE_ACCOUNT_OPERATION = gql`
mutation deleteAccountOperation($accountOperationId: ID!) {
  deleteAccountOperation(input: {accountOperationId: $accountOperationId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_SET_ACCOUNT_OPERATION_DUPLICATION = gql`
  mutation setAccountOperationDuplication(
    $accountOperationId: ID!,
    $toDuplicate: Boolean!,
    ) {
    setAccountOperationDuplication(input: {
      accountOperationId: $accountOperationId,
      toDuplicate: $toDuplicate,
    }) {
      statusCode
      errors
    }
  }
`

// Reminders :
export const GQL_MUTATION_CREATE_REMINDER = gql`
  mutation createReminder(
    $contactId: ID!,
    $deliverAt: String!,
    $content: String!,
    ) {
    createReminder(input: {
      contactId: $contactId
      deliverAt: $deliverAt
      content: $content
    }) {
      statusCode
      errors
    }
  }
`

export const GQL_MUTATION_DELETE_REMINDER = gql`
mutation deleteReminder($reminderId: ID!) {
  deleteReminder(input: {reminderId: $reminderId}) {
    statusCode
    errors
  }
}
`

export const GQL_MUTATION_UPDATE_REMINDER = gql`
mutation updateReminder(
  $reminderId: ID!,
  $contactId: ID,
  $deliverAt: String,
  $content: String,
  ) {
  updateReminder(input: {
    reminderId: $reminderId
    contactId: $contactId
    deliverAt: $deliverAt
    content: $content
  }) {
    statusCode
    errors
  }
}
`
