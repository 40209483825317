import { createSlice } from "@reduxjs/toolkit";
import { IAudio, IDirectory, IDocument, IFoto, ILocker, INote, IVideo } from "../../../interfaces";
import { sortByName } from "../../../utils/Utils";
import { useSelector } from "react-redux";
import { IRootState, store } from "../../store";
import { apolloClient } from "../../../ApolloClient";
import { GQL_POCKET } from "../../../graphql/Queries";
import { EnumPocketTab } from "../../../enums";
import { ApolloQueryResult } from "@apollo/client";


interface IPocketSliceState {
    expanded: boolean,
    directories: undefined | IDirectory[],
    documents: undefined | IDocument[],
    notes: undefined | INote[],
    fotos: undefined | IFoto[],
    videos: undefined | IVideo[],
    audios: undefined | IAudio[],
    refetchNeeded: boolean,
    tab: EnumPocketTab,
}

const initialState: IPocketSliceState = {
    expanded: false,
    directories: undefined,
    documents: undefined,
    notes: undefined,
    fotos: undefined,
    videos: undefined,
    audios: undefined,
    refetchNeeded: false,
    tab: EnumPocketTab.none,
}

const getFilteredDirectories = (state: IPocketSliceState, directories: IDirectory[]) => {
    if (state.directories === undefined) {
        return directories
    }
    const res = directories.filter(directory => !state.directories?.map(stateDirectory => stateDirectory.id).includes(directory.id))
    return res
}

const getFilteredDocuments = (state: IPocketSliceState, documents: IDocument[]) => {
    if (state.documents === undefined) {
        return documents
    }
    const res = documents.filter(document => !state.documents?.map(stateDocument => stateDocument.id).includes(document.id))
    return res
}

const getFilteredNotes = (state: IPocketSliceState, notes: INote[]) => {
    if (state.notes === undefined) {
        return notes
    }
    const res = notes.filter(note => !state.notes?.map(stateNote => stateNote.id).includes(note.id))
    return res
}

const getFilteredFotos = (state: IPocketSliceState, fotos: IFoto[]) => {
    if (state.fotos === undefined) {
        return fotos
    }
    const res = fotos.filter(foto => !state.fotos?.map(stateFoto => stateFoto.id).includes(foto.id))
    return res
}

const getFilteredVideos = (state: IPocketSliceState, videos: IVideo[]) => {
    if (state.videos === undefined) {
        return videos
    }
    const res = videos.filter(video => !state.videos?.map(stateVideo => stateVideo.id).includes(video.id))
    return res
}

const getFilteredAudios = (state: IPocketSliceState, audios: IAudio[]) => {
    if (state.audios === undefined) {
        return audios
    }
    const res = audios.filter(audio => !state.audios?.map(stateAudio => stateAudio.id).includes(audio.id))
    return res
}

export const pocketSlice = createSlice({
    name: "pocketSlice",
    initialState,
    reducers: {
        setExpandedAction: (state, action) => {
            state.tab = action.payload.tab
            state.expanded = action.payload.expanded
        },
        setDirectoriesAction: (state, action) => {
            state.directories = action.payload
        },
        setDocumentsAction: (state, action) => {
            state.documents = action.payload
        },
        setNotesAction: (state, action) => {
            state.notes = action.payload
        },
        setFotosAction: (state, action) => {
            state.fotos = action.payload
        },
        setVideosAction: (state, action) => {
            state.videos = action.payload
        },
        setAudiosAction: (state, action) => {
            state.audios = action.payload
        },
        addDirectoryAction: (state, action) => {
            const newDirectory: IDirectory = action.payload
            if (state.directories === undefined) {
                state.directories = [newDirectory]
            } else {
                state.directories = [...state.directories, newDirectory]
            }
        },
        addDocumentAction: (state, action) => {
            const newDocument: IDocument = action.payload
            if (state.documents === undefined) {
                state.documents = [newDocument]
            } else {
                state.documents = [...state.documents, newDocument]
            }
        },
        addDirectoriesAction: (state, action) => {
            const newDirectories: IDirectory[] = action.payload
            if (state.directories === undefined) {
                state.directories = newDirectories
            } else {
                const filteredDirectories = getFilteredDirectories(state, newDirectories)
                state.directories = [...state.directories, ...filteredDirectories]
            }
        },
        addDocumentsAction: (state, action) => {
            const newDocuments: IDocument[] = action.payload
            if (state.documents === undefined) {
                state.documents = newDocuments
            } else {
                const filteredDocuments = getFilteredDocuments(state, newDocuments)
                state.documents = [...state.documents, ...filteredDocuments]
            }
        },
        removeDirectoryAction: (state, action) => {
            const directoryId = action.payload
            state.directories = state.directories?.filter(directory => directory.id !== directoryId)
        },
        removeDocumentAction: (state, action) => {
            const documentId = action.payload
            state.documents = state.documents?.filter(document => document.id !== documentId)
        },
        removeDirectoriesAction: (state, action) => {
            const directoryIds: string[] = action.payload
            if (state.directories !== undefined) {
                state.directories = state.directories.filter(directory => !directoryIds.includes(directory.id))
            }
        },
        removeDocumentsAction: (state, action) => {
            const documentIds: string[] = action.payload
            if (state.documents !== undefined) {
                state.documents = state.documents.filter(document => !documentIds.includes(document.id))
            }
        },
        setPocketRefetchNeeded: (state, action) => {
            state.refetchNeeded = action.payload
        },
        addNotesAction: (state, action) => {
            const newNotes: INote[] = action.payload
            if (state.notes === undefined) {
                state.notes = newNotes
            } else {
                const filteredNotes = getFilteredNotes(state, newNotes)
                state.notes = [...state.notes, ...filteredNotes]
            }
        },
        removeNotesAction: (state, action) => {
            const noteIds: string[] = action.payload
            if (state.notes !== undefined) {
                state.notes = state.notes.filter(note => !noteIds.includes(note.id))
            }
        },
        addFotosAction: (state, action) => {
            const newFotos: IFoto[] = action.payload
            if (state.fotos === undefined) {
                state.fotos = newFotos
            } else {
                const filteredFotos = getFilteredFotos(state, newFotos)
                state.fotos = [...state.fotos, ...filteredFotos]
            }
        },
        removeFotosAction: (state, action) => {
            const fotoIds: string[] = action.payload
            if (state.fotos !== undefined) {
                state.fotos = state.fotos.filter(foto => !fotoIds.includes(foto.id))
            }
        },
        addVideosAction: (state, action) => {
            const newVideos: IVideo[] = action.payload
            if (state.videos === undefined) {
                state.videos = newVideos
            } else {
                const filteredVideos = getFilteredVideos(state, newVideos)
                state.videos = [...state.videos, ...filteredVideos]
            }
        },
        removeVideosAction: (state, action) => {
            const videoIds: string[] = action.payload
            if (state.videos !== undefined) {
                state.videos = state.videos.filter(video => !videoIds.includes(video.id))
            }
        },
        addAudiosAction: (state, action) => {
            const newAudios: IAudio[] = action.payload
            if (state.audios === undefined) {
                state.audios = newAudios
            } else {
                const filteredAudios = getFilteredAudios(state, newAudios)
                state.audios = [...state.audios, ...filteredAudios]
            }
        },
        removeAudiosAction: (state, action) => {
            const audioIds: string[] = action.payload
            if (state.audios !== undefined) {
                state.audios = state.audios.filter(audio => !audioIds.includes(audio.id))
            }
        },
    }
})

export const {
    setExpandedAction, setPocketRefetchNeeded,
    setDirectoriesAction, addDirectoryAction, addDirectoriesAction, removeDirectoryAction, removeDirectoriesAction,
    setDocumentsAction, addDocumentAction, addDocumentsAction, removeDocumentAction, removeDocumentsAction,
    setNotesAction, addNotesAction, removeNotesAction,
    setFotosAction, addFotosAction, removeFotosAction,
    setVideosAction, addVideosAction, removeVideosAction,
    setAudiosAction, addAudiosAction, removeAudiosAction,
} = pocketSlice.actions


export const useGetDirectories = () => {
    const directories: (undefined | IDirectory[]) = useSelector((state: IRootState) => state.pocketReducer.directories)
    const refetchNeeded: boolean = useSelector((state: IRootState) => state.pocketReducer.refetchNeeded)
    if (directories === undefined || refetchNeeded) {
        apolloClient.query({
            query: GQL_POCKET,
            fetchPolicy: 'network-only',
        }
        ).then(response => {
            setDataResponse(response)
            return response.data.pocket.directories
        })
    } else {
        return directories
    }
}

export const useGetDocuments = () => {
    const documents: (undefined | IDocument[]) = useSelector((state: IRootState) => state.pocketReducer.documents)
    const refetchNeeded: boolean = useSelector((state: IRootState) => state.pocketReducer.refetchNeeded)
    if (documents === undefined || refetchNeeded) {
        apolloClient.query({
            query: GQL_POCKET,
            fetchPolicy: 'network-only',
        }
        ).then(response => {
            setDataResponse(response)
            return response.data.pocket.documents
        })
    } else {
        return documents
    }
}

export const useGetNotes = () => {
    const notes: (undefined | INote[]) = useSelector((state: IRootState) => state.pocketReducer.notes)
    const refetchNeeded: boolean = useSelector((state: IRootState) => state.pocketReducer.refetchNeeded)
    if (notes === undefined || refetchNeeded) {
        apolloClient.query({
            query: GQL_POCKET,
            fetchPolicy: 'network-only',
        }
        ).then(response => {
            setDataResponse(response)
            return response.data.pocket.notes
        })
    } else {
        return notes
    }
}

export const useGetFotos = () => {
    const fotos: (undefined | IFoto[]) = useSelector((state: IRootState) => state.pocketReducer.fotos)
    const refetchNeeded: boolean = useSelector((state: IRootState) => state.pocketReducer.refetchNeeded)
    if (fotos === undefined || refetchNeeded) {
        apolloClient.query({
            query: GQL_POCKET,
            fetchPolicy: 'network-only',
        }
        ).then(response => {
            setDataResponse(response)
            return response.data.pocket.fotos
        })
    } else {
        return fotos
    }
}

export const useGetVideos = () => {
    const videos: (undefined | IVideo[]) = useSelector((state: IRootState) => state.pocketReducer.videos)
    const refetchNeeded: boolean = useSelector((state: IRootState) => state.pocketReducer.refetchNeeded)
    if (videos === undefined || refetchNeeded) {
        apolloClient.query({
            query: GQL_POCKET,
            fetchPolicy: 'network-only',
        }
        ).then(response => {
            setDataResponse(response)
            return response.data.pocket.videos
        })
    } else {
        return videos
    }
}

export const useGetAudios = () => {
    const audios: (undefined | IAudio[]) = useSelector((state: IRootState) => state.pocketReducer.audios)
    const refetchNeeded: boolean = useSelector((state: IRootState) => state.pocketReducer.refetchNeeded)
    if (audios === undefined || refetchNeeded) {
        apolloClient.query({
            query: GQL_POCKET,
            fetchPolicy: 'network-only',
        }
        ).then(response => {
            setDataResponse(response)
            return response.data.pocket.audios
        })
    } else {
        return audios
    }
}

const setDataResponse = (response: ApolloQueryResult<any>) => {
    store.dispatch(setDirectoriesAction(response.data.pocket.directories))
    store.dispatch(setDocumentsAction(response.data.pocket.documents))
    store.dispatch(setNotesAction(response.data.pocket.notes))
    store.dispatch(setFotosAction(response.data.pocket.fotos))
    store.dispatch(setVideosAction(response.data.pocket.videos))
    store.dispatch(setAudiosAction(response.data.pocket.audios))
    store.dispatch(setPocketRefetchNeeded(false))
}


export default pocketSlice.reducer