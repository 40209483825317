import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import "../css/components/ResponsiveAppBar.css"
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { NAVIGATE_ROOT, NAVIGATE_DESKTOP, isActivePath, NAVIGATE_LOGIN, NAVIGATE_VAULT, NAVIGATE_SHARED, NAVIGATE_PASSWORDS, NAVIGATE_ALBUMS, NAVIGATE_CONTACTS, NAVIGATE_CASHFLOW, NAVIGATE_REMINDERS } from '../utils/Navigation';
import { EnumMetadocLogoMode } from '../enums';
import { MetadocLogo } from './MetadocLogo';
import { AvatarMenu } from './AvatarMenu';

interface IPage {
    title: string
    path: string
}


const pages: IPage[] = [
    {
        title: 'Bureau',
        path: NAVIGATE_DESKTOP,
    },
    {
        title: 'Coffre',
        path: NAVIGATE_VAULT,
    },
    {
        title: 'Partage',
        path: NAVIGATE_SHARED,
    },
    {
        title: 'Albums',
        path: NAVIGATE_ALBUMS,
    },
    {
        title: 'Passwords',
        path: NAVIGATE_PASSWORDS,
    },
    {
        title: 'Contacts',
        path: NAVIGATE_CONTACTS,
    },
    {
        title: 'Cashflow',
        path: NAVIGATE_CASHFLOW,
    },
    {
        title: 'Reminder',
        path: NAVIGATE_REMINDERS,
    },
];

const ResponsiveAppBar = () => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate()
    const location = useLocation()


    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleButtonNavMenu = (page: IPage) => {
        setAnchorElNav(null);
        navigate(page.path)
    };

    return (
        <AppBar className='ResponsiveAppBar' position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {/* mobile */}
                    <Box sx={{ display: { width: '100%', xs: 'flex', md: 'none', justifyContent: 'space-between', alignItems: 'center' } }} >
                        <Box>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="default"
                                className={`menu-icon ${Boolean(anchorElNav) ? "active" : ""}`}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map((page, index) => (
                                    <MenuItem key={index} onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center">
                                            <Link to={page.path} className={`xs-menu-link ${isActivePath(page.path, location.pathname) ? "active" : ""}`}>
                                                {page.title}
                                            </Link>
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href={NAVIGATE_ROOT}
                            sx={{
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'white',
                                textDecoration: 'none',
                            }}
                        >
                            Metadoc
                        </Typography>
                        <AvatarMenu />
                    </Box>

                    {/* desktop */}
                    <Box sx={{ width: '100%', display: { xs: 'none', md: 'flex', justifyContent: 'space-between', alignItems: 'center' } }} >
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <Typography
                                variant="h6"
                                noWrap
                                // component="a"
                                // href={NAVIGATE_ROOT}
                                sx={{
                                    mr: 2,
                                    display: { alignItems: 'center' },
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    color: 'white',
                                    textDecoration: 'none',
                                }}
                                className="menu-link"
                            >
                                <MetadocLogo mode={EnumMetadocLogoMode.standard} link={true} />
                            </Typography>

                            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                {pages.map((page, index) => (
                                    // <Button
                                    //     key={index}
                                    //     onClick={() => handleButtonNavMenu(page)}
                                    //     sx={{ my: 2, color: 'inherit', display: 'block' }}
                                    //     className={`menu-link ${isActivePath(page.path, location.pathname) ? "active" : ""}`}
                                    // >
                                    //     {/* <Link to={page.path} className={`menu-link ${isActivePath(page.path, location.pathname) ? "active" : ""}`}> */}
                                    //     {page.title}
                                    //     {/* </Link> */}
                                    // </Button>
                                    <Typography
                                        key={index}
                                        // variant="h5"
                                        noWrap
                                        component="a"
                                        href={page.path}
                                        sx={{
                                            // fontFamily: 'monospace',
                                            // fontWeight: 700,
                                            // letterSpacing: '.3rem',
                                            color: 'white',
                                            textDecoration: 'none',
                                            mx: 1,
                                        }}
                                        className={`menu-link ${isActivePath(page.path, location.pathname) ? "active" : ""}`}
                                    >
                                        {page.title}
                                    </Typography>
                                ))}
                            </Box>
                        </Box>
                        <AvatarMenu />
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default ResponsiveAppBar;
