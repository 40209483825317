// import "../../css/components/directories/Directory.css"
import { Box, Collapse, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { IDirectory } from "../../interfaces";
import { PocketIcon } from '../svg_icons/PocketIcon';
import { EnumListLockersMode, EnumMessageType, EnumPocketTargetType, EnumSvgVariant } from '../../enums';
import { ExpandMore } from "../utils/ExpandMore";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import { useMutation } from '@apollo/client';
import { GQL_MUTATION_COPY_TO_DIRECTORY, GQL_MUTATION_COPY_TO_LOCKER, GQL_MUTATION_MOVE_TO_DIRECTORY, GQL_MUTATION_MOVE_TO_LOCKER, GQL_MUTATION_REMOVE_FROM_POCKET } from '../../graphql/Mutations';
import { addMessage } from '../messages/Message';
import { useDispatch } from 'react-redux';
import {
    removeDirectoriesAction as removeDirectoriesFromPocketAction,
} from "../../redux/features/pocket/pocketSlice";
import { DirectoryIcon } from '../svg_icons/DirectoryIcon';
import { useNavigate } from 'react-router-dom';
import { getObjectPath } from '../../utils/Navigation';
import { setAllRefetchNeeded } from '../../redux/store';
import { FloatingSpinner } from '../spinner/FloatingSpinner';

interface IProps {
    directory: IDirectory,
    targetType?: EnumPocketTargetType,
    targetId?: string,
    targetMode?: EnumListLockersMode,
}


export const DirectoryItem: FC<IProps> = (props) => {
    const [expanded, setExpanded] = useState(false)
    const [removeFromPocket, { data: removeFromPocketData, loading: removeFromPocketLoading, error: removeFromPocketError }] = useMutation(GQL_MUTATION_REMOVE_FROM_POCKET)
    const [moveToLocker, { data: moveToLockerData, loading: moveToLockerLoading, error: moveToLockerError }] = useMutation(GQL_MUTATION_MOVE_TO_LOCKER)
    const [moveToDirectory, { data: moveToDirectoryData, loading: moveToDirectoryLoading, error: moveToDirectoryError }] = useMutation(GQL_MUTATION_MOVE_TO_DIRECTORY)
    const [copyToLocker, { data: copyToLockerData, loading: copyToLockerLoading, error: copyToLockerError }] = useMutation(GQL_MUTATION_COPY_TO_LOCKER)
    const [copyToDirectory, { data: copyToDirectoryData, loading: copyToDirectoryLoading, error: copyToDirectoryError }] = useMutation(GQL_MUTATION_COPY_TO_DIRECTORY)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (removeFromPocketError) {
            addMessage({
                location: "DirectoryItem",
                type: EnumMessageType.Error,
                message: removeFromPocketError.message,
            })
        } else if (removeFromPocketData) {
            if (removeFromPocketData.removeFromPocket.statusCode === 200) {
                dispatch(removeDirectoriesFromPocketAction([props.directory.id]))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Retiré de la pocket.",
                })
            } else {
                removeFromPocketData.removeFromPocket.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [removeFromPocketData, removeFromPocketError])

    useEffect(() => {
        if (moveToLockerError) {
            addMessage({
                location: "DirectoryItem",
                type: EnumMessageType.Error,
                message: moveToLockerError.message,
            })
        } else if (moveToLockerData) {
            if (moveToLockerData.moveToLocker.statusCode === 200) {
                setAllRefetchNeeded("DirectoryItem")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Dossier déplacé.",
                })
            } else {
                moveToLockerData.moveToLocker.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [moveToLockerData, moveToLockerError])

    useEffect(() => {
        if (moveToDirectoryError) {
            addMessage({
                location: "DirectoryItem",
                type: EnumMessageType.Error,
                message: moveToDirectoryError.message,
            })
        } else if (moveToDirectoryData) {
            if (moveToDirectoryData.moveToDirectory.statusCode === 200) {
                setAllRefetchNeeded("DirectoryItem")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Dossier déplacé.",
                })
            } else {
                moveToDirectoryData.moveToDirectory.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [moveToDirectoryData, moveToDirectoryError])

    useEffect(() => {
        if (copyToLockerError) {
            addMessage({
                location: "Pocket",
                type: EnumMessageType.Error,
                message: copyToLockerError.message,
            })
        } else if (copyToLockerData) {
            if (copyToLockerData.copyToLocker.statusCode === 200) {
                setAllRefetchNeeded("DirectoryItem")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Dossier copié.",
                })
            } else {
                copyToLockerData.copyToLocker.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [copyToLockerData, copyToLockerError])

    useEffect(() => {
        if (copyToDirectoryError) {
            addMessage({
                location: "Pocket",
                type: EnumMessageType.Error,
                message: copyToDirectoryError.message,
            })
        } else if (copyToDirectoryData) {
            if (copyToDirectoryData.copyToDirectory.statusCode === 200) {
                setAllRefetchNeeded("DirectoryItem")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Dossier copié.",
                })
            } else {
                copyToDirectoryData.copyToDirectory.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [copyToDirectoryData, copyToDirectoryError])

    const handleExpand = () => {
        setExpanded(!expanded)
    }

    const handleRemoveFromPocket = () => {
        removeFromPocket({
            variables: {
                directoryIds: [props.directory.id],
            }
        })
    }

    const handleSee = () => {
        navigate(getObjectPath(props.directory))
    }
    const handleMove = () => {
        if (!props.targetType || !props.targetId) {
            addMessage({
                type: EnumMessageType.Error,
                message: "Destination incorecte.",
            })
        } else {
            if (props.targetType === EnumPocketTargetType.locker) {
                moveToLocker({
                    variables: {
                        lockerId: props.targetId,
                        directoryIds: [props.directory.id],
                    }
                })
            } else if (props.targetType === EnumPocketTargetType.directory) {
                moveToDirectory({
                    variables: {
                        directoryId: props.targetId,
                        directoryIds: [props.directory.id],
                    }
                })
            }
        }
    }
    const handleCopy = () => {
        if (!props.targetType || !props.targetId) {
            addMessage({
                type: EnumMessageType.Error,
                message: "Destination incorecte.",
            })
        } else {
            if (props.targetType === EnumPocketTargetType.locker) {
                copyToLocker({
                    variables: {
                        lockerId: props.targetId,
                        directoryIds: [props.directory.id],
                    }
                })

            } else if (props.targetType === EnumPocketTargetType.directory) {
                copyToDirectory({
                    variables: {
                        directoryId: props.targetId,
                        directoryIds: [props.directory.id],
                    }
                })
            }
        }
    }

    return (
        <Box
            className="DirectoryItem"
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                mb: 1,
                // backgroundColor: "red",
            }}
        >
            <FloatingSpinner dependances={[copyToDirectoryLoading, copyToLockerLoading, moveToLockerLoading, moveToDirectoryLoading]} />
            <Box sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}>
                <Box sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                }}>
                    <DirectoryIcon color={props.directory.mode}
                        sx={{
                            fontSize: 16,
                            p: 1,
                            mx: .5,
                            mr: -1,
                            border: "1px solid rgba(0, 0, 0, .2)",
                            borderRadius: "50%",
                            backgroundColor: "white",
                            zIndex: 2,
                        }}
                    />
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={props.directory.name}>
                        <Typography color="text.primary"
                            sx={{
                                fontSize: 16,
                                pl: 2,
                                pr: 2,
                                border: "1px solid rgba(0, 0, 0, .15)",
                                borderTopRightRadius: "7px",
                                borderBottomRightRadius: "7px",
                                backgroundColor: "white",
                                maxWidth: 120,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        >
                            {props.directory.name}
                        </Typography>
                    </Tooltip>
                </Box>
                <Box >
                    <ExpandMore expand={expanded}
                        onClick={handleExpand} aria-expanded={expanded} aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </ExpandMore>
                </Box>
            </Box>
            <Collapse in={expanded} timeout="auto" unmountOnExit
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                }}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                    }}
                >
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Voir">
                        <IconButton onClick={handleSee} color="primary" size="small">
                            <VisibilityOutlinedIcon sx={{ fontSize: 16 }} />
                        </IconButton>
                    </Tooltip>
                    {
                        props.targetType &&
                        props.targetMode &&
                        props.targetMode !== EnumListLockersMode.shared &&
                        ([EnumPocketTargetType.locker, EnumPocketTargetType.directory].includes(props.targetType)) &&
                        <>
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Déplacer ici">
                                <IconButton onClick={handleMove} color="primary" size="small">
                                    <DriveFileMoveOutlinedIcon sx={{ fontSize: 16 }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Copier ici">
                                <IconButton onClick={handleCopy} color="primary" size="small">
                                    <FileCopyOutlinedIcon sx={{ fontSize: 16 }} />
                                </IconButton>
                            </Tooltip>
                        </>
                    }
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Retirer de la pocket">
                        <IconButton onClick={handleRemoveFromPocket} color="primary" size="small">
                            <PocketIcon variant={EnumSvgVariant.primary} sx={{ fontSize: 16 }} />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Divider flexItem variant='middle' />
            </Collapse>
        </Box >
    )
}
