import "../../css/components/documents/Document.css";
import { Box, IconButton, Link, Paper, Skeleton, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { IBreadcrumb, IDocumentSearchResult } from "../../interfaces";
import { EnumBasePathFor, EnumBreadcrumbMode, EnumMessageType, EnumSvgVariant } from "../../enums";
import { dateFormat, truncateString } from "../../utils/Utils";
import { DocumentIcon } from "../svg_icons/DocumentIcon";
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { constructPath, getBasePath, getModePath } from "../../utils/Navigation";
import { Breadcrumbs } from "../Breadcrumbs";
import {
    setPocketRefetchNeeded,
    useGetDocuments as useGetPocketDocuments,
} from "../../redux/features/pocket/pocketSlice";
import { PocketIcon } from "../svg_icons/PocketIcon";
import { useMutation } from "@apollo/client";
import { GQL_MUTATION_ADD_IN_POCKET, GQL_MUTATION_REMOVE_FROM_POCKET } from "../../graphql/Mutations";
import { addMessage } from "../messages/Message";
import { useDispatch } from "react-redux";
import { Flexbox } from "../utils/Flexbox";

interface IProps {
    document: IDocumentSearchResult,
}

export const DocumentSearchResult: FC<IProps> = (props) => {
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[] | undefined>()
    const pocketDocuments = useGetPocketDocuments()
    const [previewLoaded, setPreviewLoaded] = useState(false);
    const [addInPocket, { data: addInPocketData, loading: addInPocketLoading, error: addInPocketError }] = useMutation(GQL_MUTATION_ADD_IN_POCKET)
    const [removeFromPocket, { data: removeFromPocketData, loading: removeFromPocketLoading, error: removeFromPocketError }] = useMutation(GQL_MUTATION_REMOVE_FROM_POCKET)

    const dispatch = useDispatch()
    const theme = useTheme()

    const directoryPath = constructPath(getBasePath(EnumBasePathFor.directory, props.document.directory.mode), [
        { key: "directoryId", value: props.document.directory.id.toString() }
    ])

    const getAncestorsBreadcrumbs = () => {
        if (props.document === undefined) {
            return [{ title: "..." }]
        }
        let res = []
        // Le locker d'abord:
        res.push(
            {
                title: props.document.directory.locker.title,
                path: constructPath(getBasePath(EnumBasePathFor.locker, props.document.directory.mode), [
                    { key: "lockerId", value: props.document.directory.locker.id.toString() }
                ])
            }
        )
        // Puis les ancestors:
        res.push(
            ...props.document.directory.ancestors.map(ancestor => {
                return {
                    title: ancestor.name,
                    path: constructPath(getBasePath(EnumBasePathFor.directory, props.document.directory.mode), [
                        { key: "directoryId", value: ancestor.id.toString() }
                    ])
                }
            })
        )
        return res
    }

    const isInPocket = () => {
        if (pocketDocuments) {
            return pocketDocuments.filter(document => document.id === props.document.id).length > 0
        }
        return false
    }

    const handlePutInPocket = () => {
        addInPocket({
            variables: {
                documentIds: [props.document.id],
            }
        })
    }

    const handleRemoveFromPocket = () => {
        removeFromPocket({
            variables: {
                documentIds: [props.document.id],
            }
        })
    }

    const handlePreviewLoaded = () => {
        setPreviewLoaded(true);
    };

    useEffect(() => {
        if (addInPocketError) {
            addMessage({
                location: "DocumentSearchResult",
                type: EnumMessageType.Error,
                message: addInPocketError.message,
            })
        } else if (addInPocketData) {
            if (addInPocketData.addInPocket.statusCode === 200) {
                // dispatch(addDocumentsInPocketAction([props.document]))
                dispatch(setPocketRefetchNeeded(true))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "C'est dans la pocket.",
                })
            } else {
                addInPocketData.addInPocket.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [addInPocketData, addInPocketError])

    useEffect(() => {
        if (removeFromPocketError) {
            addMessage({
                location: "DocumentSearchResult",
                type: EnumMessageType.Error,
                message: removeFromPocketError.message,
            })
        } else if (removeFromPocketData) {
            if (removeFromPocketData.removeFromPocket.statusCode === 200) {
                // dispatch(removeDocumentsFromPocketAction([props.document.id]))
                dispatch(setPocketRefetchNeeded(true))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Retiré de la pocket.",
                })
            } else {
                removeFromPocketData.removeFromPocket.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [removeFromPocketData, removeFromPocketError])

    useEffect(() => {
        setBreadcrumbs([
            getModePath(props.document.directory.mode),
            ...getAncestorsBreadcrumbs(),
            {
                title: props.document.directory.name || "...",
                path: constructPath(getBasePath(EnumBasePathFor.directory, props.document.directory.mode), [
                    { key: "directoryId", value: props.document.directory.id.toString() }
                ])
            },
            {
                title: props.document.title || "...",
            },
        ])
    }, [props.document.directory])

    return (
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", mx: 2, width: "100%" }}>
            <Paper className="Document">
                <Box className="Document-container">
                    <Box className="Document-main-section">
                        <Box className="Document-icon-section">
                            <DocumentIcon color={props.document.mode} sx={{ fontSize: 24, mx: .5, mt: -0.6 }} />
                        </Box>
                        <Flexbox sx={{ justifyContent: "space-between", alignItems: "flex-start" }}>
                            {
                                props.document.sheetPreviewUrl && <Flexbox sx={{ px: 1, py: 1 }}>
                                    {!previewLoaded && <Skeleton variant="rectangular" width={100} height={100} sx={{ borderRadius: 1 }} />}
                                    <Link href={props.document.sheetFileUrl} underline="none" target="_blank">
                                        <Box component="img"
                                            src={props.document.sheetPreviewUrl}
                                            onLoad={handlePreviewLoaded}
                                            sx={{
                                                width: "100px",
                                                borderRadius: 1,
                                                borderStyle: "solid",
                                                borderWidth: "1px",
                                                borderColor: theme.palette.grey[200],
                                                display: previewLoaded ? 'block' : 'none',
                                            }}
                                        />
                                    </Link>
                                </Flexbox>
                            }
                            <Flexbox direction="column" sx={{
                                pl: "4px",
                                pb: "4px",
                            }}>
                                <Flexbox sx={{ justifyContent: "flex-start" }}>
                                    {
                                        props.document.sheetFileUrl
                                            ?
                                            <>
                                                <Link href={props.document.sheetFileUrl} underline="none" target="_blank">
                                                    <Typography color="text.primary" sx={{
                                                        fontSize: 18,
                                                        overflowWrap: "anywhere",
                                                        ":hover": {
                                                            cursor: "pointer",
                                                            textShadow: `-1px 0px 4px ${theme.palette.grey[600]}`,
                                                        }
                                                    }}>
                                                        {props.document.title}
                                                    </Typography>
                                                </Link>
                                            </>
                                            :
                                            <>
                                                <Typography color="text.primary" sx={{ fontSize: 18, overflowWrap: "anywhere" }}>
                                                    {props.document.title}
                                                </Typography>
                                            </>
                                    }
                                </Flexbox>
                                <Flexbox sx={{ justifyContent: "flex-start" }}>
                                    <Typography sx={{ fontSize: 14 }} >
                                        {truncateString(props.document.description, 80)}
                                    </Typography>
                                </Flexbox>

                                <Flexbox sx={{ justifyContent: "flex-start" }}>
                                    <Flexbox sx={{ justifyContent: "flex-start", mt: 1 }}>
                                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Dernière modification">
                                            <EventOutlinedIcon color={props.document.mode} sx={{ fontSize: 18, mr: .5 }} />
                                        </Tooltip>
                                        <Typography color="text.secondary" sx={{ fontSize: 14 }}>
                                            {`${dateFormat(props.document.updatedAt)}`}
                                        </Typography>
                                    </Flexbox>
                                </Flexbox>
                            </Flexbox>

                        </Flexbox>

                    </Box>
                    <Box className="Document-footer-section">
                        {
                            isInPocket() ?
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Retirer de la pocket">
                                    <IconButton onClick={handleRemoveFromPocket} color="secondary">
                                        <PocketIcon variant={EnumSvgVariant.secondary} />
                                    </IconButton>
                                </Tooltip> :
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Placer dans la pocket">
                                    <IconButton onClick={handlePutInPocket} color="primary">
                                        <PocketIcon variant={EnumSvgVariant.primary} />
                                    </IconButton>
                                </Tooltip>
                        }
                        {
                            props.document.sheetFileUrl &&
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Ouvrir dans un nouvel onglet">
                                <Link href={props.document.sheetFileUrl} underline="none" target="_blank">
                                    <IconButton color="primary">
                                        <OpenInNewOutlinedIcon />
                                    </IconButton>
                                </Link>
                            </Tooltip>
                        }
                        {
                            props.document.sheetFileUrlForDownload &&
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Télécharger">
                                <IconButton href={props.document.sheetFileUrlForDownload} color="primary">
                                    <CloudDownloadOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        }
                    </Box>
                </Box>
            </Paper>
            <Box sx={{ ml: 2 }}>
                <Breadcrumbs mode={EnumBreadcrumbMode.standard} breadcrumbs={breadcrumbs} sx={{ mt: -1, mb: 2 }} />
            </Box>
        </Box>
    )
}
