import { Box, Divider, IconButton, Link, Paper, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { IReminder } from "../../interfaces";
import {
    DeleteOutline, EditOutlined
} from '@mui/icons-material';

import { truncateString } from "../../utils/Utils";
import { KeysIcon } from "../svg_icons/KeysIcon";
import { Flexbox } from "../utils/Flexbox";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { ModalDeleteConfirm } from '../modals/ModalDeleteConfirm';
import { useMutation } from '@apollo/client';
import { addMessage } from '../messages/Message';
import { EnumDayjsFormat, EnumMessageType, EnumSize } from '../../enums';
import { setAllRefetchNeeded } from '../../redux/store';
import { GQL_MUTATION_DELETE_REMINDER } from '../../graphql/Mutations';
// import { ModalEditReminder } from './modals/ModalEditReminder';
import { ContactBadge } from '../contacts/ContactBadge';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';
import { ModalEditReminder } from './modals/ModalEditReminder';


interface IProps {
    reminder: IReminder,
}

export const Reminder: FC<IProps> = (props) => {
    const [showDeleteReminderDialog, setShowDeleteReminderDialog] = useState(false)
    const [showModalEditReminder, setShowModalEditReminder] = useState(false)
    const [deleteReminder, { data: deleteReminderData, loading: deleteReminderLoading, error: deleteReminderError }] = useMutation(GQL_MUTATION_DELETE_REMINDER)

    const theme = useTheme()

    useEffect(() => {
        if (deleteReminderError) {
            addMessage({
                location: "Reminder",
                type: EnumMessageType.Error,
                message: deleteReminderError.message,
            })
        } else if (deleteReminderData) {
            if (deleteReminderData.deleteReminder.statusCode === 200) {
                setAllRefetchNeeded("Reminder")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Reminder supprimé.",
                })
            } else {
                deleteReminderData.deleteReminder.errors.map((error: string) => {
                    addMessage({
                        location: "Reminder",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteReminderData, deleteReminderError])

    const handleEdit = () => {
        setShowModalEditReminder(true)
    }

    const handleDelete = () => {
        setShowDeleteReminderDialog(true)
    }

    const closeDeleteReminderDialog = () => {
        setShowDeleteReminderDialog(false)
    }

    const confirmedDeleteReminder = () => {
        deleteReminder({
            variables: {
                reminderId: props.reminder.id
            }
        })
        setShowDeleteReminderDialog(false)
    }

    const closeModalEditReminder = () => {
        setShowModalEditReminder(false)
    }

    return (
        <>
            <Paper
                sx={{
                    marginTop: "24px",
                    marginBottom: "12px",
                    marginLeft: "12px",
                    marginRight: "12px",
                    width: "100%",
                }}>
                <Box sx={{
                    paddingTop: "12px",
                    paddingBottom: "6px",
                    paddingLeft: "12px",
                    paddingRight: "12px",
                }}>
                    <Flexbox sx={{
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                    }}>
                        <Box sx={{
                            paddingTop: "12px",
                            paddingBottom: "8px",
                            paddingLeft: "8px",
                            paddingRight: "8px",
                            marginTop: "-32px",
                            marginLeft: "-24px",
                            backgroundColor: "white",
                            borderRadius: "50%",
                            borderLeft: "1px solid rgb(0 0 0 / 12%)",
                        }}>
                            <AccessAlarmIcon color={props.reminder.deliveredAt ? "success" : "vault"} sx={{ fontSize: 24, mx: .5 }} />
                        </Box>
                        <Box sx={{
                            width: "100%",
                            paddingLeft: "4px",
                            paddingRight: "4px",
                            paddingBottom: "4px",
                        }}>
                            <Flexbox sx={{
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}>
                                <Typography>
                                    {dayjs(props.reminder.deliveredAt ? props.reminder.deliveredAt : props.reminder.deliverAt).locale('fr').format(EnumDayjsFormat.date)}
                                </Typography>
                                <ContactBadge
                                    contact={props.reminder.contact}
                                    size={EnumSize.small}
                                    displayContactTitle={true}
                                    displayTooltip={true}
                                />
                            </Flexbox>
                            <Typography sx={{
                                my: 1,
                                color: theme.palette.text.secondary,
                            }}>
                                {props.reminder.content}
                            </Typography>
                        </Box>
                    </Flexbox>
                    <Box>
                        <Divider />
                        <Flexbox sx={{
                            justifyContent: props.reminder.deliveredAt ? "flex-end" : "space-between",
                            flexWrap: "wrap",
                        }}>
                            {
                                !props.reminder.deliveredAt && <>
                                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Modifier">
                                        <IconButton onClick={handleEdit} color="primary">
                                            <EditOutlined />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            }
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Supprimer">
                                <IconButton onClick={handleDelete} color="error">
                                    <DeleteOutline />
                                </IconButton>
                            </Tooltip>
                        </Flexbox>
                    </Box>
                </Box>
            </Paper>
            <ModalDeleteConfirm
                open={showDeleteReminderDialog}
                title="Suppression du reminder"
                content={`Confirmez-vous la suppression définitive du reminder ?`}
                handleClose={closeDeleteReminderDialog}
                handleConfirm={confirmedDeleteReminder}
            />
            <ModalEditReminder
                open={showModalEditReminder}
                handleClose={closeModalEditReminder}
                reminder={props.reminder}
            />
        </>
    )
}
