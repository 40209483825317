import { Box, Divider, IconButton, Paper, Skeleton, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { IFoto } from "../../interfaces";
import {
    CropFreeOutlined,
    DeleteOutline
} from '@mui/icons-material';

import { Flexbox } from "../utils/Flexbox";
import { ModalDeleteConfirm } from '../modals/ModalDeleteConfirm';
import { useMutation } from '@apollo/client';
import { addMessage } from '../messages/Message';
import { EnumMessageType, EnumSvgVariant } from '../../enums';
import { setAllRefetchNeeded } from '../../redux/store';
import { GQL_MUTATION_ADD_IN_POCKET, GQL_MUTATION_DELETE_FOTO, GQL_MUTATION_REMOVE_FROM_POCKET, GQL_MUTATION_ROTATE_FOTO } from '../../graphql/Mutations';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { PocketIcon } from '../svg_icons/PocketIcon';
import RotateRightIcon from '@mui/icons-material/RotateRight';

import {
    addFotosAction as addFotosInPocketAction,
    removeFotosAction as removeFotosFromPocketAction,
    useGetFotos as useGetPocketFotos,
} from "../../redux/features/pocket/pocketSlice";
import { useDispatch } from 'react-redux';
import { FloatingSpinner } from '../spinner/FloatingSpinner';
import { toggleSelectFotoAction } from '../../redux/features/albums/albumSlice';

interface IProps {
    foto: IFoto,
    selected?: boolean,
    onClick: (index: number) => void,
    index: number,
}

export const Foto: FC<IProps> = (props) => {
    const [previewLoaded, setPreviewLoaded] = useState(false);
    const [showDeleteFotoDialog, setShowDeleteFotoDialog] = useState(false)
    const [deleteFoto, { data: deleteFotoData, loading: deleteFotoLoading, error: deleteFotoError }] = useMutation(GQL_MUTATION_DELETE_FOTO)

    const pocketFotos = useGetPocketFotos()
    const [addInPocket, { data: addInPocketData, loading: addInPocketLoading, error: addInPocketError }] = useMutation(GQL_MUTATION_ADD_IN_POCKET)
    const [removeFromPocket, { data: removeFromPocketData, loading: removeFromPocketLoading, error: removeFromPocketError }] = useMutation(GQL_MUTATION_REMOVE_FROM_POCKET)

    const [rotateFoto, { data: rotateFotoData, loading: rotateFotoLoading, error: rotateFotoError }] = useMutation(GQL_MUTATION_ROTATE_FOTO)

    const theme = useTheme()
    const dispatch = useDispatch()

    const handlePreviewLoaded = () => {
        setPreviewLoaded(true);
    }

    useEffect(() => {
        if (deleteFotoError) {
            addMessage({
                location: "Foto",
                type: EnumMessageType.Error,
                message: deleteFotoError.message,
            })
        } else if (deleteFotoData) {
            if (deleteFotoData.deleteFoto.statusCode === 200) {
                setAllRefetchNeeded("Foto")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Foto supprimée.",
                })
            } else {
                deleteFotoData.deleteFoto.errors.map((error: string) => {
                    addMessage({
                        location: "Foto",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteFotoData, deleteFotoError])

    useEffect(() => {
        if (rotateFotoError) {
            addMessage({
                location: "Foto",
                type: EnumMessageType.Error,
                message: rotateFotoError.message,
            })
        } else if (rotateFotoData) {
            if (rotateFotoData.rotateFoto.statusCode === 200) {
                setAllRefetchNeeded("Foto")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Rotation de la foto effectuée.",
                })
            } else {
                rotateFotoData.rotateFoto.errors.map((error: string) => {
                    addMessage({
                        location: "Foto",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [rotateFotoData, rotateFotoError])

    useEffect(() => {
        if (addInPocketError) {
            addMessage({
                location: "Foto",
                type: EnumMessageType.Error,
                message: addInPocketError.message,
            })
        } else if (addInPocketData) {
            if (addInPocketData.addInPocket.statusCode === 200) {
                dispatch(addFotosInPocketAction([props.foto]))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "C'est dans la pocket.",
                })
            } else {
                addInPocketData.addInPocket.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [addInPocketData, addInPocketError])

    useEffect(() => {
        if (removeFromPocketError) {
            addMessage({
                location: "Foto",
                type: EnumMessageType.Error,
                message: removeFromPocketError.message,
            })
        } else if (removeFromPocketData) {
            if (removeFromPocketData.removeFromPocket.statusCode === 200) {
                dispatch(removeFotosFromPocketAction([props.foto.id]))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Retiré de la pocket.",
                })
            } else {
                removeFromPocketData.removeFromPocket.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [removeFromPocketData, removeFromPocketError])


    const handleDelete = () => {
        setShowDeleteFotoDialog(true)
    }

    const closeDeleteFotoDialog = () => {
        setShowDeleteFotoDialog(false)
    }

    const confirmedDeleteFoto = () => {
        deleteFoto({
            variables: {
                fotoId: props.foto.id
            }
        })
        setShowDeleteFotoDialog(false)
    }

    const isInPocket = () => {
        if (pocketFotos) {
            return pocketFotos.filter(foto => foto.id === props.foto.id).length > 0
        }
        return false
    }

    const handlePutInPocket = () => {
        addInPocket({
            variables: {
                fotoIds: [props.foto.id],
            }
        })
    }

    const handleRemoveFromPocket = () => {
        removeFromPocket({
            variables: {
                fotoIds: [props.foto.id],
            }
        })
    }

    const handleRotate = () => {
        rotateFoto({
            variables: {
                fotoId: props.foto.id,
            }
        })
    }

    const handleSelect = () => {
        dispatch(toggleSelectFotoAction(props.foto.id))
    }


    return (
        <>
            <FloatingSpinner dependances={[rotateFotoLoading]} />
            <Paper className={`model-foto-${props.foto.id}`}
                sx={{
                    marginTop: "24px",
                    marginBottom: "12px",
                    marginLeft: "12px",
                    marginRight: "12px",
                    // width: "100%",
                    width: "320px",
                    boxShadow: props.selected ? `0px 0px 4px 2px ${theme.palette.secondary.main}` : "",
                }}>
                <Box sx={{
                    // paddingTop: "12px",
                    // paddingBottom: "6px",
                    // paddingLeft: "12px",
                    // paddingRight: "12px",
                }}>
                    {
                        props.foto.fotoPreviewUrl === null && <Flexbox sx={{
                            height: "200px",
                            px: 1,
                            py: 1,
                            backgroundColor: theme.palette.fotoBackground.main,
                            borderTopLeftRadius: 4,
                            borderTopRightRadius: 4,
                        }}>
                            <Typography sx={{
                                color: "white",
                                textAlign: "center",
                                cursor: "pointer",
                                ":hover": {
                                    color: theme.palette.secondary.main,
                                }
                            }}
                                onClick={() => props.onClick(props.index)}
                            >
                                Afficher la photo
                                <br />
                                <Typography sx={{ color: theme.palette.cancel.main }}>

                                    (miniature en construction..)
                                </Typography>
                            </Typography>
                        </Flexbox>
                    }
                    {
                        props.foto.fotoPreviewUrl && <Flexbox sx={{
                            px: 1,
                            py: 1,
                            backgroundColor: theme.palette.fotoBackground.main,
                            borderTopLeftRadius: 4,
                            borderTopRightRadius: 4,
                        }}>
                            {!previewLoaded && <Skeleton variant="rectangular" width={200} height={200} sx={{ borderRadius: 1 }} />}
                            <Box component="img"
                                src={props.foto.fotoPreviewUrl}
                                onLoad={handlePreviewLoaded}
                                sx={{
                                    maxHeight: "200px",
                                    maxWidth: "300px",
                                    borderRadius: 1,
                                    display: previewLoaded ? 'block' : 'none',
                                    cursor: "pointer",
                                }}
                                onClick={() => props.onClick(props.index)}
                            />

                        </Flexbox>
                    }


                    <Box>
                        <Divider />
                        <Flexbox sx={{
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                        }}>
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Télécharger">
                                <IconButton href={props.foto.fotoFileUrlForDownload} color="primary">
                                    <CloudDownloadOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={props.selected ? "Désélectionner" : "Sélectionner"}>
                                <IconButton onClick={handleSelect} color={props.selected ? "cancel" : "secondary"}>
                                    <CropFreeOutlined />
                                </IconButton>
                            </Tooltip>
                            {
                                isInPocket() ?
                                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Retirer de la pocket">
                                        <IconButton onClick={handleRemoveFromPocket} color="secondary">
                                            <PocketIcon variant={EnumSvgVariant.secondary} />
                                        </IconButton>
                                    </Tooltip> :
                                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Placer dans la pocket">
                                        <IconButton onClick={handlePutInPocket} color="primary">
                                            <PocketIcon variant={EnumSvgVariant.primary} />
                                        </IconButton>
                                    </Tooltip>
                            }
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Rotation">
                                <IconButton onClick={handleRotate} color="primary">
                                    <RotateRightIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Supprimer">
                                <IconButton onClick={handleDelete} color="error">
                                    <DeleteOutline />
                                </IconButton>
                            </Tooltip>
                        </Flexbox>
                    </Box>
                </Box>
            </Paper>
            <ModalDeleteConfirm
                open={showDeleteFotoDialog}
                title="Suppression de la photo"
                content={`Confirmez-vous la suppression définitive de la photo ?`}
                handleClose={closeDeleteFotoDialog}
                handleConfirm={confirmedDeleteFoto}
            />
        </>
    )
}
