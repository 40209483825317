import { Box, Container, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import "yet-another-react-lightbox/styles.css";
import { IAccountOperation, IAccountOperationStatesFilter, IBankAccount } from '../../../interfaces';
import { Add } from '@mui/icons-material';
import { Flexbox } from '../../utils/Flexbox';
import { ModalNewAccountOperation } from './modals/ModalNewAccountOperation';
import { AccountOperation } from './AccountOperation';
import { DatePeriod } from '../../utils/DatePeriod';
import { EnumAccountOperationImputableVisibility, EnumAccountOperationType, EnumAccountOperationTypeVisibility, EnumDatePeriodMode, EnumDayjsFormat, EnumDevice } from '../../../enums';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState, setAllRefetchNeeded } from '../../../redux/store';
import { setDateFromAction, setDateToAction, setFilter as setReduxFilter, setStatesFilter as setReduxStatesFilter, toggleImputableVisibility as toggleReduxImputableVisibility, toggleAccountOperationTypeVisibility as toggleReduxAccountOperationTypeVisibility } from '../../../redux/features/cashflow/bankAccountSlice';
import { filterMatch } from '../../../utils/Utils';
import { AccountOperationsFilter } from './AccountOperationsFilter';
import { AccountOperationsTotals } from './AccountOperationsTotals';


interface IProps {
    bankAccount: IBankAccount
}

export const ListAccountOperations: FC<IProps> = (props) => {
    const [showModalNewAccountOperation, setShowModalNewAccountOperation] = useState(false)
    const dateFrom: Dayjs = dayjs(useSelector((state: IRootState) => state.bankAccountReducer.dateFrom), EnumDayjsFormat.date)
    const dateTo: Dayjs = dayjs(useSelector((state: IRootState) => state.bankAccountReducer.dateTo), EnumDayjsFormat.date)
    const filter: string = useSelector((state: IRootState) => state.bankAccountReducer.filter)
    const [filteredAccountOperations, setFilteredAccountOperations] = useState<IAccountOperation[] | undefined>([])
    const statesFilter: IAccountOperationStatesFilter = useSelector((state: IRootState) => state.bankAccountReducer.statesFilter)
    const imputableVisibility: EnumAccountOperationImputableVisibility = useSelector((state: IRootState) => state.bankAccountReducer.imputableVisibility)
    const accountOperationTypeVisibility: EnumAccountOperationTypeVisibility = useSelector((state: IRootState) => state.bankAccountReducer.accountOperationTypeVisibility)

    const dispatch = useDispatch()

    useEffect(() => {
        if (props.bankAccount && props.bankAccount.accountOperations) {
            let tempFilteredAccountOperations = [...props.bankAccount.accountOperations]
            // On garde l'opération si elle est dans un des états sélectionnés
            tempFilteredAccountOperations = tempFilteredAccountOperations.filter(tempFilteredAccountOperation => (statesFilter[tempFilteredAccountOperation.state] === true))

            // On garde l'opération si elle est imputable ou non selon la visibilité
            if (imputableVisibility === EnumAccountOperationImputableVisibility.imputable) {
                tempFilteredAccountOperations = tempFilteredAccountOperations.filter(tempFilteredAccountOperation => tempFilteredAccountOperation.imputable === true)
            } else if (imputableVisibility === EnumAccountOperationImputableVisibility.notImputable) {
                tempFilteredAccountOperations = tempFilteredAccountOperations.filter(tempFilteredAccountOperation => tempFilteredAccountOperation.imputable === false)
            }

            // On garde l'opération si elle est de type crédit ou débit selon la visibilité
            if (accountOperationTypeVisibility === EnumAccountOperationTypeVisibility.credit) {
                tempFilteredAccountOperations = tempFilteredAccountOperations.filter(tempFilteredAccountOperation => tempFilteredAccountOperation.accountOperationType === EnumAccountOperationType.credit)
            } else if (accountOperationTypeVisibility === EnumAccountOperationTypeVisibility.debit) {
                tempFilteredAccountOperations = tempFilteredAccountOperations.filter(tempFilteredAccountOperation => tempFilteredAccountOperation.accountOperationType === EnumAccountOperationType.debit)
            }

            // On applique le filtre
            if (filter.length !== 0) {
                tempFilteredAccountOperations = tempFilteredAccountOperations.filter(tempFilteredAccountOperation => (
                    filterMatch(tempFilteredAccountOperation.title, filter) ||
                    filterMatch(`${tempFilteredAccountOperation.amount}`, filter) ||
                    filterMatch(tempFilteredAccountOperation.description, filter) ||
                    filterMatch(tempFilteredAccountOperation.reference, filter) ||
                    // filterMatch(tempFilteredAccountOperation.customContact?.contact?.nickname, filter) ||
                    // filterMatch(tempFilteredAccountOperation.customContact?.contact?.firstname, filter) ||
                    // filterMatch(tempFilteredAccountOperation.customContact?.contact?.lastname, filter) ||
                    // filterMatch(tempFilteredAccountOperation.customContact?.contact?.enterpriseName, filter) ||
                    filterMatch(tempFilteredAccountOperation.customContact?.title, filter)
                ))
            }
            setFilteredAccountOperations([...tempFilteredAccountOperations])
        }
    }, [props.bankAccount, filter, statesFilter, imputableVisibility, accountOperationTypeVisibility])

    const closeModalNewAccountOperation = () => {
        setShowModalNewAccountOperation(false)
    }

    const handleDates = (pDateFrom: Dayjs, pDateTo: Dayjs) => {
        dispatch(setDateFromAction(pDateFrom.format(EnumDayjsFormat.date)))
        dispatch(setDateToAction(pDateTo.format(EnumDayjsFormat.date)))
        setAllRefetchNeeded("ListAccountOperations")
    }

    const setFilter = (pFilter: string) => {
        dispatch(setReduxFilter(pFilter))
    }


    const setStatesFilter = (pStatesFilter: IAccountOperationStatesFilter) => {
        dispatch(setReduxStatesFilter(pStatesFilter))
    }

    const toggleImputableVisibility = () => {
        dispatch(toggleReduxImputableVisibility())
    }

    const toggleAccountOperationTypeVisibility = () => {
        dispatch(toggleReduxAccountOperationTypeVisibility())
    }

    return (
        <div className='ListAccountOperations'>
            <Container className="ListAccountOperations-actions"
                sx={{
                    mt: "12px",
                    // bgcolor: "green",
                }}
            >
                <Flexbox sx={{
                    // bgcolor: "blue",
                    justifyContent: "space-between",
                }}>
                    <Flexbox>
                        <DatePeriod mode={EnumDatePeriodMode.byMonth} dateFrom={dateFrom} dateTo={dateTo} handleDates={handleDates} />
                    </Flexbox>
                    <Flexbox sx={{
                        // bgcolor: "red",
                    }}>
                        {/* Desktop only */}
                        <Flexbox sx={{
                            display: { xs: "none", md: "flex" },
                        }}>
                            <AccountOperationsFilter
                                imputableVisibility={imputableVisibility}
                                toggleImputableVisibility={toggleImputableVisibility}
                                mode={EnumDevice.desktop}
                                defaulFiltertValue={filter}
                                setFilter={setFilter}
                                setStatesFilter={setStatesFilter}
                                statesFilter={statesFilter}
                                accountOperationTypeVisibility={accountOperationTypeVisibility}
                                toggleAccountOperationTypeVisibility={toggleAccountOperationTypeVisibility}
                            />
                        </Flexbox>
                        <Divider orientation="vertical" flexItem variant="middle" sx={{
                            mx: 1,
                        }} />
                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Ajouter une opération">
                            <IconButton color="primary" onClick={() => setShowModalNewAccountOperation(true)}><Add fontSize="medium" /></IconButton>
                        </Tooltip>
                        <ModalNewAccountOperation
                            bankAccountId={props.bankAccount.id}
                            open={showModalNewAccountOperation}
                            handleClose={closeModalNewAccountOperation}
                        />
                    </Flexbox>
                </Flexbox>
                {/* mobile only */}
                <Flexbox sx={{
                    display: { xs: "flex", md: "none" },
                }}>
                    <AccountOperationsFilter
                        imputableVisibility={imputableVisibility}
                        toggleImputableVisibility={toggleImputableVisibility}
                        mode={EnumDevice.mobile}
                        setFilter={setFilter}
                        defaulFiltertValue={filter}
                        setStatesFilter={setStatesFilter}
                        statesFilter={statesFilter}
                        accountOperationTypeVisibility={accountOperationTypeVisibility}
                        toggleAccountOperationTypeVisibility={toggleAccountOperationTypeVisibility}
                    />
                </Flexbox>
            </Container>
            <Container sx={{
                // bgcolor: "red",
            }}>
                {
                    filteredAccountOperations && <>
                        <AccountOperationsTotals bankAccount={props.bankAccount} filteredAccountOperations={filteredAccountOperations} dateFrom={dateFrom} dateTo={dateTo} />
                    </>
                }
            </Container>

            <Container className="ListAccountOperations-container">
                <Flexbox direction='column'>
                    {
                        filteredAccountOperations && <>
                            {
                                filteredAccountOperations.map((accountOperation) => {
                                    return (
                                        <Flexbox key={`${accountOperation.id}`} sx={{
                                            width: "100%",
                                        }}>
                                            <AccountOperation dateFrom={dateFrom} dateTo={dateTo} accountOperation={accountOperation} />
                                        </Flexbox>
                                    )
                                })
                            }
                        </>
                    }
                    {
                        props.bankAccount !== undefined && props.bankAccount.accountOperations !== undefined && props.bankAccount.accountOperations.length === 0 &&
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography color="text.secondary" sx={{ fontSize: 24 }}>
                                Aucune opération.
                            </Typography>
                        </Box>
                    }
                </Flexbox>
            </Container>
        </div>
    )
}
