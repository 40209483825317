import { Avatar, Box, Divider, IconButton, Paper, Skeleton, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { IContact } from "../../interfaces";
import {
    CropFreeOutlined,
    DeleteOutline, EditOutlined
} from '@mui/icons-material';

import { displayContactSubtitle, getDayjsAge, truncateString } from "../../utils/Utils";
import { Flexbox } from "../utils/Flexbox";
import { ModalDeleteConfirm } from '../modals/ModalDeleteConfirm';
import { useMutation } from '@apollo/client';
import { addMessage } from '../messages/Message';
import { EnumContactType, EnumDayjsFormat, EnumMessageType, EnumModalContactDownloadVcardsMode } from '../../enums';
import { setAllRefetchNeeded } from '../../redux/store';
import { GQL_MUTATION_DELETE_CONTACT, GQL_MUTATION_EXPORT_CONTACT_VCARDS } from '../../graphql/Mutations';
import { ModalEditContact } from './modals/ModalEditContact';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { ModalContactAvatar } from './modals/ModalContactAvatar';
import { ContactRelationships } from './ContactRelationships';
import { useDispatch } from 'react-redux';
import { toggleSelectContactAction } from '../../redux/features/contacts/contactsSlice';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { ModalContactDownloadVcards } from './modals/ModalContactDownloadVcards';
import { CustomContactRelationships } from '../customContacts/CustomContactRelationships';


interface IProps {
    contact: IContact,
}

export const Contact: FC<IProps> = (props) => {
    const [showDeleteContactDialog, setShowDeleteContactDialog] = useState(false)
    const [showModalEditContact, setShowModalEditContact] = useState(false)
    const [deleteContact, { data: deleteContactData, loading: deleteContactLoading, error: deleteContactError }] = useMutation(GQL_MUTATION_DELETE_CONTACT)
    const [avatarLoaded, setAvatarLoaded] = useState(false)
    const [showModalContactAvatar, setShowModalContactAvatar] = useState(false)
    const [exportContactVcards, { data: exportContactVcardsData, loading: exportContactVcardsLoading, error: exportContactVcardsError }] = useMutation(GQL_MUTATION_EXPORT_CONTACT_VCARDS)
    const [showModalContactDownloadVcards, setShowModalContactDownloadVcards] = useState(false)
    const [downloadContactsUrl, setDownloadContactsUrl] = useState<string | undefined>(undefined)

    const theme = useTheme()
    const dispatch = useDispatch()

    useEffect(() => {
        if (exportContactVcardsError) {
            closeModalContactDownloadVcards()
            addMessage({
                location: "Contact",
                type: EnumMessageType.Error,
                message: exportContactVcardsError.message,
            })
        } else if (exportContactVcardsData) {
            if (exportContactVcardsData.exportContactVcards.statusCode === 200) {
                setDownloadContactsUrl(exportContactVcardsData.exportContactVcards.sheetFileUrl)
            } else {
                exportContactVcardsData.exportContactVcards.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [exportContactVcardsData, exportContactVcardsError])

    useEffect(() => {
        if (deleteContactError) {
            addMessage({
                location: "Contact",
                type: EnumMessageType.Error,
                message: deleteContactError.message,
            })
        } else if (deleteContactData) {
            if (deleteContactData.deleteContact.statusCode === 200) {
                setAllRefetchNeeded("Contact")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Contact supprimé.",
                })
            } else {
                deleteContactData.deleteContact.errors.map((error: string) => {
                    addMessage({
                        location: "Contact",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteContactData, deleteContactError])

    const closeModalContactDownloadVcards = () => {
        setShowModalContactDownloadVcards(false)
        setDownloadContactsUrl(undefined)
    }


    const handleEdit = () => {
        setShowModalEditContact(true)
    }

    const handleDelete = () => {
        setShowDeleteContactDialog(true)
    }

    const closeDeleteContactDialog = () => {
        setShowDeleteContactDialog(false)
    }

    const confirmedDeleteContact = () => {
        deleteContact({
            variables: {
                contactId: props.contact.id
            }
        })
        setShowDeleteContactDialog(false)
    }

    const closeModalEditContact = () => {
        setShowModalEditContact(false)
    }

    const handleAvatarLoaded = () => {
        setAvatarLoaded(true)
    }

    const handleEditAvatar = () => {
        setShowModalContactAvatar(true)
    }

    const closeModalContactAvatar = () => {
        setShowModalContactAvatar(false)
    }

    const handleSelect = () => {
        dispatch(toggleSelectContactAction(props.contact.id))
    }

    const handleDownloadContact = () => {
        setShowModalContactDownloadVcards(true)
        exportContactVcards(
            {
                variables: {
                    contactIds: [props.contact.id],
                    fileName: `${props.contact.displayTitle}.vcf`,
                }
            }
        )
    }

    return (
        <>
            <Paper id={`Contact-${props.contact.id}`} className="Contact" sx={{
                width: "100%",
                boxShadow: props.contact.selected ? `0px 0px 4px 2px ${theme.palette.secondary.main}` : "",
            }}>
                <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: "flex-start", alignItems: { xs: "center", md: "flex-start" }, py: 2, px: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", mr: { xs: 0, md: 3 } }}>
                        {
                            !avatarLoaded && props.contact.contactAvatarPreviewUrl &&
                            <Skeleton variant='circular' animation="wave"
                                sx={{
                                    width: 72,
                                    height: 72,
                                    borderWidth: 1,
                                    borderStyle: "solid",
                                    borderColor: theme.palette.primary.main,
                                    ":hover": {
                                        borderColor: theme.palette.secondary.main,
                                        cursor: "pointer",
                                    }
                                }}
                                onClick={handleEditAvatar}
                            />
                        }
                        <Avatar alt={props.contact.displayTitle}
                            onLoad={handleAvatarLoaded}
                            onClick={handleEditAvatar}
                            src={`${props.contact.contactAvatarPreviewUrl}`}
                            sx={{
                                width: 72,
                                height: 72,
                                display: (avatarLoaded || !props.contact.contactAvatarPreviewUrl) ? "flex" : "none",
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderColor: theme.palette.primary.main,
                                ":hover": {
                                    borderColor: theme.palette.secondary.main,
                                    cursor: "pointer",
                                }
                            }} />
                    </Box>
                    <Box sx={{ display: "flex", width: "100%", flexDirection: "column", alignItems: { xs: "center", md: "flex-start" } }}>
                        <Flexbox sx={{ justifyContent: { xs: "center", md: "flex-start" } }}>
                            <Typography variant="h5" color="primary">
                                {props.contact.displayTitle}
                            </Typography>
                        </Flexbox>
                        {
                            props.contact.nickname && (props.contact.firstname || props.contact.lastname) &&
                            <Typography sx={{
                                color: theme.palette.text.secondary,
                                fontSize: "12px",
                            }}>
                                {`${props.contact.firstname} ${props.contact.lastname}`}
                            </Typography>
                        }
                        <Typography variant="h6" color="secondary">
                            {displayContactSubtitle(props.contact)}
                        </Typography>
                        <Typography
                            component="a"
                            href={`tel:${props.contact.phone}`}
                            sx={{
                                color: theme.palette.text.secondary,
                                textDecoration: "none",
                            }} >
                            {props.contact.phone}
                        </Typography>
                        {
                            props.contact.email &&
                            <Typography variant="body1" sx={{ fontSize: "14px", color: theme.palette.text.secondary, wordBreak: "break-all" }}>
                                {props.contact.email}
                            </Typography>
                        }
                    </Box>
                </Box>
                <Flexbox direction='column' sx={{ justifyContent: "flex-start", alignItems: "flex-start", px: 2 }}>
                    {
                        props.contact.birthAt && <>
                            <Flexbox sx={{
                                width: "100%",
                                justifyContent: "space-between",
                            }}>
                                <Typography>
                                    {dayjs(props.contact.birthAt).locale('fr').format(EnumDayjsFormat.date)}
                                </Typography>
                                <Typography>
                                    {
                                        `${getDayjsAge(dayjs(props.contact.birthAt))} ans`
                                    }
                                </Typography>
                            </Flexbox>
                        </>
                    }
                    <Typography sx={{ mb: 1 }}>
                        {truncateString(props.contact.adresse, 80)}
                    </Typography>
                    <Typography sx={{ mb: 1 }}>
                        {truncateString(props.contact.description, 80)}
                    </Typography>
                </Flexbox>
                <Flexbox sx={{ justifyContent: "flex-start", ml: 1 }}>
                    <ContactRelationships contact={props.contact} />
                </Flexbox>
                {
                    props.contact.contactType === EnumContactType.business && <>
                        <Flexbox sx={{ justifyContent: "flex-start", ml: 1 }}>
                            <CustomContactRelationships contact={props.contact} />
                        </Flexbox>
                    </>
                }
                <Box>
                    <Divider />
                    <Flexbox sx={{
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                    }}>
                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Modifier">
                            <IconButton onClick={handleEdit} color="primary">
                                <EditOutlined />
                            </IconButton>
                        </Tooltip>
                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={props.contact.selected ? "Désélectionner" : "Sélectionner"}>
                            <IconButton onClick={handleSelect} color={props.contact.selected ? "cancel" : "secondary"}>
                                <CropFreeOutlined />
                            </IconButton>
                        </Tooltip>
                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Télécharger">
                            <IconButton color="primary" onClick={handleDownloadContact}>
                                <CloudDownloadOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Supprimer">
                            <IconButton onClick={handleDelete} color="error">
                                <DeleteOutline />
                            </IconButton>
                        </Tooltip>
                    </Flexbox>
                </Box>
            </Paper>
            <ModalDeleteConfirm
                open={showDeleteContactDialog}
                title="Suppression du contact"
                content={`Confirmez-vous la suppression définitive du contact ${props.contact.displayTitle} ?`}
                handleClose={closeDeleteContactDialog}
                handleConfirm={confirmedDeleteContact}
            />
            <ModalEditContact
                open={showModalEditContact}
                handleClose={closeModalEditContact}
                contact={props.contact}
            />
            <ModalContactAvatar
                open={showModalContactAvatar}
                handleClose={closeModalContactAvatar}
                contact={props.contact}
            />
            <ModalContactDownloadVcards
                mode={EnumModalContactDownloadVcardsMode.single}
                open={showModalContactDownloadVcards}
                handleClose={closeModalContactDownloadVcards}
                sheet_file_url={downloadContactsUrl}
            />
        </>
    )
}
