import { useMutation } from '@apollo/client';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import { EnumDayjsFormat, EnumMandatoryContactFields, EnumMessageType, EnumSize } from '../../../enums';
import { GQL_MUTATION_UPDATE_REMINDER } from '../../../graphql/Mutations';
import { IContact, IReminder } from '../../../interfaces';
import { addMessage } from '../../messages/Message';
import { setAllRefetchNeeded } from '../../../redux/store';
import { Flexbox } from '../../utils/Flexbox';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';
import { ContactBadge } from '../../contacts/ContactBadge';
import { ContactSelection } from '../../contacts/ContactSelection';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { DateSelector } from '../../utils/DateSelector';

interface IProps {
    open: boolean;
    handleClose: () => void;
    reminder: IReminder

}

export const ModalEditReminder: FC<IProps> = (props) => {
    const [contact, setContact] = useState<IContact>(props.reminder.contact)
    const [content, setContent] = useState(props.reminder.content)
    const [deliverAt, setDeliverAt] = useState<Dayjs>(dayjs(props.reminder.deliverAt))
    const theme = useTheme()
    const [updateReminder, { data: updateReminderData, loading: updateReminderLoading, error: updateReminderError }] = useMutation(GQL_MUTATION_UPDATE_REMINDER)

    useEffect(() => {
        setContact(props.reminder.contact)
        setContent(props.reminder.content)
        setDeliverAt(dayjs(props.reminder.deliverAt))
    }, [props.reminder])

    useEffect(() => {
        if (updateReminderError) {
            addMessage({
                location: "ModalEditReminder",
                type: EnumMessageType.Error,
                message: updateReminderError.message,
            })
        } else if (updateReminderData) {
            if (updateReminderData.updateReminder.statusCode === 200) {
                setAllRefetchNeeded("ModalEditReminder")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Reminder modifié.",
                })
                handleClose()
            } else {
                updateReminderData.updateReminder.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [updateReminderData, updateReminderError])

    const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        updateReminder({
            variables: {
                reminderId: props.reminder.id,
                contactId: contact?.id,
                content,
                deliverAt: deliverAt?.format(EnumDayjsFormat.datetimeForBackend),
            }
        })
    }

    const handleClose = () => {
        props.handleClose()
    }

    const handleSelectContact = (contact: IContact) => {
        setContact(contact)
    }

    const handleDeliverAt = (pDate: Dayjs) => {
        setDeliverAt(pDate)
    }

    return (
        <div className="ModalEditReminder">
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <Box
                    noValidate
                    component="form"
                    onSubmit={handleConfirm}
                >
                    <DialogTitle color="primary">
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <AccessAlarmIcon color="vault" sx={{ fontSize: 24, mr: "12px" }} />
                            <Typography sx={{ fontSize: 24 }}>
                                Modifier le reminder
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Flexbox sx={{
                            justifyContent: "flex-start",
                            width: "100%",
                            mt: 2,
                        }}>
                            <Typography sx={{
                                fontSize: 16,
                                color: theme.palette.text.secondary,
                                width: "100%",
                            }}>
                                Date
                            </Typography>
                            <DateSelector
                                date={deliverAt}
                                title="Date du reminder"
                                disableFuture={false}
                                handleDate={handleDeliverAt}
                                showArrows={true}
                                displayDateOnLeft={true}
                            />
                        </Flexbox>
                        {
                            contact &&
                            <Flexbox>
                                <ContactBadge
                                    contact={contact}
                                    size={EnumSize.medium}
                                    displayContactTitle={true}
                                    displayTooltip={true}
                                />
                            </Flexbox>
                        }
                        <Divider flexItem sx={{
                            mt: 2,
                        }}>
                            Destinataire
                        </Divider>
                        <Flexbox sx={{
                            backgroundColor: theme.palette.background.default,
                            width: "100%",
                        }}>
                            <ContactSelection
                                handleSelect={(contact) => handleSelectContact(contact)}
                                mandatoryContactFields={[EnumMandatoryContactFields.email]}
                                heightSize={EnumSize.tiny}
                            />
                        </Flexbox>
                        <TextField
                            margin="normal"
                            label="Message"
                            fullWidth
                            multiline
                            rows={4}
                            onChange={(event) => { setContent(event.target.value) }}
                            value={content}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                        <Button type="submit" variant="contained" color="primary">
                            Modifier
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div >
    )
}
