import { useMutation } from '@apollo/client';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider, TextField,
    Typography,
    useTheme
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useRef, useState } from 'react';
import { EnumDayjsFormat, EnumMandatoryContactFields, EnumMessageType, EnumSize } from '../../../enums';
import { GQL_MUTATION_CREATE_REMINDER } from '../../../graphql/Mutations';
import { setAllRefetchNeeded } from '../../../redux/store';
import { addMessage } from '../../messages/Message';
import { KeysIcon } from '../../svg_icons/KeysIcon';
import { Flexbox } from '../../utils/Flexbox';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';
import { DateSelector } from '../../utils/DateSelector';
import { IContact } from '../../../interfaces';
import { ContactBadge } from '../../contacts/ContactBadge';
import { ContactSelection } from '../../contacts/ContactSelection';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';

interface IProps {
    open: boolean;
    handleClose: () => void;
}

export const ModalNewReminder: FC<IProps> = (props) => {
    const [contact, setContact] = useState<IContact | undefined>()
    const [content, setContent] = useState("")
    const [deliverAt, setDeliverAt] = useState<Dayjs>(dayjs())


    const [createReminder, { data: createReminderData, loading: createReminderLoading, error: createReminderError }] = useMutation(GQL_MUTATION_CREATE_REMINDER)
    const theme = useTheme()

    useEffect(() => {
        if (createReminderError) {
            addMessage({
                location: "ModalNewReminder",
                type: EnumMessageType.Error,
                message: createReminderError.message,
            })
        } else if (createReminderData) {
            if (createReminderData.createReminder.statusCode === 200) {
                setAllRefetchNeeded("ModalNewReminder")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Reminder ajouté.",
                })
                handleClose()
            } else {
                createReminderData.createReminder.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [createReminderData, createReminderError])

    const checkErrors = () => {
        if (contact === undefined) {
            addMessage({
                type: EnumMessageType.Error,
                message: "Le contact est obligatoire.",
            })
            return false
        }
        return true
    }

    const cleanDatas = () => {
        setContact(undefined)
        setContent("")
        setDeliverAt(dayjs())
    }

    const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (checkErrors()) {
            createReminder({
                variables: {
                    contactId: contact?.id,
                    content,
                    deliverAt: deliverAt?.format(EnumDayjsFormat.datetimeForBackend),
                }
            })
        }
    }

    const handleClose = () => {
        cleanDatas()
        props.handleClose()
    }

    const handleSelectContact = (contact: IContact) => {
        setContact(contact)
    }

    const handleDeliverAt = (pDate: Dayjs) => {
        setDeliverAt(pDate)
    }

    return (
        <div className="ModalNewReminder">
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <Box
                    noValidate
                    component="form"
                    onSubmit={handleConfirm}
                >
                    <DialogTitle color="primary">
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <AccessAlarmIcon color="vault" sx={{ fontSize: 24, mr: "12px" }} />
                            <Typography sx={{ fontSize: 24 }}>
                                Nouveau reminder
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Flexbox sx={{
                            justifyContent: "flex-start",
                            width: "100%",
                            mt: 2,
                        }}>
                            <Typography sx={{
                                fontSize: 16,
                                color: theme.palette.text.secondary,
                                width: "100%",
                            }}>
                                Date
                            </Typography>
                            <DateSelector
                                date={deliverAt}
                                title="Date du reminder"
                                disableFuture={false}
                                handleDate={handleDeliverAt}
                                // resetDate={resetDateOperation}
                                showArrows={true}
                                displayDateOnLeft={true}
                            />
                        </Flexbox>
                        {
                            contact &&
                            <Flexbox>
                                <ContactBadge
                                    contact={contact}
                                    size={EnumSize.medium}
                                    displayContactTitle={true}
                                    displayTooltip={true}
                                />
                            </Flexbox>
                        }
                        <Divider flexItem sx={{
                            mt: 2,
                        }}>
                            Destinataire
                        </Divider>
                        <Flexbox sx={{
                            backgroundColor: theme.palette.background.default,
                            width: "100%",
                        }}>
                            <ContactSelection
                                handleSelect={(contact) => handleSelectContact(contact)}
                                mandatoryContactFields={[EnumMandatoryContactFields.email]}
                                heightSize={EnumSize.tiny}
                            />
                        </Flexbox>
                        <TextField
                            margin="normal"
                            label="Message"
                            fullWidth
                            multiline
                            rows={4}
                            onChange={(event) => { setContent(event.target.value) }}
                            value={content}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                        <Button type="submit" variant="contained" color="primary">
                            Ajouter
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div >
    )
}
