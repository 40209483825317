import { Box, Chip, Collapse, Divider, IconButton, Link, Paper, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { IBankAccount } from "../../interfaces";
import {
    DeleteOutline, EditOutlined
} from '@mui/icons-material';

import { ExpandMore } from "../utils/ExpandMore";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ModalDeleteConfirm } from "../modals/ModalDeleteConfirm";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { addMessage } from "../messages/Message";
import { EnumAccountOperationType, EnumBankAccountType, EnumMessageType, EnumMetadocLogoMode } from "../../enums";
import { GQL_MUTATION_ADD_IN_BANK_ACCOUNTS_SHORTCUTTED, GQL_MUTATION_DELETE_BANK_ACCOUNT, GQL_MUTATION_REMOVE_FROM_BANK_ACCOUNTS_SHORTCUTTED } from "../../graphql/Mutations";
import { toggleExpandBankAccountAction, toggleSelectBankAccountAction } from "../../redux/features/cashflow/bankAccountsSlice";
import { formatFancyCurrency, truncateString } from "../../utils/Utils";
import { setAllRefetchNeeded } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { constructPath, NAVIGATE_BANK_ACCOUNT } from "../../utils/Navigation";
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { useGetCurrentUser } from "../../redux/features/users/currentUserSlice";
import { FloatingSpinner } from "../spinner/FloatingSpinner";
import { BankAccountIcon } from '../svg_icons/BankAccountIcon';
import { ModalEditBankAccount } from './modals/ModalEditBankAccount';
import { Flexbox } from '../utils/Flexbox';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import { MetadocLogo } from '../MetadocLogo';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

interface IProps {
    bankAccount: IBankAccount,
}

export const BankAccount: FC<IProps> = (props) => {
    const [showDeleteBankAccountDialog, setShowDeleteBankAccountDialog] = useState(false)
    const [showModalEditBankAccount, setShowModalEditBankAccount] = useState(false)
    const [deleteBankAccount, { data: deleteBankAccountData, loading: deleteBankAccountLoading, error: deleteBankAccountError }] = useMutation(GQL_MUTATION_DELETE_BANK_ACCOUNT)
    const [addInBankAccountsShortcutted, { data: addInBankAccountsShortcuttedData, loading: addInBankAccountsShortcuttedLoading, error: addInBankAccountsShortcuttedError }] = useMutation(GQL_MUTATION_ADD_IN_BANK_ACCOUNTS_SHORTCUTTED)
    const [removeFromBankAccountsShortcutted, { data: removeFromBankAccountsShortcuttedData, loading: removeFromBankAccountsShortcuttedLoading, error: removeFromBankAccountsShortcuttedError }] = useMutation(GQL_MUTATION_REMOVE_FROM_BANK_ACCOUNTS_SHORTCUTTED)
    const dispatch = useDispatch()

    const currentUser = useGetCurrentUser()

    const theme = useTheme()
    const navigate = useNavigate()

    const bankAccountPath = constructPath(NAVIGATE_BANK_ACCOUNT, [
        { key: "bankAccountId", value: props.bankAccount.id.toString() }
    ])


    useEffect(() => {
        if (deleteBankAccountError) {
            addMessage({
                location: "BankAccount",
                type: EnumMessageType.Error,
                message: deleteBankAccountError.message,
            })
        } else if (deleteBankAccountData) {
            if (deleteBankAccountData.deleteBankAccount.statusCode === 200) {
                setAllRefetchNeeded("BankAccount")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Compte supprimé.",
                })
            } else {
                deleteBankAccountData.deleteBankAccount.errors.map((error: string) => {
                    addMessage({
                        location: "BankAccount",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteBankAccountData, deleteBankAccountError])

    useEffect(() => {
        if (addInBankAccountsShortcuttedError) {
            addMessage({
                location: "BankAccount",
                type: EnumMessageType.Error,
                message: addInBankAccountsShortcuttedError.message,
            })
        } else if (addInBankAccountsShortcuttedData) {
            if (addInBankAccountsShortcuttedData.addInBankAccountsShortcutted.statusCode === 200) {
                setAllRefetchNeeded("BankAccount")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Opérations rapides activées.",
                })
            } else {
                addInBankAccountsShortcuttedData.addInBankAccountsShortcutted.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [addInBankAccountsShortcuttedData, addInBankAccountsShortcuttedError])

    useEffect(() => {
        if (removeFromBankAccountsShortcuttedError) {
            addMessage({
                location: "BankAccount",
                type: EnumMessageType.Error,
                message: removeFromBankAccountsShortcuttedError.message,
            })
        } else if (removeFromBankAccountsShortcuttedData) {
            if (removeFromBankAccountsShortcuttedData.removeFromBankAccountsShortcutted.statusCode === 200) {
                setAllRefetchNeeded("BankAccount")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Opérations rapides désactivées.",
                })
            } else {
                removeFromBankAccountsShortcuttedData.removeFromBankAccountsShortcutted.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [removeFromBankAccountsShortcuttedData, removeFromBankAccountsShortcuttedError])

    const handleEdit = () => {
        setShowModalEditBankAccount(true)
    }

    const handleDelete = () => {
        setShowDeleteBankAccountDialog(true)
    }

    const closeDeleteBankAccountDialog = () => {
        setShowDeleteBankAccountDialog(false)
    }

    const confirmedDeleteBankAccount = () => {
        deleteBankAccount({
            variables: {
                bankAccountId: props.bankAccount.id
            }
        })
        setShowDeleteBankAccountDialog(false)
    }

    const handleSelect = () => {
        dispatch(toggleSelectBankAccountAction(props.bankAccount.id))
    }

    const handleExpand = () => {
        dispatch(toggleExpandBankAccountAction(props.bankAccount.id))
    }

    const closeModalEditBankAccount = () => {
        setShowModalEditBankAccount(false)
    }

    const isInBankAccountsShortcutted = () => {
        if (currentUser) {
            return currentUser.bankAccountsShortcutted.filter(bankAccount => bankAccount.id === props.bankAccount.id).length > 0
        }
        return false
    }

    const handlePutInBankAccountsShortcutted = () => {
        addInBankAccountsShortcutted({
            variables: {
                bankAccountId: props.bankAccount.id,
            }
        })
    }

    const handleRemoveFromBankAccountsShortcutted = () => {
        removeFromBankAccountsShortcutted({
            variables: {
                bankAccountId: props.bankAccount.id,
            }
        })
    }

    const getMetaBalance = () => {
        return parseFloat(props.bankAccount.realBalance) - parseFloat(props.bankAccount.softLimit)
    }


    return (
        <>
            <FloatingSpinner dependances={[deleteBankAccountLoading]} />
            <Paper className="BankAccount" sx={{
                mt: "24px",
                mb: "12px",
                ml: "12px",
                mr: "12px",
                width: "100%",
                webkitTransition: "initial !important",
                transition: "initial !important",
                boxShadow: props.bankAccount.selected ? `0px 0px 4px 2px ${theme.palette.secondary.main}` : "",
            }}>
                <Box className="BankAccount-container" sx={{
                    pt: "12px",
                    pb: "6px",
                    pl: "12px",
                    pr: "12px",
                }}>
                    <Box className="BankAccount-main-section" sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: 'flex-start',
                    }}>
                        <Box className="BankAccount-icon-section"
                            onClick={handleSelect}
                            sx={{
                                pt: "12px",
                                pb: "8px",
                                pl: "8px",
                                pr: "8px",
                                mt: "-28px",
                                ml: "-24px",
                                backgroundColor: "white",
                                borderRadius: "50%",
                                borderLeft: "1px solid rgb(0 0 0 / 12 %)",
                                // border: none;
                                boxShadow: props.bankAccount.selected ? `0px 0px 4px 2px ${theme.palette.secondary.main}` : "",
                                ":hover": { cursor: "pointer" },
                                "svg": {
                                    boxShadow: "13px 17px 0px 13px #fff",
                                }
                            }}>
                            <BankAccountIcon color={props.bankAccount.cbColor} sx={{ fontSize: 24, mx: .5 }} />
                        </Box>
                        <Box className="BankAccount-content-section" sx={{
                            width: "100%",
                            pl: "4px",
                            pr: "4px",
                            pb: "4px",
                        }}>
                            <Flexbox direction='column' sx={{
                                alignItems: "flex-start",
                            }}>
                                <Typography
                                    onClick={() => navigate(bankAccountPath)}
                                    sx={{
                                        color: theme.palette.text.primary,
                                        fontSize: 24,
                                        ":hover": {
                                            cursor: "pointer",
                                            textShadow: `-1px 0px 4px ${theme.palette.grey[600]}`,
                                        }
                                    }}
                                >
                                    {props.bankAccount.title}
                                </Typography>
                                <Typography
                                    sx={{
                                        color: theme.palette.secondary.main,
                                        fontSize: 20,
                                    }}
                                >
                                    {props.bankAccount.bankName}
                                </Typography>
                            </Flexbox>
                            <Typography >
                                {truncateString(props.bankAccount.description, 80)}
                            </Typography>
                        </Box>
                    </Box>
                    <Flexbox sx={{
                        justifyContent: "space-between",
                    }}>
                        <ExpandMore expand={props.bankAccount.expanded == undefined ? false : props.bankAccount.expanded}
                            onClick={handleExpand} aria-expanded={props.bankAccount.expanded} aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                        {
                            props.bankAccount.bankAccountType === EnumBankAccountType.business &&
                            <Chip size='small' label="Pro" color="desktop" />
                        }
                    </Flexbox>
                    <Box className="BankAccount-expanded-section">
                        <Collapse in={props.bankAccount.expanded} timeout="auto" unmountOnExit>
                            <Divider />
                            <Box className="BankAccount-expanded-section-actions" sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                flexWrap: "wrap",
                            }}>
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Modifier">
                                    <IconButton onClick={handleEdit} color="primary">
                                        <EditOutlined />
                                    </IconButton>
                                </Tooltip>
                                {
                                    isInBankAccountsShortcutted()
                                        ?
                                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Opérations rapides">
                                            <IconButton onClick={handleRemoveFromBankAccountsShortcutted} color="secondary">
                                                <SwitchAccessShortcutIcon />
                                            </IconButton>
                                        </Tooltip>
                                        :
                                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Opérations rapides">
                                            <IconButton onClick={handlePutInBankAccountsShortcutted} color="primary">
                                                <SwitchAccessShortcutIcon />
                                            </IconButton>
                                        </Tooltip>
                                }
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Supprimer">
                                    <IconButton onClick={handleDelete} color="error">
                                        <DeleteOutline />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Ouvrir dans un nouvel onglet">
                                    <Link href={bankAccountPath} underline="none" target="_blank">
                                        <IconButton color="primary">
                                            <OpenInNewOutlinedIcon />
                                        </IconButton>
                                    </Link>
                                </Tooltip>
                            </Box>
                            <Divider />
                            <Flexbox sx={{
                                mt: 1,
                                justifyContent: "space-between",
                            }} >
                                <Flexbox>
                                    <MetadocLogo mode={EnumMetadocLogoMode.tiny} link={false} sx={{
                                        mx: 1,
                                        width: 24,
                                    }} />
                                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={`Solde actuel (${formatFancyCurrency(parseFloat(props.bankAccount.softLimit), parseFloat(props.bankAccount.softLimit) >= 0 ? EnumAccountOperationType.credit : EnumAccountOperationType.debit)
                                        })`}>
                                        <Typography sx={{
                                            fontSize: 18,
                                            color: getMetaBalance() >= 0 ? theme.palette.credit.main : theme.palette.debit.main,
                                        }}>
                                            {formatFancyCurrency(getMetaBalance(), getMetaBalance() >= 0 ? EnumAccountOperationType.credit : EnumAccountOperationType.debit)}
                                        </Typography>
                                    </Tooltip>
                                </Flexbox>
                                <Flexbox>
                                    <AccountBalanceIcon sx={{
                                        color: theme.palette.primary.main,
                                        mx: 1,
                                    }} />
                                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Solde bancaire">
                                        <Typography sx={{
                                            fontSize: 18,
                                            color: parseFloat(props.bankAccount.bankBalance) >= 0 ? theme.palette.credit.main : theme.palette.debit.main,
                                        }}>
                                            {formatFancyCurrency(parseFloat(props.bankAccount.bankBalance), parseFloat(props.bankAccount.bankBalance) >= 0 ? EnumAccountOperationType.credit : EnumAccountOperationType.debit)}
                                        </Typography>
                                    </Tooltip>
                                </Flexbox>
                            </Flexbox>
                        </Collapse>
                    </Box>
                </Box>
            </Paper>
            <ModalEditBankAccount
                open={showModalEditBankAccount}
                handleClose={closeModalEditBankAccount}
                bankAccount={props.bankAccount}
            />
            <ModalDeleteConfirm
                open={showDeleteBankAccountDialog}
                title="Suppression du casier"
                content={`Confirmez-vous la suppression définitive du casier ${props.bankAccount.title} ?`}
                handleClose={closeDeleteBankAccountDialog}
                handleConfirm={confirmedDeleteBankAccount}
            />
        </>
    )
}

