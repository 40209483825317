import { Box, Divider, IconButton, Paper, Skeleton, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { IAudio } from "../../interfaces";
import {
    CropFreeOutlined,
    DeleteOutline
} from '@mui/icons-material';

import { Flexbox } from "../utils/Flexbox";
import { ModalDeleteConfirm } from '../modals/ModalDeleteConfirm';
import { useMutation } from '@apollo/client';
import { addMessage } from '../messages/Message';
import { EnumMessageType, EnumSvgVariant } from '../../enums';
import { setAllRefetchNeeded } from '../../redux/store';
import { GQL_MUTATION_ADD_IN_POCKET, GQL_MUTATION_DELETE_AUDIO, GQL_MUTATION_DELETE_VIDEO, GQL_MUTATION_REMOVE_FROM_POCKET } from '../../graphql/Mutations';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { MusicIcon } from '../svg_icons/MusicIcon';

import {
    addAudiosAction as addAudiosInPocketAction,
    removeAudiosAction as removeAudiosFromPocketAction,
    useGetAudios as useGetPocketAudios,
} from "../../redux/features/pocket/pocketSlice";
import { useDispatch } from 'react-redux';
import { PocketIcon } from '../svg_icons/PocketIcon';
import { toggleSelectAudioAction } from '../../redux/features/albums/albumSlice';

interface IProps {
    audio: IAudio,
    selected?: boolean,
    onClick: (index: number) => void,
    index: number,
}

export const Audio: FC<IProps> = (props) => {
    const [showDeleteAudioDialog, setShowDeleteAudioDialog] = useState(false)
    const [deleteAudio, { data: deleteAudioData, loading: deleteAudioLoading, error: deleteAudioError }] = useMutation(GQL_MUTATION_DELETE_AUDIO)

    const pocketAudios = useGetPocketAudios()
    const [addInPocket, { data: addInPocketData, loading: addInPocketLoading, error: addInPocketError }] = useMutation(GQL_MUTATION_ADD_IN_POCKET)
    const [removeFromPocket, { data: removeFromPocketData, loading: removeFromPocketLoading, error: removeFromPocketError }] = useMutation(GQL_MUTATION_REMOVE_FROM_POCKET)

    const theme = useTheme()
    const dispatch = useDispatch()

    useEffect(() => {
        if (deleteAudioError) {
            addMessage({
                location: "Audio",
                type: EnumMessageType.Error,
                message: deleteAudioError.message,
            })
        } else if (deleteAudioData) {
            if (deleteAudioData.deleteAudio.statusCode === 200) {
                setAllRefetchNeeded("Audio")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Musique supprimée.",
                })
            } else {
                deleteAudioData.deleteAudio.errors.map((error: string) => {
                    addMessage({
                        location: "Audio",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteAudioData, deleteAudioError])

    useEffect(() => {
        if (addInPocketError) {
            addMessage({
                location: "Audio",
                type: EnumMessageType.Error,
                message: addInPocketError.message,
            })
        } else if (addInPocketData) {
            if (addInPocketData.addInPocket.statusCode === 200) {
                dispatch(addAudiosInPocketAction([props.audio]))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "C'est dans la pocket.",
                })
            } else {
                addInPocketData.addInPocket.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [addInPocketData, addInPocketError])

    useEffect(() => {
        if (removeFromPocketError) {
            addMessage({
                location: "Audio",
                type: EnumMessageType.Error,
                message: removeFromPocketError.message,
            })
        } else if (removeFromPocketData) {
            if (removeFromPocketData.removeFromPocket.statusCode === 200) {
                dispatch(removeAudiosFromPocketAction([props.audio.id]))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Retiré de la pocket.",
                })
            } else {
                removeFromPocketData.removeFromPocket.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [removeFromPocketData, removeFromPocketError])


    const handleDelete = () => {
        setShowDeleteAudioDialog(true)
    }

    const closeDeleteAudioDialog = () => {
        setShowDeleteAudioDialog(false)
    }

    const confirmedDeleteAudio = () => {
        deleteAudio({
            variables: {
                audioId: props.audio.id
            }
        })
        setShowDeleteAudioDialog(false)
    }

    const isInPocket = () => {
        if (pocketAudios) {
            return pocketAudios.filter(audio => audio.id === props.audio.id).length > 0
        }
        return false
    }

    const handlePutInPocket = () => {
        addInPocket({
            variables: {
                audioIds: [props.audio.id],
            }
        })
    }

    const handleRemoveFromPocket = () => {
        removeFromPocket({
            variables: {
                audioIds: [props.audio.id],
            }
        })
    }

    const handleSelect = () => {
        dispatch(toggleSelectAudioAction(props.audio.id))
    }

    return (
        <>
            <Paper
                sx={{
                    marginTop: "24px",
                    marginBottom: "12px",
                    marginLeft: "12px",
                    marginRight: "12px",
                    width: "320px",
                    boxShadow: props.selected ? `0px 0px 4px 2px ${theme.palette.secondary.main}` : "",
                }}>
                <Box>
                    {
                        props.audio.audioFileUrl === null && <Flexbox sx={{
                            height: "200px",
                            px: 1,
                            py: 1,
                            backgroundColor: theme.palette.fotoBackground.main,
                            borderTopLeftRadius: 4,
                            borderTopRightRadius: 4,
                        }}>
                            <Typography sx={{
                                color: "white",
                                textAlign: "center",
                                // cursor: "pointer",
                                // ":hover": {
                                //     color: theme.palette.secondary.main,
                                // }
                            }}
                            // onClick={() => props.onClick(props.index)}
                            >
                                Audio en construction
                                <br />
                                <Typography sx={{ color: theme.palette.cancel.main }}>
                                    (Patientez puis recharchez la page..)
                                </Typography>
                            </Typography>
                        </Flexbox>
                    }
                    {
                        props.audio.audioFileUrl && <>
                            <Flexbox direction='column' sx={{
                                px: 1,
                                py: 4,
                                backgroundColor: theme.palette.fotoBackground.main,
                                borderTopLeftRadius: 4,
                                borderTopRightRadius: 4,
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                                <MusicIcon
                                    onClick={() => props.onClick(props.index)}
                                    sx={{
                                        fontSize: 24,
                                        mr: 1,
                                        color: theme.palette.grey[200],
                                        my: 2,
                                        cursor: "pointer",
                                    }} />
                                <Typography sx={{
                                    // bgcolor: "red",
                                    color: theme.palette.grey[200],
                                    mb: 2,
                                }}>
                                    {props.audio.title}
                                </Typography>
                                <audio controls>
                                    <source src={props.audio.audioFileUrl} type="audio/mpeg" />
                                    Votre navigateur ne supporte pas la lecture audio.
                                </audio>
                            </Flexbox>
                        </>
                    }


                    <Box>
                        <Divider />
                        <Flexbox sx={{
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                        }}>
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Télécharger">
                                <IconButton href={props.audio.audioFileUrlForDownload} color="primary">
                                    <CloudDownloadOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={props.selected ? "Désélectionner" : "Sélectionner"}>
                                <IconButton onClick={handleSelect} color={props.selected ? "cancel" : "secondary"}>
                                    <CropFreeOutlined />
                                </IconButton>
                            </Tooltip>
                            {
                                isInPocket() ?
                                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Retirer de la pocket">
                                        <IconButton onClick={handleRemoveFromPocket} color="secondary">
                                            <PocketIcon variant={EnumSvgVariant.secondary} />
                                        </IconButton>
                                    </Tooltip> :
                                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Placer dans la pocket">
                                        <IconButton onClick={handlePutInPocket} color="primary">
                                            <PocketIcon variant={EnumSvgVariant.primary} />
                                        </IconButton>
                                    </Tooltip>
                            }
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Supprimer">
                                <IconButton onClick={handleDelete} color="error">
                                    <DeleteOutline />
                                </IconButton>
                            </Tooltip>
                        </Flexbox>
                    </Box>
                </Box>
            </Paper>
            <ModalDeleteConfirm
                open={showDeleteAudioDialog}
                title="Suppression de la musique"
                content={`Confirmez-vous la suppression définitive de la musique ?`}
                handleClose={closeDeleteAudioDialog}
                handleConfirm={confirmedDeleteAudio}
            />
        </>
    )
}
