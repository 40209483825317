import { FC, useEffect, useState } from 'react';
import { Flexbox } from '../../utils/Flexbox';
import { BasicFilter } from '../../utils/BasicFilter';
import { Divider, Switch, Typography } from '@mui/material';
import { IAccountOperation, IAccountOperationStatesFilter, IBankAccount } from '../../../interfaces';
import { EnumAccountOperationState, EnumAccountOperationType, EnumDevice } from '../../../enums';
import theme from '../../../theme';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';
import { dateIsInPeriod, formatFancyCurrency } from '../../../utils/Utils';
import Grid from '@mui/material/Grid2';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import ForwardIcon from '@mui/icons-material/Forward';


interface IProps {
    bankAccount: IBankAccount,
    filteredAccountOperations: IAccountOperation[],
    dateFrom: Dayjs,
    dateTo: Dayjs,
}

export const AccountOperationsTotals: FC<IProps> = (props) => {
    const [totalUnpaidCredit, setTotalUnpaidCredit] = useState<number>(0.00)
    const [totalUnpaidDebit, setTotalUnpaidDebit] = useState<number>(0.00)
    const [totalPaidCredit, setTotalPaidCredit] = useState<number>(0.00)
    const [totalPaidDebit, setTotalPaidDebit] = useState<number>(0.00)
    const [totalUnpaid, setTotalUnpaid] = useState<number>(0.00)
    const [totalPaid, setTotalPaid] = useState<number>(0.00)
    const [totalCredit, setTotalCredit] = useState<number>(0.00)
    const [totalDebit, setTotalDebit] = useState<number>(0.00)
    const [grandTotal, setGrandTotal] = useState<number>(0.00)
    const [realBalanceAtDateFrom, setRealBalanceAtDateFrom] = useState<number>(0.00)
    const [realBalanceAtDateTo, setRealBalanceAtDateTo] = useState<number>(0.00)
    const [realBalanceIncreased, setRealBalanceIncreased] = useState<boolean>(true)

    const calculateTotals = () => {
        let tempTotalUnpaidCredit = 0.00
        let tempTotalUnpaidDebit = 0.00
        let tempTotalPaidCredit = 0.00
        let tempTotalPaidDebit = 0.00

        let tempRealBalanceAtDateTo = parseFloat(props.bankAccount.realBalanceAtDateFrom)
        setRealBalanceAtDateFrom(tempRealBalanceAtDateTo)

        props.filteredAccountOperations.forEach(accountOperation => {
            // On ne prend en compte que les opérations imputable et pas stepAside et non payées ou payées dans la période:
            if (accountOperation.imputable &&
                accountOperation.temporaryStepAside !== true &&
                (
                    accountOperation.paidAt === undefined ||
                    accountOperation.paidAt === null ||
                    dateIsInPeriod(dayjs(accountOperation.paidAt), props.dateFrom, props.dateTo)
                )) {
                const accountOperationAmount = parseFloat(accountOperation.amount)
                // Non payé:
                if ([EnumAccountOperationState.prepared, EnumAccountOperationState.created].includes(accountOperation.state)) {
                    if (accountOperation.accountOperationType === EnumAccountOperationType.credit) {
                        tempTotalUnpaidCredit += accountOperationAmount
                        // console.log("tempTotalUnpaidCredit", tempTotalUnpaidCredit)
                    }
                    else {
                        tempTotalUnpaidDebit += accountOperationAmount
                        // console.log("tempTotalUnpaidDebit", tempTotalUnpaidDebit)
                    }
                }
                // Payé:
                if ([EnumAccountOperationState.paid, EnumAccountOperationState.consolidated].includes(accountOperation.state)) {
                    if (accountOperation.accountOperationType === EnumAccountOperationType.credit) {
                        tempTotalPaidCredit += accountOperationAmount
                        // console.log("tempTotalPaidCredit", tempTotalPaidCredit)
                    }
                    else {
                        tempTotalPaidDebit += accountOperationAmount
                        // console.log("tempTotalPaidDebit", tempTotalPaidDebit)
                    }
                }
                // On additionne pour le realBalanceAtDateTo:
                accountOperation.accountOperationType === EnumAccountOperationType.credit ? tempRealBalanceAtDateTo += accountOperationAmount : tempRealBalanceAtDateTo -= accountOperationAmount

            }
        })
        setTotalUnpaidCredit(tempTotalUnpaidCredit)
        setTotalUnpaidDebit(tempTotalUnpaidDebit)
        setTotalPaidCredit(tempTotalPaidCredit)
        setTotalPaidDebit(tempTotalPaidDebit)
        setTotalUnpaid(tempTotalUnpaidCredit - tempTotalUnpaidDebit)
        setTotalPaid(tempTotalPaidCredit - tempTotalPaidDebit)
        setTotalCredit(tempTotalPaidCredit + tempTotalUnpaidCredit)
        setTotalDebit(tempTotalPaidDebit + tempTotalUnpaidDebit)
        setGrandTotal(tempTotalUnpaidCredit + tempTotalPaidCredit - tempTotalUnpaidDebit - tempTotalPaidDebit)

        setRealBalanceAtDateTo(tempRealBalanceAtDateTo)
        // setRealBalanceIncreased(realBalanceAtDateTo > realBalanceAtDateFrom)
        setRealBalanceIncreased(tempRealBalanceAtDateTo > realBalanceAtDateFrom)
    }

    useEffect(() => {
        calculateTotals()
    }, [props.filteredAccountOperations, props.dateFrom, props.dateTo, props.bankAccount])

    return (
        <>
            <Grid container>
                <Grid size={{ xs: 12, md: 4 }}>
                    <Flexbox sx={{
                        justifyContent: { xs: "flex-end", md: "flex-start" },
                    }}>
                        {/* mobile */}
                        <Typography sx={{
                            display: { xs: "block", md: "none" },
                            pr: 1,
                            textDecoration: "line-through",
                        }}>
                            Payé:
                        </Typography>

                        {/* desktop */}
                        <Typography sx={{
                            display: { xs: "none", md: "block" },
                            pr: 1,
                        }}>
                            Non Payé:
                        </Typography>
                        <Flexbox sx={{
                            gap: 1,
                        }}>
                            {/* mobile */}
                            <Typography sx={{
                                display: { xs: "block", md: "none" },
                                color: theme.palette.credit.main,
                            }}>
                                {totalUnpaidCredit.toFixed(2)}
                            </Typography>

                            {/* desktop */}
                            <Typography sx={{
                                display: { xs: "none", md: "block" },
                                color: theme.palette.credit.main,
                            }}>
                                {formatFancyCurrency(totalUnpaidCredit, EnumAccountOperationType.credit, false)}
                            </Typography>

                            {/* mobile */}
                            <Typography sx={{
                                color: theme.palette.debit.main,
                                display: { xs: "block", md: "none" },
                            }}>
                                - {totalUnpaidDebit.toFixed(2)}
                            </Typography>

                            {/* desktop */}
                            <Typography sx={{
                                color: theme.palette.debit.main,
                                display: { xs: "none", md: "block" },
                            }}>
                                {formatFancyCurrency(totalUnpaidDebit, EnumAccountOperationType.debit, false)}
                            </Typography>


                            <Typography>
                                =
                            </Typography>
                            <Typography sx={{
                                color: totalUnpaid > 0 ? theme.palette.credit.main : theme.palette.debit.main,
                            }}>
                                {formatFancyCurrency(totalUnpaid, totalUnpaid > 0 ? EnumAccountOperationType.credit : EnumAccountOperationType.debit, false)}
                            </Typography>
                        </Flexbox>
                    </Flexbox>
                </Grid>
                <Grid size={{ xs: 12, md: 4 }}>
                    <Flexbox sx={{
                        justifyContent: { xs: "flex-end", md: "center" },
                    }}>
                        <Typography sx={{
                            pr: 1,
                        }}>
                            Payé:
                        </Typography>
                        <Flexbox sx={{
                            gap: 1,
                        }}>
                            {/* mobile */}
                            <Typography sx={{
                                color: theme.palette.credit.main,
                                display: { xs: "block", md: "none" },
                            }}>
                                {totalPaidCredit.toFixed(2)}
                            </Typography>

                            {/* desktop */}
                            <Typography sx={{
                                color: theme.palette.credit.main,
                                display: { xs: "none", md: "block" },
                            }}>
                                {formatFancyCurrency(totalPaidCredit, EnumAccountOperationType.credit, false)}
                            </Typography>

                            {/* mobile */}
                            <Typography sx={{
                                color: theme.palette.debit.main,
                                display: { xs: "block", md: "none" },
                            }}>
                                - {totalPaidDebit.toFixed(2)}
                            </Typography>

                            {/* desktop */}
                            <Typography sx={{
                                color: theme.palette.debit.main,
                                display: { xs: "none", md: "block" },
                            }}>
                                {formatFancyCurrency(totalPaidDebit, EnumAccountOperationType.debit, false)}
                            </Typography>

                            <Typography>
                                =
                            </Typography>
                            <Typography sx={{
                                color: totalPaid > 0 ? theme.palette.credit.main : theme.palette.debit.main,
                            }}>
                                {formatFancyCurrency(totalPaid, totalPaid > 0 ? EnumAccountOperationType.credit : EnumAccountOperationType.debit, false)}
                            </Typography>
                        </Flexbox>
                    </Flexbox>
                </Grid>
                <Grid size={{ xs: 12, md: 4 }}>
                    <Flexbox sx={{
                        justifyContent: { xs: "flex-end", md: "flex-end" },
                    }}>
                        <Typography sx={{
                            pr: 1,
                        }}>
                            Total:
                        </Typography>
                        <Flexbox sx={{
                            gap: 1,
                        }}>
                            {/* mobile */}
                            <Typography sx={{
                                color: theme.palette.credit.main,
                                display: { xs: "block", md: "none" },
                            }}>
                                {totalCredit.toFixed(2)}
                            </Typography>

                            {/* desktop */}
                            <Typography sx={{
                                color: theme.palette.credit.main,
                                display: { xs: "none", md: "block" },
                            }}>
                                {formatFancyCurrency(totalCredit, EnumAccountOperationType.credit, false)}
                            </Typography>

                            {/* mobile */}
                            <Typography sx={{
                                color: theme.palette.debit.main,
                                display: { xs: "block", md: "none" },
                            }}>
                                - {totalDebit.toFixed(2)}
                            </Typography>

                            {/* desktop */}
                            <Typography sx={{
                                color: theme.palette.debit.main,
                                display: { xs: "none", md: "block" },
                            }}>
                                {formatFancyCurrency(totalDebit, EnumAccountOperationType.debit, false)}
                            </Typography>

                            <Typography>
                                =
                            </Typography>
                            <Typography sx={{
                                color: grandTotal > 0 ? theme.palette.credit.main : theme.palette.debit.main,
                            }}>
                                {formatFancyCurrency(grandTotal, grandTotal > 0 ? EnumAccountOperationType.credit : EnumAccountOperationType.debit, false)}
                            </Typography>
                        </Flexbox>
                    </Flexbox>
                </Grid>
                <Grid size={{ xs: 12, md: 12 }}>
                    <Flexbox sx={{
                        justifyContent: { xs: "flex-end", md: "flex-end" },
                    }}>
                        <Flexbox sx={{
                            gap: 1,
                            backgroundColor: realBalanceIncreased ? `${theme.palette.credit.main}CC` : `${theme.palette.debit.main}CC`,
                            padding: 1,
                            borderRadius: 4,
                        }}>
                            <Typography sx={{
                                // color: theme.palette.secondary.main,
                                color: theme.palette.common.white,
                            }}>
                                {formatFancyCurrency(realBalanceAtDateFrom, realBalanceAtDateFrom > 0 ? EnumAccountOperationType.credit : EnumAccountOperationType.debit, false)}
                            </Typography>
                            <ForwardIcon sx={{
                                // color: realBalanceIncreased ? theme.palette.credit.main : theme.palette.debit.main,
                                color: theme.palette.background.default,
                            }} />
                            <Typography sx={{
                                // color: realBalanceIncreased ? theme.palette.credit.main : theme.palette.debit.main,
                                color: theme.palette.common.white,
                            }}>
                                {formatFancyCurrency(realBalanceAtDateTo, realBalanceAtDateTo > 0 ? EnumAccountOperationType.credit : EnumAccountOperationType.debit, false)}
                            </Typography>
                        </Flexbox>
                    </Flexbox>
                </Grid>
            </Grid>
        </>



    )
}
