import { useMutation } from '@apollo/client';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { EnumListLockersMode, EnumMessageType } from '../../../enums';
import { GQL_MUTATION_CREATE_DIRECTORY, GQL_MUTATION_CREATE_NOTE } from '../../../graphql/Mutations';
import { IDirectory, ILocker } from '../../../interfaces';
import { setAllRefetchNeeded } from '../../../redux/store';
// import { addLockerAction } from '../../../redux/features/lockers/lockersSlice';
import { addMessage } from '../../messages/Message';
import { DirectoryIcon } from '../../svg_icons/DirectoryIcon';
import { NoteIcon } from '../../svg_icons/NoteIcon';
import { ModalInsertionMarkdown } from './ModalInsertionMarkdown';

interface IProps {
    open: boolean;
    handleClose: () => void;
    directory: IDirectory;
    mode: EnumListLockersMode;
}

export const ModalNewNote: FC<IProps> = (props) => {
    const [title, setTitle] = useState("")
    const [errorOnTitle, setErrorOnTitle] = useState(false)
    const [content, setContent] = useState("")
    const titleRef = useRef<HTMLInputElement>(null);
    const [showModalInsertionMarkdown, setShowModalInsertionMarkdown] = useState(false)
    const [createNote, { data: createNoteData, loading: createNoteLoading, error: createNoteError }] = useMutation(GQL_MUTATION_CREATE_NOTE)


    const closeModalInsertionMarkdown = () => {
        setShowModalInsertionMarkdown(false)
    }

    useEffect(() => {
        // Le input n'est pas monté avant le test, donc on bricole avec un setTimeout..
        if (props.open) {
            setTimeout(() => {
                if (titleRef.current) {
                    titleRef.current.focus();
                }
            }, 0);
        }
    }, [props.open]);

    useEffect(() => {
        if (createNoteError) {
            addMessage({
                location: "ModalNewNote",
                type: EnumMessageType.Error,
                message: createNoteError.message,
            })
        } else if (createNoteData) {
            if (createNoteData.createNote.statusCode === 200) {
                setAllRefetchNeeded("ModalNewNote")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Note ajoutée.",
                })
                handleClose()
            } else {
                createNoteData.createNote.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [createNoteData, createNoteError])

    const checkErrors = () => {
        if (title === undefined || title === "") {
            setErrorOnTitle(true)
            return false
        }
        return true
    }

    const cleanDatas = () => {
        setTitle("")
        setErrorOnTitle(false)
        setContent("")
    }

    const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (checkErrors()) {
            createNote({
                variables: {
                    title,
                    content,
                    directoryId: props.directory.id,
                }
            })
        }
    }

    const handleClose = () => {
        cleanDatas()
        props.handleClose()
    }

    const confirmModalInsertionMarkdown = (markdownToAdd: String) => {
        setContent(content + markdownToAdd)
        setShowModalInsertionMarkdown(false)
    }

    return (
        <div className="ModalNewNote">
            <Dialog maxWidth="lg" open={props.open} onClose={handleClose} fullWidth>
                <Box
                    noValidate
                    component="form"
                    onSubmit={handleConfirm}
                >
                    <DialogTitle color="primary">
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <NoteIcon color={props.mode} sx={{ fontSize: 24, mr: "12px" }} />
                            <Typography sx={{ fontSize: 24 }}>
                                Nouvelle note
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            autoComplete='off'
                            inputRef={titleRef}
                            autoFocus
                            margin="dense"
                            label="Titre de la note"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setTitle(event.target.value) }}
                            value={title}
                            required
                            error={errorOnTitle}
                            helperText={errorOnTitle ? "Ce champ est obligatoire." : ""}
                            sx={{ mb: 2 }}
                        />
                        <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            <Button color='secondary' onClick={() => { setShowModalInsertionMarkdown(true) }}>Insertion</Button>
                        </Box>
                        <TextField
                            margin="normal"
                            label="Contenu"
                            fullWidth
                            multiline
                            rows={12}
                            onChange={(event) => { setContent(event.target.value) }}
                            value={content}
                            sx={{ mt: 0 }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                        <Button type="submit" variant="contained" color="primary">
                            Ajouter
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
            <ModalInsertionMarkdown
                open={showModalInsertionMarkdown}
                handleClose={closeModalInsertionMarkdown}
                handleConfirm={confirmModalInsertionMarkdown}
            />
        </div >
    )
}
